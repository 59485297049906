<div *ngIf="isEditing; else driversPreview">
  <div>
    <label for="binary" class="checkbox-label">Is Default</label>
    <p-checkbox
      [(ngModel)]="isDefaultDriver"
      binary="true"
      inputId="binary"
      (onChange)="isDefaultDriverChange.emit(isDefaultDriver)"
                id="isDriverDefault"
    ></p-checkbox>
  </div>
  <p-multiSelect
    *ngIf="!isDefaultDriver"
    aria-label="driverSelector"
    [filter]="true"
    [options]="drivers"
    [(ngModel)]="selectedDrivers"
    optionLabel="itemName"
    filterBy="itemName"
    [style]="{ minWidth: '100%', marginTop: '5px' }"
    (onPanelShow)="sortSelected()"
    (onChange)="selectedDriversChange.emit(selectedDrivers)"
    tabbableMultiselect
  ></p-multiSelect>
</div>
<ng-template #driversPreview>
  <div *ngIf="isDefaultDriver">Is Default</div>
  <div *ngIf="!isDefaultDriver && selectedDrivers?.length < 4">
    <div *ngFor="let driver of selectedDrivers" class="mr-2">
      {{ driver?.itemName }}
    </div>
  </div>
  <div *ngIf="!isDefaultDriver && selectedDrivers?.length >= 4">
    {{ selectedDrivers.length }} drivers selected
  </div>
</ng-template>
