<div (appClickOutside)="clickOutsideTable()">
  <button
    style="text-align: left"
    class="btn btn-origin dropdown-toggle btn-block"
    type="button"
    (click)="toggleTable()"
    [id]="buttonId"
    (keydown)="tableBtnKeyDownHandler($event)"
    [disabled]="disabled"
  >
    {{ isSelected ? locationName : "---Select Location---" }}
    <span class="caret pull-right span-top"></span>
  </button>
  <div *ngIf="!isTableHidden" [hidden]="isTableHidden" class="style-table">
    <p-table
      #dto
      columnResizeMode="expand"
      [columns]="cols"
      [value]="_locations"
      selectionMode="single"
      [scrollable]="true"
      scrollHeight="240px"
      [(selection)]="currentSelection"
      [rows]="size"
      [paginator]="true"
      (onLazyLoad)="lazyLoadLocations($event)"
      [lazy]="true"
      [totalRecords]="totalRecords"
      [id]="tableId"
      [filterDelay]="0"
      [globalFilterFields]="['name']"
      [resizableColumns]="true"
    >
      <ng-template pTemplate="caption">
        <div>
          <input
            pInputText
            type="text"
            [(ngModel)]="searchText"
            (input)="filterTable($event)"
            placeholder="Global Search"
            [id]="searchFieldId"
            (keydown)="searchKeyDownHandler($event)"
            onfocus="this.select()"
            autocomplete="off"
          />
        </div>
      </ng-template>
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns" [style.width.px]="col.width || 150" />
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr class="tr-font-column">
          <th *ngFor="let col of columns" class="th-width-table" pResizableColumn>
            {{ col.header }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-location let-columns="columns" let-y="rowIndex">
        <tr
          [pSelectableRow]="location"
          class="tr-font-column"
          id="location_selector_row_{{ location.id }}"
          style="height: 40px"
          (click)="onLocationClick(location)"
        >
          <td class="ui-resizable-column" title="{{ location.locId | uppercase }}">
            {{ location.locId }}
          </td>
          <td class="ui-resizable-column" title="{{ location.country | uppercase }}">
            {{ location.country }}
          </td>
          <td class="ui-resizable-column" title="{{ location.city | uppercase }}">
            {{ location.city }}
          </td>
          <td class="ui-resizable-column" title="{{ location.name | uppercase }}">
            {{ location.name }}
          </td>
          <td class="ui-resizable-column" title="{{ location.state | uppercase }}">
            {{ location.state }}
          </td>
          <td class="ui-resizable-column" title="{{ location.zipcode | uppercase }}">
            {{ location.zipcode }}
          </td>
          <td class="ui-resizable-column" title="{{ location.address | uppercase }}">
            {{ location.address }}
          </td>
          <td class="ui-resizable-column" title="{{ location.address2 | uppercase }}">
            {{ location.address2 }}
          </td>
          <td class="ui-resizable-column" title="{{ location.isShipper | yesNo }}">
            {{ location.isShipper | yesNo }}
          </td>
          <td class="ui-resizable-column" title="{{ location.isConsignee | yesNo }}">
            {{ location.isConsignee | yesNo }}
          </td>
          <td class="ui-resizable-column" title="{{ location.confidenceLevel }}">
            {{ location.confidenceLevel ? location.confidenceLevel : "Not calculated" }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
