import { throwError as observableThrowError } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "../../shared/services/http/http-client.service";
import { DriverViewModel } from "@app/shared/models/drivers/DriverViewModel.model";

@Injectable()
export class DriverService {
  url: String;
  constructor(private _http: HttpClient) {}

  getDriverList(includeInactive: boolean, includeTeams = false) {
    return this._http.post("api/driver/list", { includeInactive, includeTeams }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.message || "Server error");
      })
    );
  }

  getTeamlessDrivers() {
    return this._http.get("api/driver/listWithoutTeams").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  findAndFilter() {
    return this._http.post("api/driver/findAndFilter", {}).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  updateDriver(driver: any) {
    return this._http.patch("api/driver/update", driver).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  updateDriverNo(driver: { id: string; idSystem: string }) {
    return this._http.patch("api/driver/updateDriverNo", driver).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getDriverInfoById(id: string | number) {
    return this._http.get("api/driver/find/" + id).pipe(
      map((res: DriverViewModel) => res),
      catchError((error: any) => observableThrowError(error.message || "Server driverInfo error"))
    );
  }

  getDriverInfoByIdAsync(id: string | number): Promise<any> {
    return this._http.get("api/driver/find/" + id).toPromise();
  }

  listSelectFormat() {
    return (this._http.post("api/driver/list", { limit: null }) as any).pipe(
      map((res) => {
        const drivers = res as any;
        drivers.map((driver) => (driver.itemName = `${driver.lastName}, ${driver.name}`));
        return drivers;
      }),
      catchError((error: any) => {
        return observableThrowError(error.message || "Server product creation error");
      })
    );
  }

  createNewDriver(driver: DriverViewModel) {
    return this._http.post("api/driver/create", driver).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server error driver cant be created")
      )
    );
  }

  // Gets the last used trailer and product for the driver, and the driver's assigned tractorGets the last used trailer and product for the driver, and the driver's assigned tractor
  getDefaultSetup(driverId: string | number) {
    return this._http.post("api/driver/getDefaultSetup", { driverId }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server error las trailer used cant be created")
      )
    );
  }

  getDriversNoTractorAssigned() {
    return this._http.get("api/driver/getDriversWithNoTruck").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server driverInfo error"))
    );
  }

  getBatchDriversDropdown(batchNo: number) {
    return this._http.get(`api/driver/listBatchDropDown?batchNo=${batchNo}`).pipe(
      map((drivers: any) => {
        return drivers.map((driver: DriverViewModel) => {
          return {
            label: `${driver.lastName}, ${driver.name}`,
            value: driver.id,
          };
        });
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server trip creation error")
      )
    );
  }

  getActiveBatchDriversDropdown() {
    return this._http.get(`api/driver/listActiveBatchDropDown`).pipe(
      map((drivers: any) => {
        return drivers.map((driver: DriverViewModel) => {
          return {
            label: `${driver.lastName}, ${driver.name}`,
            value: driver.id,
          };
        });
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server trip creation error")
      )
    );
  }

  getCurrentBatchStatus(driverId: string) {
    return this._http.post("api/driver/currentBatchStatus", { driverId }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
}
