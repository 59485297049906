import { Component, OnInit } from "@angular/core";
import { EfsService } from "../efs.service";
import { CanComponentDeactivate } from "@app/shared/guards/confirmation/confirmation.guard";
import { authenticationService } from "@app/login/auth.service";
import { EfsChargeTypeMapping } from "@app/shared/models/fuel/EfsChargeTypeMapping.model";

@Component({
  selector: "app-efs-charge-types",
  templateUrl: "./efs-charge-types.component.html",
  styleUrl: "./efs-charge-types.component.css",
})
export class EfsChargeTypesComponent implements OnInit, CanComponentDeactivate {
  mappings: EfsChargeTypeMapping[];
  filteredMappings: EfsChargeTypeMapping[];
  selectedMapping: EfsChargeTypeMapping;
  showDeleted = false;
  displayAdd = false;
  displayEdit = false;
  loadingTable = false;

  cols: any[] = [
    { field: "categoryCode", header: "EFS Category Code" },
    { field: "description", header: "EFS Category Description" },
    { field: "chargeType.name", header: "Charge Type" },
    { field: "settlementColumn", header: "Column" },
    { field: "defaultChargeTo", header: "Charge To" },
  ];

  constructor(private efsService: EfsService, private authService: authenticationService) {}

  ngOnInit() {
    this.mappings = [];
    this.getMappings();
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this.authService.hasPermission(permission);
  }

  confirm() {
    return true;
  }

  filterMappings() {
    if (this.showDeleted) this.filteredMappings = this.mappings;
    else this.filteredMappings = this.mappings.filter((mapping) => !mapping.dateDeletedUtc);
  }

  showAddForm() {
    this.selectedMapping = {
      id: "",
      categoryCode: "",
      description: "",
    };
    this.displayAdd = true;
  }

  showEditForm(chargeType: EfsChargeTypeMapping) {
    this.displayEdit = true;
    this.selectedMapping = chargeType;
  }

  getMappings() {
    this.loadingTable = true;
    this.efsService.getChargeTypeMappings().subscribe((mappings) => {
      this.mappings = mappings;
      this.filterMappings();
      this.loadingTable = false;
    });
  }
}
