<div class="d-flex" style="margin-bottom: 10px">
  <div class="form-group">
    <label for="startDate">Start Date</label>
    <input
      type="date"
      class="form-control"
      id="startDate"
      [(ngModel)]="filters.startDate"
      (input)="filtersSubject.next($event)"
    />
  </div>
  <div class="form-group" style="margin-left: 10px">
    <label for="endDate">End Date</label>
    <input
      type="date"
      class="form-control"
      id="endDate"
      [(ngModel)]="filters.endDate"
      (input)="filtersSubject.next($event)"
    />
  </div>
</div>

<p-toolbar styleClass="toolbar-header">
  <div class="ui-toolbar">
    <div class="ui-toolbar-group-left">
      <button
        pButton
        type="button"
        icon="fa fa-file-excel-o"
        class="p-button-secondary mr-2"
        title="Export to Excel"
        label="Export"
        (click)="exportExcel()"
      ></button>
    </div>
  </div>
</p-toolbar>

<p-table
  #syncedFuelTable
  [columns]="columns"
  [value]="records"
  [totalRecords]="totalRecords"
  [rows]="rows"
  [paginator]="true"
  [loading]="isLoading"
  [scrollable]="true"
  scrollHeight="600px"
  [lazy]="true"
  (onLazyLoad)="onLazyLoad($event)"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th></th>
      <th
        [pSortableColumn]="column.field"
        *ngFor="let column of columns"
        [style.width]="column.width"
      >
        {{ column.header }}
      </th>
      <th>Discount PPU</th>
    </tr>
    <tr>
      <th></th>
      <th *ngFor="let column of columns">
        <input
          *ngIf="isFilterable(column.field)"
          type="text"
          [(ngModel)]="filters[column.field]"
          (input)="filtersSubject.next($event)"
        />
      </th>
      <th></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-record>
    <tr>
      <td style="text-overflow: clip">
        <img
          *ngIf="record.batchId"
          style="cursor: pointer; width: 21px"
          src="assets/img/details.svg"
          title="Go To Fuel Expense Grid"
          (click)="openGrid(record)"
        />
      </td>
      <td
        *ngFor="let column of columns"
        [ngClass]="{ 'align-right': column.type === 'currency' || column.type === 'number' }"
      >
        <span *ngIf="column.type == 'string'">{{ record[column.field] }}</span>
        <span *ngIf="column.type == 'date'">{{ record[column.field] | date : "short" }}</span>
        <span *ngIf="column.type == 'number'">{{ record[column.field] }}</span>
        <span *ngIf="column.type == 'currency'">{{ record[column.field] | negativeToParens }}</span>
      </td>
      <td class="text-align: right">{{ record.retailPPU - record.ppu | negativeToParens }}</td>
    </tr>
  </ng-template>
</p-table>
