import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ChargetypesService } from "@app/chargeType/shared/chargetypes.service";
import { DriverService } from "@app/driver/shared/driver.service";
import { FuelRecordGridModel } from "@app/shared/models/fuel/FuelRecordGridModel.model";
import { ExcelExportService } from "@app/shared/services/excel-export.service";
import { ToastrService } from "ngx-toastr";
import { SelectItem } from "primeng/api";
import { Table } from "primeng/table";

@Component({
  selector: "app-editable-fuel-records-table",
  templateUrl: "./editable-fuel-records-table.component.html",
  styleUrl: "./editable-fuel-records-table.component.css",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableFuelRecordsTableComponent implements OnInit, OnChanges {
  @ViewChild("recordTable", { static: true }) dt: Table;

  @Input() records: FuelRecordGridModel[] = [];
  @Output() recordsChange = new EventEmitter<any[]>();

  @Input() showBatchLink: boolean = false;

  totals: any[] = [];
  drivers = [];
  chargeTypes: SelectItem[] = [];

  recordCols = [
    { field: "drId", header: "Peoplenet", type: "driverId" },
    { field: "driverName", header: "Driver Name", type: "string" },
    { field: "batchDriverId", header: "Assigned Driver", type: "driver", hide: true },
    { field: "chargeTypeId", header: "Charge Type", type: "chargeType", hide: true },
    { field: "transactionId", header: "Transaction", type: "string" },
    { field: "transactionDate", header: "Date", type: "date" },
    { field: "locationName", header: "Location", type: "string" },
    { field: "locationCity", header: "City", type: "string" },
    { field: "locationState", header: "State", type: "string" },
    { field: "unit", header: "Unit", type: "string" },
    { field: "quantity", header: "Gallons", type: "number" },
    { field: "ppu", header: "PPG", type: "currency" },
    { field: "productCode", header: "Product Code", type: "string" },
    { field: "chainName", header: "Chain", type: "string" },
    { field: "settlementColumn", header: "Type", type: "string" },
    { field: "fuel", header: "Fuel", type: "currency" },
    { field: "other", header: "Other", type: "currency" },
    { field: "cash", header: "Cash", type: "currency" },
    { field: "fee", header: "Fee", type: "currency" },
    { field: "discount", header: "Discount", type: "currency" },
    { field: "total", header: "Total", type: "currency" },
  ];

  totalsCols = [
    { field: "fuel", header: "Fuel" },
    { field: "other", header: "Other" },
    { field: "cash", header: "Cash" },
    { field: "fee", header: "Fee" },
    { field: "discount", header: "Discount" },
    { field: "total", header: "Total" },
  ];

  constructor(
    private driverService: DriverService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private chargeTypesService: ChargetypesService,
    private excelService: ExcelExportService
  ) {}

  ngOnInit() {
    this.chargeTypesService.listDropDownJustId().subscribe({
      next: (chargeTypes) => {
        this.chargeTypes = chargeTypes;
        this.cdr.markForCheck();
      },
      error: (error) => {
        this.toastr.error("Error loading charge types: " + error.message);
      },
    });

    this.driverService.getActiveBatchDriversDropdown().subscribe({
      next: (drivers: { value: any; label: string }[]) => {
        this.drivers = this.addEmptyOption(drivers);
        this.updateDriverFilters();
        this.cdr.markForCheck();
      },
      error: (error) => {
        this.toastr.error("Error getting drivers");
      },
    });
  }

  // Do something when we first get the records
  ngOnChanges() {
    this.onChange();
  }

  getEditedColor(record: FuelRecordGridModel, field: string): string {
    if (!record.syncedFuelRecord) return "";

    const quantityFields = ["fuel", "other", "cash"];
    const syncedType = record.settlementColumn?.toLowerCase();
    const syncedRecord = record.syncedFuelRecord;

    if (field == "chainName") return "";
    else if (quantityFields.includes(field.toLowerCase())) {
      // get the intended amount, which is the transaction amount if the settlement column is the same as the field
      if (field === syncedType)
        return record[field] !== syncedRecord.transactionAmount ? "red" : "";
      else return record[field] !== 0 ? "red" : "";
    } else if (field == "fee") {
      if (syncedType == "fee") {
        const originalValue = syncedRecord.transactionAmount + syncedRecord.transactionFee;
        return record[field] !== originalValue ? "red" : "";
      } else return record[field] !== syncedRecord.transactionFee ? "red" : "";
    } else if (field == "ppu") return record[field] !== syncedRecord.ppu ? "red" : "";
    else if (field == "discount")
      return record[field] !== -syncedRecord.lineItemDiscount ? "red" : "";
    else if (!syncedRecord) return "";
    else if (syncedRecord[field] === undefined) return "";
    return record[field] !== syncedRecord[field] ? "red" : "";
  }

  onChange() {
    this.updateChargeTypeFilters();
    this.updateDriverFilters();
    this.updateTotals();

    this.recordsChange.emit(this.records);
  }

  updateTotals() {
    this.totals = [
      this.records.reduce((acc, record) => {
        for (const col of this.totalsCols) {
          acc[col.field] = (acc[col.field] || 0) + record[col.field];
        }
        return acc;
      }, {}),
    ];

    // Calculate the total for each row
    for (const record of this.records) {
      record.total = record.fuel + record.other + record.cash + record.fee + record.discount;
      record.total = Math.round(record.total * 100) / 100;
    }
  }

  private addEmptyOption(
    drivers: { value: any; label: string }[]
  ): { value: any; label: string }[] {
    return [{ value: null, label: "Select a Driver" }, ...drivers];
  }

  updateChargeTypeFilters() {
    for (const record of this.records) {
      const chargeType = this.chargeTypes.find((d) => d.value === record.chargeTypeId);
      record.filterChargeType = chargeType ? chargeType.label : "";
    }
  }

  updateDriverFilters() {
    for (const record of this.records) {
      const driver = this.drivers.find((d) => d.value === record.batchDriverId);
      record.filterDriverName = driver ? driver.label : "";
    }
  }

  openDriverSettlement(batchDriver: any) {
    const url = `/settlements/loads/${batchDriver.driverId}?batchNo=${batchDriver.batchNo}&tab=Fuel`;
    window.open(url, "_blank");
  }

  exportExcel() {
    const rows = this.records;
    const cols = this.recordCols;

    if (rows === undefined || cols === undefined) {
      this.toastr.warning("Table is empty.", "Unable to Export", {
        closeButton: true,
        enableHtml: true,
      });
      return;
    }

    const exportRows = rows.map((record) => {
      const row = {};
      for (const col of cols) {
        if (col.field == "batchDriverId")
          row[col.header] = this.drivers.find((d) => d.value === record[col.field])?.label;
        else if (col.field == "chargeTypeId")
          row[col.header] = this.chargeTypes.find((d) => d.value === record[col.field])?.label;
        else row[col.header] = record[col.field];
      }
      return row;
    });

    if (exportRows.length > 0) {
      this.excelService.exportAsExcelFile(exportRows, "FuelRecords");
    } else {
      this.toastr.warning("Table is empty.", "Unable to Export", {
        closeButton: true,
        enableHtml: true,
      });
    }
  }
}
