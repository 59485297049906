import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { DriverLaneRateViewModel } from "@app/shared/models/rates/DriverLaneRateViewModel.model";
import { SelectItem } from "primeng/api";
import { Table } from "primeng/table";

@Component({
  selector: "app-driver-lane-rates",
  templateUrl: "./driver-lane-rates.component.html",
  styleUrl: "./driver-lane-rates.component.css",
})
export class DriverLaneRatesComponent {
  @ViewChild("driverLaneRatesTable", { static: true }) table: Table;
  @Input() rates: DriverLaneRateViewModel[];

  statusOptions: SelectItem[] = [
    { label: "ALL", value: null },
    { label: "ACTIVE", value: true },
    { label: "INACTIVE", value: false },
  ];

  // prettier-ignore
  columns: any = [
    { field: "name", header: "Rate Name", width: 300 },
    { field: "description", header: "Description", width: 300 },
    { field: "rateBy", header: "Rate By", width: 200 },
  ];
  filterElements: any = [];

  constructor(private router: Router) {}

  ngOnInit(): void {
    const filter = sessionStorage.getItem("driver-lane-rates-list");
    if (!filter) this.table.filter(this.statusOptions[1].value, "isValid", "equals");
  }

  editRate(rate: DriverLaneRateViewModel) {
    this.router.navigate([`/rates/lanedriver/${rate.id}`]);
  }

  createRate() {
    this.router.navigate([`/createrate/lanedriver`]);
  }
}
