import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ChargetypesService } from "@app/chargeType/shared/chargetypes.service";
import { DriverService } from "@app/driver/shared/driver.service";
import { MoneyCodeGridModel } from "@app/shared/models/fuel/MoneyCodeGridModel";
import { ExcelExportService } from "@app/shared/services/excel-export.service";
import { ToastrService } from "ngx-toastr";
import { SelectItem } from "primeng/api";
import { Table } from "primeng/table";

@Component({
  selector: "app-editable-money-codes-table",
  templateUrl: "./editable-money-codes-table.component.html",
  styleUrl: "./editable-money-codes-table.component.css",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableMoneyCodesTableComponent implements OnInit, OnChanges {
  @ViewChild("recordTable", { static: true }) dt: Table;

  @Input() moneyCodes: MoneyCodeGridModel[] = [];
  @Output() moneyCodesChange = new EventEmitter<any[]>();

  @Input() showBatchLink: boolean = false;
  @Input() isNewRecords: boolean = false;

  totals: any[] = [];
  drivers = [];
  chargeTypes: SelectItem[] = [];

  moneyCodeCols = [
    { field: "batchDriverId", header: "Assigned Driver", type: "driver", hide: true },
    { field: "chargeTypeId", header: "Charge Type", type: "chargeType", hide: true },
    { field: "notes", header: "Notes", type: "string" },
    { field: "name", header: "Name", type: "string" },
    { field: "transactionId", header: "Transaction", type: "string" },
    { field: "transferCode", header: "Transfer Code", type: "string" },
    { field: "amount", header: "Amount", type: "currency" },
    { field: "amountUsed", header: "Amount Used", type: "currency" },
    { field: "feeAmount", header: "Fee", type: "currency" },
    { field: "issuedTo", header: "Issued To", type: "string" },
    { field: "issuedBy", header: "Issued By", type: "string" },
    { field: "activeDate", header: "Issued Date", type: "date" },
    { field: "firstUseDate", header: "Use Time", type: "date" },
    { field: "poNumber", header: "PO Number", type: "string" },
    { field: "unit", header: "Unit", type: "string" },
  ];

  totalsCols = [
    { field: "amount", header: "Fuel" },
    { field: "amountUsed", header: "Other" },
    { field: "feeAmount", header: "Cash" },
  ];

  constructor(
    private driverService: DriverService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private chargeTypesService: ChargetypesService,
    private excelService: ExcelExportService
  ) {}

  ngOnInit() {
    this.chargeTypesService.listDropDownJustId().subscribe({
      next: (chargeTypes) => {
        this.chargeTypes = chargeTypes;
        this.onChange();
        this.cdr.markForCheck();
      },
      error: (error) => {
        this.toastr.error("Error loading charge types: " + error.message);
      },
    });

    this.driverService.getActiveBatchDriversDropdown().subscribe({
      next: (drivers: { value: any; label: string }[]) => {
        this.drivers = this.addEmptyOption(drivers);
        this.updateDriverFilters();
        this.cdr.markForCheck();
      },
      error: (error) => {
        this.toastr.error("Error getting drivers");
      },
    });
  }

  // Do something when we first get the records
  ngOnChanges() {
    this.onChange();
  }

  getEditedColor(record: MoneyCodeGridModel, field: string): string {
    if (!record.syncedMoneyCode) return "";

    const recordMapping = {
      activeDate: "activeDate",
      amount: "amount",
      amountUsed: "amountUsed",
      transferCode: "code",
      contractId: "contractId",
      feeAmount: "feeAmount",
      firstUseDate: "firstUseDate",
      transactionId: "transactionId",
      issuedTo: "issuedTo",
      notes: "notes",
      payee: "payee",
      issuedBy: "who",
      unit: "unit",
      name: "name",
      poNumber: "ponb",
    };

    const syncedValue = record.syncedMoneyCode[recordMapping[field]];

    return record[field] != syncedValue ? "red" : "";
  }

  onChange() {
    this.updateChargeTypeFilters();
    this.updateDriverFilters();
    this.updateTotals();

    this.moneyCodesChange.emit(this.moneyCodes);
  }

  updateTotals() {
    this.totals = [
      this.moneyCodes
        .filter((m) => !m.softDelete)
        .reduce((acc, record) => {
          for (const col of this.totalsCols) {
            acc[col.field] = (acc[col.field] || 0) + record[col.field];
          }
          return acc;
        }, {}),
    ];
  }

  private addEmptyOption(
    drivers: { value: any; label: string }[]
  ): { value: any; label: string }[] {
    return [{ value: null, label: "Select a Driver" }, ...drivers];
  }

  setSoftDelete(record: MoneyCodeGridModel, value: boolean) {
    record.softDelete = value;
    this.onChange();
  }

  updateChargeTypeFilters() {
    for (const record of this.moneyCodes) {
      const chargeType = this.chargeTypes.find((d) => d.value === record.chargeTypeId);
      record.filterChargeType = chargeType ? chargeType.label : "";
    }
  }

  updateDriverFilters() {
    for (const record of this.moneyCodes) {
      const driver = this.drivers.find((d) => d.value === record.batchDriverId);
      record.filterDriverName = driver ? driver.label : "";
    }
  }

  openDriverSettlement(batchDriver: any) {
    const url = `/settlements/loads/${batchDriver.driverId}?batchNo=${batchDriver.batchNo}&tab=Fuel`;
    window.open(url, "_blank");
  }

  exportExcel() {
    const rows = this.moneyCodes;
    const cols = this.moneyCodeCols;

    if (rows === undefined || cols === undefined) {
      this.toastr.warning("Table is empty.", "Unable to Export", {
        closeButton: true,
        enableHtml: true,
      });
      return;
    }

    const exportRows = rows.map((record) => {
      const row = {};
      for (const col of cols) {
        if (col.field == "batchDriverId")
          row[col.header] = this.drivers.find((d) => d.value === record[col.field])?.label;
        else if (col.field == "chargeTypeId")
          row[col.header] = this.chargeTypes.find((d) => d.value === record[col.field])?.label;
        else row[col.header] = record[col.field];
      }
      return row;
    });

    if (exportRows.length > 0) {
      this.excelService.exportAsExcelFile(exportRows, "MoneyCodes");
    } else {
      this.toastr.warning("Table is empty.", "Unable to Export", {
        closeButton: true,
        enableHtml: true,
      });
    }
  }
}
