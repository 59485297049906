<div class="container">
  <div class="col-md-12">
    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item" [class.active]="currentTab == 'fuel'">
        <a class="nav-link" role="tab" (click)="setTab('fuel')">Fuel Records</a>
      </li>
      <li class="nav-item" [class.active]="currentTab == 'money'">
        <a class="nav-link" role="tab" (click)="setTab('money')">Express Codes</a>
      </li>
    </ul>
    <div class="tab-content white-bg-tabs">
      <div *ngIf="currentTab == 'fuel'">
        <app-synced-fuel-records></app-synced-fuel-records>
      </div>
      <div *ngIf="currentTab == 'money'">
        <app-synced-money-codes></app-synced-money-codes>
      </div>
    </div>
  </div>
</div>
