<div>
  <div class="col-md-12 no-padding">
    <legend>Config</legend>
    <div class="row">
      <div class="col-md-2">Trip Charge Upper-Bound Total</div>
      <div class="col-md-3">
        <div style="display: flex">
          <div style="padding-top: 6px">$</div>
          <div style="flex: 1">
            <input
              type="text"
              class="form-control"
              [(ngModel)]="newValues.tripChargeTotal"
              (ngModelChange)="dataChanged()"
            />
          </div>
        </div>
      </div>
      <div class="col-md-3">
        To prevent accidental charges, displays a warning if a billing charge or driver payment
        total is above this amount.
      </div>
    </div>
    <br />

    <div class="row">
      <div class="col-md-2">Charge Type for Non-Highway Fuel</div>
      <div class="col-md-3">
        <p-dropdown
          [options]="chargeTypes"
          [(ngModel)]="nonHighwayFuelChargeTypeId"
          placeholder="Select Charge Type"
          [filter]="true"
          optionLabel="label"
          optionValue="value"
          filterBy="label"
        ></p-dropdown>
      </div>
      <div class="col-md-3">
        This Charge Type will be assigned to any Non-Highway fuel amount on the Sage Fuel Export
      </div>
    </div>
    <br />

    <div class="row">
      <div class="col-md-2">Charge Type for Escrow</div>
      <div class="col-md-3">
        <p-dropdown
          [options]="chargeTypes"
          [(ngModel)]="escrowChargeTypeId"
          placeholder="Select Charge Type"
          filter="true"
          optionLabel="label"
          optionValue="value"
          filterBy="label"
        ></p-dropdown>
      </div>
      <div class="col-md-3">This Charge Type will be used for Escrow on the Sage Batch Export</div>
    </div>
    <br />

    <div class="row">
      <div class="col-md-2">Charge Type for Deficit</div>
      <div class="col-md-3">
        <p-dropdown
          [options]="chargeTypes"
          [(ngModel)]="deficitChargeTypeId"
          placeholder="Select Charge Type"
          filter="true"
          optionLabel="label"
          optionValue="value"
          filterBy="label"
        ></p-dropdown>
      </div>
      <div class="col-md-3">This Charge Type will be used for Deficit on the Sage Batch Export</div>
    </div>
    <br />

    <div class="row">
      <div class="col-md-2">Charge Type for Tcheks</div>
      <div class="col-md-3">
        <p-dropdown
          [options]="chargeTypes"
          [(ngModel)]="tchekChargeTypeId"
          placeholder="Select Charge Type"
          filter="true"
          optionLabel="label"
          optionValue="value"
          filterBy="label"
        ></p-dropdown>
      </div>
      <div class="col-md-3">This Charge Type will be used for Tchek on the Sage Batch Export</div>
    </div>
    <br />

    <div class="row">
      <div class="col-md-2">T-Chek Vendor Id</div>
      <div class="col-md-3">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="newValues.tChekVendorId"
          (ngModelChange)="dataChanged()"
        />
      </div>
      <div class="col-md-3">This Vendor ID will be used on the Sage Fuel Export</div>
    </div>
    <br />

    <div class="row">
      <div class="col-md-2">Invoice Email CC</div>
      <div class="col-md-3">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="newValues.invoiceEmailCc"
          (ngModelChange)="dataChanged()"
        />
      </div>
      <div class="col-md-3">This email address will be automatically cc'd on every invoice email</div>
    </div>
    <br />

    <div class="row">
      <div class="col-md-2">Settlement Email CC</div>
      <div class="col-md-3">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="newValues.settlementsEmailCc"
          (ngModelChange)="dataChanged()"
        />
      </div>
      <div class="col-md-3">This email address will be automatically cc'd on every settlement email</div>
    </div>
    <br />

    <div class="row">
      <div class="col-md-5"></div>
      <div class="col-md-3">
        <button type="button" class="btn btn-warning mr-2" (click)="save()">Save</button>
        <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
      </div>
    </div>
  </div>
</div>
