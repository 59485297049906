import { Component, OnInit } from "@angular/core";
import { RatesService } from "./shared/rates.service";

import { ClientRateViewModel } from "@app/shared/models/rates/ClientRateViewModel.model";
import { DriverRateViewModel } from "@app/shared/models/rates/DriverRateViewModel.model";
import { CustomChargeViewModel } from "@app/shared/models/rates/CustomChargeViewModel.model";
import { LongevityViewModel } from "@app/shared/models/rates/LongevityViewModel.model";
import { FuelSurchargeViewModel } from "@app/shared/models/rates/FuelSurchargeViewModel.model";
import { InsuranceSurchargeViewModel } from "@app/shared/models/rates/InsuranceSurchargeViewModel.model";
import { ClientLaneRateViewModel } from "@app/shared/models/rates/ClientLaneRateViewModel.model";
import { DriverLaneRateViewModel } from "@app/shared/models/rates/DriverLaneRateViewModel.model";

@Component({
  selector: "app-rates-list",
  templateUrl: "./rateslist.component.html",
  styleUrls: ["./rateslist.component.css"],
})
export class RatesListComponent implements OnInit {
  clientRates: ClientRateViewModel[];
  driverRates: DriverRateViewModel[];
  clientLaneRates: ClientLaneRateViewModel[];
  driverLaneRates: DriverLaneRateViewModel[];
  customRates: CustomChargeViewModel[] = [];
  longevityRates: LongevityViewModel[] = [];
  fuelRates: FuelSurchargeViewModel[] = [];
  insuranceRates: InsuranceSurchargeViewModel[] = [];

  sessionId = "rateslistSettings";
  currentTab: string;

  constructor(private ratesService: RatesService) {}

  ngOnInit() {
    this.ratesService.getClientRates().subscribe((rates: any) => {
      this.clientRates = rates;
    });
    this.ratesService.getDriverRates().subscribe((rates: any) => {
      this.driverRates = rates;
    });
    this.ratesService.getClientLaneRates().subscribe((rates: any) => {
      this.clientLaneRates = rates;
    });
    this.ratesService.getDriverLaneRates().subscribe((rates: any) => {
      this.driverLaneRates = rates;
    });
    this.ratesService.getFuelSurcharges().subscribe((rates: any[]) => {
      this.fuelRates = rates;
    });
    this.ratesService.getInsuranceSurcharges().subscribe((rates: any[]) => {
      this.insuranceRates = rates;
    });
    this.ratesService.getLongevityList().subscribe((rates: any[]) => {
      this.longevityRates = rates;
    });
    this.ratesService.getCustomChargesAndGuarantees().subscribe((rates: any[]) => {
      this.customRates = rates;
    });

    const settings = JSON.parse(sessionStorage.getItem(this.sessionId));

    if (settings) {
      this.currentTab = settings.currentTab;
    } else {
      this.setTab("Client");
    }
  }

  setTab(tabName: string) {
    this.currentTab = tabName;
    this.saveSetting("currentTab", tabName);
  }

  saveSetting(settingName: string, settingValue: any) {
    let settings = JSON.parse(sessionStorage.getItem(this.sessionId)) || {};
    settings[settingName] = settingValue;
    sessionStorage.setItem(this.sessionId, JSON.stringify(settings));
  }

  saveAllSettings() {
    this.setTab(this.currentTab);
  }
}
