import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { LocationsService } from "@app/locations/shared/locations.service";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map, switchMap } from "rxjs/operators";
import { LazyLoadEvent } from "primeng/api";
import { Table } from "primeng/table";

@Component({
  selector: "app-location-picker",
  templateUrl: "./location-picker.component.html",
  styleUrls: ["./location-picker.component.css"],
})
export class LocationPickerComponent implements OnInit {
  @ViewChild("dto") dt: Table;

  // I know these inputs are a bit bizarre, but it was necessary to make sure
  // everything worked when changing this into a component. It could definitely
  // use refactoring.
  @Input() disabled: boolean = false;

  @Input() tableId: string;
  @Input() escapeElementId?: string;
  @Input() searchFieldId?: string;
  @Input() nextId?: string;
  @Input() buttonId: string;

  @Input() isSelected: any;
  @Input() locationName: string;
  @Input() locId: string;
  @Input() tripData?: any;
  @Output() tripDataChanged = new EventEmitter<any>();

  @Output() locationSelected = new EventEmitter<any>();
  @Output() firstItemShiftTabbed = new EventEmitter<any>();

  cols: any[];
  size: any = 100;
  loading = true;
  isArrow = false;
  isTableHidden = true;
  rowIndex: any = 0;
  searchText: any = "";
  lastSearch: any = "";
  totalRecords: any = 0;
  recordsBring: any = 0;
  scrollValue: any = 0;
  _locations: any = [];
  scrollLazyUp: any = false;
  scrollLazyDown: any = false;
  currentSelection: any;
  init: boolean = true;

  subject = new Subject();

  constructor(private _LocationService: LocationsService, private cdr: ChangeDetectorRef) {}

  adjustTable(backwards: boolean): void {
    this.currentSelection = this._locations[this.rowIndex];

    this.dt.scrollTo({ top: 40 * this.rowIndex });
  }

  ngOnInit(): void {
    this.subject
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((event: LazyLoadEvent) =>
          this._LocationService
            .listAllLocations(this.searchText, this.size, event.first, false)
            .pipe(map((locations) => ({ event, locations })))
        )
      )
      .subscribe(({ event, locations }) => {
        this.updateLocationsList(event, locations);
        this.cdr.markForCheck();
      });

    this._locations = [];

    this.cols = [
      { field: "locId", header: "ID", width: 100 },
      { field: "country", header: "Country", width: 110 },
      { field: "city", header: "City", width: 150 },
      { field: "name", header: "Name", width: 200 },
      { field: "state", header: "State", width: 55 },
      { field: "zipcode", header: "Zip", width: 70 },
      { field: "address", header: "Address", width: 200 },
      { field: "address2", header: "Address L2", width: 90 },
      { field: "isShipper", header: "Shipper", width: 70 },
      { field: "isConsignee", header: "Consignee", width: 85 },
      { field: "confidenceLevel", header: "Accuracy", width: 80 },
    ];
  }

  debouncedLazyLoad(event: LazyLoadEvent) {
    this._LocationService
      .listAllLocations(this.searchText, this.size, event.first, false)
      .subscribe((locations: any) => {
        this.updateLocationsList(event, locations);
        this.cdr.markForCheck();
      });
  }

  updateLocationsList(event: LazyLoadEvent, locations: any) {
    const { Locations, totalRecords } = locations;
    this.totalRecords = totalRecords;
    if (
      this.searchText !== this.lastSearch ||
      Locations.length != this._locations.length ||
      this.init
    ) {
      this.rowIndex = 0;
      this.currentSelection = Locations[0];
      // if (!this.init) this.onLocationSelected(this.currentSelection);
    }

    this._locations = Locations;
    this.lastSearch = this.searchText;
    this.init = false;
  }

  lazyLoadLocations(event: LazyLoadEvent) {
    // only debounce searches, because otherwise it doesn't seem to load the right rows
    const isSearchUpdate = this.searchText !== this.lastSearch;
    if (isSearchUpdate) {
      this.subject.next(event);
    } else {
      this.debouncedLazyLoad(event);
    }
  }

  toggleTable() {
    this.isTableHidden = !this.isTableHidden;
    if (!this.isTableHidden) this.showTable();
  }

  showTable() {
    this.searchText = this.locId;
    this.isArrow = false;
    this.isTableHidden = false;

    setTimeout(() => {
      // this will make the execution after the above boolean has changed
      if (this.searchFieldId) document.getElementById(this.searchFieldId).focus();
      this.dt.scrollToVirtualIndex(this.rowIndex);
    }, 50);
  }

  clickOutsideTable() {
    if (!this.isTableHidden) {
      this.hideDropdown();
    }
  }

  filterTable(event: any) {
    this.dt.filterGlobal(event.target.value, "contains");
  }

  onLocationSelected(location: any) {
    if (location) {
      this.locationSelected.emit(location);
    }
  }

  onLocationClick(location: any) {
    this.onLocationSelected(location);
    this.hideDropdown();
  }

  searchKeyDownHandler(event) {
    const key = event.key;
    if (key == "Tab") {
      event.preventDefault();
      if (event.shiftKey && event.key === "Tab") {
        this.hideDropdown();
        this.firstItemShiftTabbed.emit();
      } else {
        const rows = this._locations;
        if (rows.length == 1) {
          this.hideDropdown();
        } else {
          setTimeout(() => {
            document.getElementById(this.buttonId).click();
            if (
              this.tripData &&
              (this.tripData.originLocId == null ||
                typeof this.tripData.originLocId == "undefined" ||
                this.tripData.originLocId.length == 0)
            ) {
              setTimeout(() => {
                if (this.escapeElementId) document.getElementById(this.escapeElementId).focus();
              });
            } else {
              setTimeout(() => {
                if (this.nextId) document.getElementById(this.nextId).focus();
              });
            }
          });
        }
        if (this.currentSelection) {
          this.onLocationSelected(this.currentSelection);
        }
      }
    } else if (key === "ArrowDown") {
      if (this.rowIndex < this._locations.length - 1) this.rowIndex++;
      this.adjustTable(false);
    } else if (key === "ArrowUp") {
      if (this.rowIndex > 0) this.rowIndex--;
      this.adjustTable(true);
    } else if (key === "Enter") {
      this.onLocationSelected(this.currentSelection);
      this.hideDropdown();
    } else if (key === "Escape") {
      this.hideDropdown();
      setTimeout(() => {
        if (this.escapeElementId) document.getElementById(this.escapeElementId).focus();
      });
    }
  }

  tableBtnKeyDownHandler(event) {
    const key = event.key;
    if (key == "Tab") {
      if (event.shiftKey && event.key === "Tab") {
        event.preventDefault();
        this.firstItemShiftTabbed.emit();
      }
    }
  }

  hideDropdown() {
    this.isTableHidden = true;
  }
}
