import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "negativeToParens" })
export class NegativeToParens implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value === null || value === undefined) return;

    if (isNaN(parseFloat(value))) return value;

    // If the number is negative, wrap it in parantheses
    // Give it a minimum of 2 decimal places and a max of 3
    value = parseFloat(value).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 3,
    });

    if (value < 0) {
      return `($${Math.abs(value)})`;
    } else {
      return `$${value}`;
    }
  }
}
