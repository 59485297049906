import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { EfsService } from "@app/efs/efs.service";
import { ToastrService } from "ngx-toastr";
import { LazyLoadEvent } from "primeng/api";
import { Table } from "primeng/table";
import { debounceTime, Subject, Subscription } from "rxjs";

@Component({
  selector: "app-synced-fuel-records",
  templateUrl: "./synced-fuel-records.component.html",
  styleUrl: "./synced-fuel-records.component.css",
})
export class SyncedFuelRecordsComponent implements OnInit, OnDestroy {
  @ViewChild("syncedFuelTable", { static: true }) table: Table;

  rows = 100;
  totalRecords = 0;
  records = [];
  isLoading = true;

  filtersSubject = new Subject();
  filtersSubscription: Subscription;
  filters = {
    transactionId: "",
    // transactionDate: "",
    locationName: "",
    locationCity: "",
    locationState: "",
    driverName: "",
    drId: "",
    unit: "",
    billingCurrency: "",
    // transactionFee: "",
    chainId: "",
    chainName: "",
    productCode: "",
    // lineItemDiscount: "",
    // transactionAmount: "",
    // transactionDiscount: "",
    // quantity: "",
    // ppu: "",
    startDate: null,
    endDate: null,
  };

  columns = [
    { field: "transactionId", header: "Transaction Id", width: "100px", type: "string" },
    { field: "transactionDate", header: "Transaction Date", width: "100px", type: "date" },
    { field: "locationName", header: "Location Name", width: "100px", type: "string" },
    { field: "locationCity", header: "City", width: "100px", type: "string" },
    { field: "locationState", header: "State", width: "100px", type: "string" },
    { field: "driverName", header: "Driver", width: "100px", type: "string" },
    { field: "drId", header: "Driver ID", width: "100px", type: "number" },
    { field: "unit", header: "Unit", width: "100px", type: "number" },
    { field: "billingCurrency", header: "Billing Currency", width: "100px", type: "string" },
    { field: "transactionFee", header: "Transaction Fee", width: "100px", type: "currency" },
    { field: "chainId", header: "Chain Id", width: "100px", type: "number" },
    { field: "chainName", header: "Chain", width: "100px", type: "string" },
    { field: "productCode", header: "Product Code", width: "100px", type: "string" },
    { field: "lineItemDiscount", header: "Line Item Discount", width: "100px", type: "currency" },
    { field: "transactionAmount", header: "Transaction Amount", width: "100px", type: "currency" },
    { field: "retailAmount", header: "Retail Amount", width: "100px", type: "currency" },
    {
      field: "transactionDiscount",
      header: "Transaction Discount",
      width: "100px",
      type: "currency",
    },
    { field: "quantity", header: "Quantity", width: "100px", type: "number" },
    { field: "ppu", header: "PPG", width: "100px", type: "currency" },
    { field: "retailPPU", header: "Retail PPG", width: "100px", type: "currency" },
  ];

  constructor(private efsService: EfsService, private toastr: ToastrService) {}

  ngOnInit() {
    this.filtersSubscription = this.filtersSubject.pipe(debounceTime(500)).subscribe(() => {
      this.table.first = 0;
      this.table.firstChange.emit(this.table.first);
      this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
    });
  }

  ngOnDestroy() {
    this.filtersSubscription.unsubscribe();
  }

  onLazyLoad(event: LazyLoadEvent) {
    const observable = this.efsService.getSyncedFuelRecords(
      this.filters,
      event.rows,
      event.first,
      event.sortField,
      event.sortOrder
    );

    this.isLoading = true;
    observable.subscribe((result: any) => {
      this.records = result.fuelRecords;
      this.totalRecords = result.totalRecords;
      this.isLoading = false;
    });
  }

  isFilterable(field: string) {
    return Object.keys(this.filters).includes(field);
  }

  openGrid(record: any) {
    const url = `/efsBatchHistory?batch=${record.batchId}&tab=fuel`;
    window.open(url, "_blank");
  }

  exportExcel() {
    this.efsService
      .downloadSyncedFuelRecords(this.filters, this.table.sortField, this.table.sortOrder)
      .subscribe({
        next: (response) => {
          const blob = new Blob([response], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "FuelRecords.csv";
          a.click();
          window.URL.revokeObjectURL(url);
        },
        error: (error) => {
          this.toastr.error("An error occurred while exporting the file.");
        },
      });
  }
}
