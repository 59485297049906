import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { EfsService } from "../efs.service";
import { ToastrService } from "ngx-toastr";
import { CanComponentDeactivate } from "@app/shared/guards/confirmation/confirmation.guard";
import { FuelRecordGridModel } from "@app/shared/models/fuel/FuelRecordGridModel.model";
import { MoneyCodeGridModel } from "@app/shared/models/fuel/MoneyCodeGridModel";

@Component({
  selector: "app-efs-import",
  templateUrl: "./efs-import.component.html",
  styleUrl: "./efs-import.component.css",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfsImportComponent implements OnInit, CanComponentDeactivate {
  lastSyncDate: Date;
  syncing: boolean = false;
  importOpen: boolean = false;

  importErrors: any[] = [];
  importWarnings: any[] = [];
  fuelRecords: FuelRecordGridModel[] = [];
  moneyCodes: MoneyCodeGridModel[] = [];

  errorCols = [
    { field: "message", header: "Error Message" },
    { field: "driverId", header: "Driver ID" },
    { field: "driverName", header: "Driver Name" },
  ];

  warningCols = [
    { field: "message", header: "Warning Message" },
    { field: "transactionId", header: "Transaction ID" },
    { field: "driverId", header: "Driver ID" },
    { field: "driverName", header: "Driver Name" },
  ];

  constructor(
    private efsService: EfsService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) {}

  confirm() {
    return true;
  }

  ngOnInit() {
    this.efsService.getLastSyncDate().subscribe((data: any) => {
      this.lastSyncDate = data;
      this.cdr.markForCheck();
    });
  }

  sync() {
    if (this.syncing) return;
    this.syncing = true;

    this.efsService.sync().subscribe({
      next: () => {
        this.toastr.success("EFS data synced successfully");
        this.lastSyncDate = new Date();
        this.syncing = false;
        this.cdr.markForCheck();
      },
      error: (error) => {
        this.toastr.error("Error syncing EFS data");
        console.error(error);
        this.syncing = false;
        this.cdr.markForCheck();
      },
    });
  }

  import() {
    this.importOpen = true;
  }

  onImportCompleted(res: any) {
    const fuelRes = res.fuelRecords;
    const moneyRes = res.moneyCodes;
    this.importErrors = fuelRes.errors;
    this.importWarnings = fuelRes.warnings;
    this.fuelRecords = fuelRes.records;
    this.moneyCodes = moneyRes.records;
  }

  validateMoneyCodes() {
    const missingChargeTypes = this.moneyCodes.filter((x) => !x.softDelete && !x.chargeTypeId);

    if (missingChargeTypes.length) {
      this.toastr.error("Some express codes are missing charge types");
      return false;
    }

    return true;
  }

  save() {
    if (!this.validateMoneyCodes()) return;

    this.efsService.finalizeImport(this.fuelRecords, this.moneyCodes).subscribe({
      next: () => {
        this.toastr.success("Records imported successfully");
        this.importOpen = false;
        this.importErrors = [];
        this.importWarnings = [];
        this.fuelRecords = [];
        this.moneyCodes = [];
        this.cdr.markForCheck();
      },
      error: (error) => {
        this.toastr.error("Error importing records");
        console.error(error);
        this.cdr.markForCheck();
      },
    });
  }
}
