import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";

@Injectable({
  providedIn: "root",
})
export class ExcelExportService {
  constructor() {}

  exportAsExcelFile(json: any[], excelFileName: string): void {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(json);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
  }
}
