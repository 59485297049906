import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { EfsService } from "@app/efs/efs.service";
import { ToastrService } from "ngx-toastr";
import { LazyLoadEvent } from "primeng/api";
import { Table } from "primeng/table";
import { debounceTime, Subject, Subscription } from "rxjs";

@Component({
  selector: "app-synced-money-codes",
  templateUrl: "./synced-money-codes.component.html",
  styleUrl: "./synced-money-codes.component.css",
})
export class SyncedMoneyCodesComponent {
  @ViewChild("moneyCodesTable", { static: true }) table: Table;

  rows = 100;
  totalRecords = 0;
  records = [];
  isLoading = true;

  filtersSubject = new Subject();
  filtersSubscription: Subscription;
  filters = {
    // activeDate: "",
    // amount: "",
    // amountUsed: "",
    code: "",
    // contractId: "",
    // codeCreatedDate: "",
    // deductFees: "",
    // feeAmount: "",
    // feeType: "",
    // firstUseDate: "",
    // moneyCodeId: "",
    // issuedTo: "",
    // notes: "",
    // numUses: "",
    // payee: "",
    // status: "",
    // voided: "",
    // voidDate: "",
    // who: "",
    // unit: "",
    // name: "",
    // ponb: "",
    startDate: null,
    endDate: null,
  };

  columns = [
    { field: "activeDate", header: "Active", type: "date" },
    { field: "firstUseDate", header: "First Use", type: "date" },
    { field: "amount", header: "Amount", type: "number" },
    { field: "amountUsed", header: "Amount Used", type: "number" },
    { field: "code", header: "Code", type: "number" },
    { field: "contractId", header: "Contract Id", type: "string" },
    { field: "codeCreatedDate", header: "Code Created", type: "date" },
    { field: "deductFees", header: "Deduct Fees", type: "string" },
    { field: "feeAmount", header: "Fee Amount", type: "currency" },
    { field: "feeType", header: "Fee Type", type: "string" },
    { field: "moneyCodeId", header: "Money Code", type: "number" },
    { field: "issuedTo", header: "Issued To", type: "string" },
    { field: "notes", header: "Notes", type: "string" },
    { field: "numUses", header: "Num Uses", type: "number" },
    { field: "payee", header: "Payee", type: "string" },
    { field: "status", header: "Status", type: "string" },
    { field: "voided", header: "Voided", type: "string" },
    { field: "voidDate", header: "Void Date", type: "date" },
    { field: "who", header: "Who", type: "string" },
    { field: "unit", header: "Unit", type: "string" },
    { field: "name", header: "Name", type: "string" },
    { field: "ponb", header: "PONB", type: "string" },
  ];

  constructor(private efsService: EfsService, private toastr: ToastrService) {}

  ngOnInit() {
    this.filtersSubscription = this.filtersSubject.pipe(debounceTime(500)).subscribe(() => {
      this.table.first = 0;
      this.table.firstChange.emit(this.table.first);
      this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
    });
  }

  ngOnDestroy() {
    this.filtersSubscription.unsubscribe();
  }

  onLazyLoad(event: LazyLoadEvent) {
    const observable = this.efsService.getSyncedMoneyCodes(
      this.filters,
      event.rows,
      event.first,
      event.sortField,
      event.sortOrder
    );

    this.isLoading = true;
    observable.subscribe((result: any) => {
      this.records = result.records;
      this.totalRecords = result.totalRecords;
      this.isLoading = false;
    });
  }

  isFilterable(field: string) {
    return Object.keys(this.filters).includes(field);
  }

  openGrid(record: any) {
    const url = `/efsBatchHistory?batch=${record.batchId}&tab=express`;
    window.open(url, "_blank");
  }

  exportExcel() {
    this.efsService
      .downloadSyncedMoneyCodes(this.filters, this.table.sortField, this.table.sortOrder)
      .subscribe({
        next: (response) => {
          const blob = new Blob([response], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "MoneyCodes.csv";
          a.click();
          window.URL.revokeObjectURL(url);
        },
        error: (error) => {
          this.toastr.error("An error occurred while exporting the file.");
        },
      });
  }
}
