import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ChargeType } from "@app/shared/models/ChargeType.model";
import { SelectItem } from "primeng/api";

@Component({
  selector: "app-settlement-fuel-tab",
  templateUrl: "./settlement-fuel-tab.component.html",
  styleUrls: ["../loads.component.css"],
})
export class SettlementFuelTabComponent implements OnInit, OnChanges {
  @Input() fuelRecords!: any[];
  @Output() fuelRecordChanged = new EventEmitter<any>();
  @Input() moneyCodes!: any[];
  @Output() moneyCodeChanged = new EventEmitter<any>();
  @Input() batchSelected!: any;

  fuelRecordTotals: any = {};
  moneyCodeTotals: any = {};
  chargeTypes: SelectItem;

  cols = [
    { field: "transactionId", header: "Transaction", type: "code" },
    { field: "transactionDate", header: "Date", type: "date" },
    { field: "locationName", header: "Location", type: "string" },
    { field: "locationCity", header: "City", type: "string" },
    { field: "locationState", header: "State", type: "string" },
    { field: "unit", header: "Unit", type: "string" },
    { field: "quantity", header: "Quantity", type: "number" },
    { field: "ppu", header: "PPG", type: "currency" },
    { field: "productCode", header: "Product", type: "string" },
    { field: "settlementColumn", header: "Type", type: "string" },
    { field: "fuel", header: "Fuel", type: "currency" },
    { field: "other", header: "Other", type: "currency" },
    { field: "cash", header: "Cash", type: "currency" },
    { field: "fee", header: "Fee", type: "currency" },
    { field: "discount", header: "Discount", type: "currency" },
  ];

  moneyCodeCols = [
    { field: "transactionId", header: "Transaction", type: "code" },
    { field: "activeDate", header: "Date", type: "date" },
    { field: "issuedTo", header: "Location", type: "string" },
    { field: "", header: "City", type: "string" },
    { field: "", header: "State", type: "string" },
    { field: "unit", header: "Unit", type: "string" },
    { field: "", header: "Quantity", type: "number" },
    { field: "", header: "PPG", type: "currency" },
    { field: "", header: "Product", type: "expressCode" },
    { field: "settlementColumn", header: "Type", type: "string" },
    { field: "fuel", header: "Fuel", type: "currency" },
    { field: "other", header: "Other", type: "currency" },
    { field: "cash", header: "Cash", type: "currency" },
    { field: "feeAmount", header: "Fee", type: "currency" },
    { field: "discount", header: "Discount", type: "currency" },
  ];

  constructor(private _ActivatedRoute: ActivatedRoute) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.calculateTotals();
  }

  ngOnInit(): void {
    const resolvers = this._ActivatedRoute.snapshot.data;
    this.chargeTypes = resolvers.chargeTypes;
  }

  calculateTotals() {
    const totalColumns = ["fuel", "other", "cash", "discount", "fee", "total"];

    for (const row of this.fuelRecords) {
      row.total = row.fuel + row.other + row.cash + row.discount + row.fee;
    }

    for (const column of totalColumns) {
      this.fuelRecordTotals[column] = this.fuelRecords.reduce((a, b) => a + b[column], 0);
    }

    const moneytotalColumns = ["fuel", "other", "cash", "feeAmount", "discount", "total"];

    for (const row of this.moneyCodes) {
      row.total = row.fuel + row.other + row.amount + row.discount + row.feeAmount;
    }

    for (const column of moneytotalColumns) {
      this.moneyCodeTotals[column] = this.moneyCodes.reduce((a, b) => a + b[column], 0);
    }
  }

  onRecordChanged(newValue: string | number, fuelRecord: any, field: string) {
    const oldValue = fuelRecord[field];
    const key = `${field}OriginalValue`;
    if (fuelRecord[key] === undefined) {
      fuelRecord[key] = oldValue;
    }
    fuelRecord[`${field}Changed`] = fuelRecord[key] !== newValue;
    fuelRecord.softUpdate = true;
    fuelRecord[field] = newValue;

    this.calculateTotals();

    this.fuelRecordChanged.emit(this.fuelRecords);
  }

  onMoneyCodeChanged(newValue: string | number, moneyCode: any, field: string) {
    const oldValue = moneyCode[field];
    const key = `${field}OriginalValue`;
    if (moneyCode[key] === undefined) {
      moneyCode[key] = oldValue;
    }
    moneyCode[`${field}Changed`] = moneyCode[key] !== newValue;
    moneyCode.softUpdate = true;
    moneyCode[field] = newValue;

    this.calculateTotals();

    this.moneyCodeChanged.emit(this.moneyCodes);
  }

  openFuelExpensesGrid(record: any, tab: string) {
    const url = `/efsBatchHistory?batch=${this.batchSelected.batchId}&tab=${tab}`;
    window.open(url, "_blank");
  }
}
