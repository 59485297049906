<div class="container">
  <div class="col-md-12">
    <legend>EFS Batch History</legend>

    <div class="mr-3">
      <div>
        <p-dropdown
          [options]="batchesList"
          [(ngModel)]="selectedBatch"
          [filter]="true"
          optionLabel="itemName"
          filterBy="itemName"
          (onChange)="loadRecordsForBatch()"
        ></p-dropdown>
      </div>
    </div>

    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item" [class.active]="currentTab == 'fuel'">
        <a class="nav-link" role="tab" (click)="setTab('fuel')">Fuel Expenses</a>
      </li>
      <li class="nav-item" [class.active]="currentTab == 'express'">
        <a class="nav-link" role="tab" (click)="setTab('express')">Express Codes</a>
      </li>
    </ul>
    <div class="tab-content white-bg-tabs">
      <div *ngIf="currentTab == 'fuel'">
        <div style="margin-top: 10px; margin-bottom: 10px">
          <button class="btn btn-primary" (click)="saveFuelRecords()">Save Fuel Records</button>
        </div>

        <app-editable-fuel-records-table
          [(records)]="fuelRecords"
          [showBatchLink]="true"
        ></app-editable-fuel-records-table>

        <div style="margin-bottom: 30px"></div>
      </div>

      <div *ngIf="currentTab == 'express'">
        <div style="margin-top: 10px; margin-bottom: 10px">
          <button class="btn btn-primary" (click)="saveMoneyCodes()">Save Express Codes</button>
        </div>

        <app-editable-money-codes-table
          [(moneyCodes)]="moneyCodes"
          [showBatchLink]="true"
        ></app-editable-money-codes-table>
      </div>
    </div>
  </div>
</div>
