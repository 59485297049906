export class DriverViewModel {
  id: string | number;
  driverNo: string;
  name: string;
  middleName: string;
  lastName: string;
  displayName: string;
  email: string;
  altEmail: string;
  cellphone: string;
  phone: string;
  birthDate: Date;
  hiredDate: Date;
  hiredDateTo: Date;
  licenseNumber: string;
  licenseState: string;
  licenseExpirationDate: Date;
  homeAddress: string;
  city: string;
  state: string;
  zipCode: string;
  emergencyContName: string;
  emergencyContPhone: string;
  taxId: string;
  operatorId: string;
  driverId: string;
  lessorId: string;
  type: string;
  terminationType: string;
  socialSecurityNumber: string;
  efsEffectiveDate?: Date;
  physicalExamDate: Date;
  physicalExamExpirationDate: Date;
  annualReviewDate: Date;
  trainingDateCGMP: Date;
  expirationDateCGMP: Date;
  hazmatTrainingDate: Date;
  hazmatTrainingExpirationDate: Date;
  hmeExpirationDate: Date;
  passportExpirationDate: Date;
  bonusPerMile: number;
  peopleNetId: string;
  terminated: boolean;
  terminationDate: Date;
  details: string;
  isActive: boolean;
  rehire: boolean;
  tchekCardNo?: string;
  isPayTo: boolean;
  earningPercentage: number;
  settlementEmail: string;
  picture: string;
  efsDriverId?: string;

  driverHireDates: any[];
  team: any;
  teamId?: string;
  tractors: any[];

  // client only
  previousTeam: any;
}
