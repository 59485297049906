import { ChargeType } from "../ChargeType.model";

export class EfsChargeTypeMapping {
  id: string;
  categoryCode: string;
  description: string;
  chargeType?: ChargeType;
  dateDeletedUtc?: Date;
  settlementColumn?: string;
  defaultChargeTo?: string; // DRIVER or COMPANY
  chargeTypeId?: string;
}
