<div *ngIf="rateType == 'DRIVER' || rateType == 'LANEDRIVER'" class="row form-group">
  <label class="col-md-2">Drivers</label>
  <div class="col-md-4">
    <app-drivers-selector
      [isEditing]="isEditing"
      [drivers]="drivers"
      [(isDefaultDriver)]="rate.isDefaultDriver"
      [(selectedDrivers)]="selectedDrivers"
    ></app-drivers-selector>
  </div>
</div>
<div class="row form-group">
  <label class="col-md-2">Decimals</label>
  <div class="col-md-4 form-group">
    <div class="p-field-radiobutton" *ngIf="isEditing">
      <p-radioButton
        inputId="decimals"
        name="decimals"
        value="2"
        aria-label="decimals2"
        [(ngModel)]="rate.decimals"
      ></p-radioButton>
      <label for="decimals" class="mr-2">2</label>
      <p-radioButton
        inputId="decimals3"
        name="decimals"
        value="3"
        aria-label="decimals3"
        [(ngModel)]="rate.decimals"
      ></p-radioButton>
      <label for="decimals3" class="mr-2">3</label>
      <p-radioButton
        inputId="decimals4"
        name="decimals"
        value="4"
        aria-label="decimals4"
        [(ngModel)]="rate.decimals"
      ></p-radioButton>
      <label for="decimals4" class="mr-2">4</label>
      <p-radioButton
        inputId="decimals5"
        name="decimals"
        value="5"
        aria-label="decimals5"
        [(ngModel)]="rate.decimals"
      ></p-radioButton>
      <label for="decimals5">5</label>
    </div>
    <div *ngIf="!isEditing">{{ rate.decimals }}</div>
  </div>
</div>

<hr />

<div class="row form-group">
  <label class="col-md-2">Rate By</label>
  <div class="col-md-10 rate-by-box" *ngIf="isEditing">
    <div class="row">
      <div class="col-md-4 form-group">
        <div class="p-field-radiobutton">
          <p-radioButton
            name="rateBy"
            value="WEIGHT"
            [(ngModel)]="rate.weightOrDistance"
            inputId="rateByWeight"
            aria-label="rateByWeight"
          ></p-radioButton>
          <label for="rateByWeight">Weight</label>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            name="rateBy"
            value="DISTANCE"
            [(ngModel)]="rate.weightOrDistance"
            inputId="rateByDistance"
            aria-label="rateByDistance"
          ></p-radioButton>
          <label for="rateByDistance">Distance</label>
        </div>
      </div>
      <div class="col-md-8">
        <div class="row" *ngIf="rate.weightOrDistance === 'WEIGHT'">
          <label class="col-md-3">Rating Unit:</label>
          <p-dropdown
            [options]="allRatingUnits"
            [(ngModel)]="rate.rateByUnits"
            [style]="{ minWidth: '200px' }"
            aria-label="ratingUnit"
            tabbableDropdown
          ></p-dropdown>
        </div>
        <div class="row" *ngIf="rate.weightOrDistance === 'DISTANCE'">
          <label class="col-md-3">Team Charge:</label>
          <input
            *ngIf="isEditing"
            [(ngModel)]="rate.teamCharge"
            style="min-width: 200px"
            type="number"
            min="0"
            step="0.01"
            class="p-inputtext"
          />
        </div>
        <div class="row" *ngIf="rate.weightOrDistance === 'DISTANCE'">
          <label class="col-md-3">Team Charge Type:</label>
          <p-dropdown
            [options]="chargeTypes"
            [(ngModel)]="rate.teamChargeTypeId"
            placeholder="Select a Charge Type"
            aria-label="teamChargeType"
            [style]="{ minWidth: '200px' }"
            tabbableDropdown
          ></p-dropdown>
        </div>
        <div class="row" style="margin-top: 0.25rem">
          <label class="col-md-3" for="oneWayMiles">One Way Miles:</label>
          <p-checkbox
            [(ngModel)]="rate.isOneWay"
            aria-label="oneWayMiles"
            inputId="oneWayMiles"
            binary="true"
            [disabled]="!isEditing"
          ></p-checkbox>
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-4">
        <div class="p-field-radiobutton">
          <p-radioButton
            name="rateByType"
            value="EQUIPMENT"
            [(ngModel)]="rate.equipmentOrProduct"
            inputId="rateByEquipment"
            aria-label="rateByEquipment"
            (click)="ratingTypeChanged()"
          ></p-radioButton>
          <label for="rateByEquipment">Equipment</label>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            name="rateByType"
            value="PRODUCT"
            [(ngModel)]="rate.equipmentOrProduct"
            inputId="rateByProduct"
            aria-label="rateByProduct"
            (click)="ratingTypeChanged()"
          ></p-radioButton>
          <label for="rateByProduct">Product</label>
        </div>
      </div>
      <div class="col-md-8">
        <div class="row">
          <label class="col-md-3">{{ rate.equipmentOrProduct }}:</label>
          <p-multiSelect
            [filter]="false"
            [options]="productTypes"
            [(ngModel)]="selectedProducts"
            (onChange)="onSelectedProductsChange()"
            optionLabel="itemName"
            filterBy="itemName"
            [style]="{ minWidth: '200px' }"
            aria-label="productMultiselect"
            tabbableMultiselect
          ></p-multiSelect>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-10 rate-by-box" *ngIf="!isEditing">
    <div>
      <div>
        {{ rate.weightOrDistance }}
        <span *ngIf="rate.weightOrDistance === 'WEIGHT'">- {{ rate.rateByUnits }}</span>
      </div>
      <div *ngIf="rate.weightOrDistance === 'DISTANCE'">
        Team Charge - {{ rate.teamCharge | currency }}
      </div>
      <div>{{ rate.isOneWay ? "One Way" : "Round Trip" }}</div>
      <div>
        {{ rate.equipmentOrProduct }}
      </div>
    </div>
  </div>
</div>

<hr />

<!-- Tables -->
<div *ngIf="selectedProducts && selectedProducts.length > 0">
  <!-- Distance Rate Products -->
  <div class="row form-group" *ngIf="!isLaneRate">
    <div class="col-md-12">
      <h3>{{ rate.weightOrDistance | titlecase }} Rates</h3>
      <label>Rated per Unit and Mile band</label>
      <p-toolbar styleClass="toolbar-header">
        <div class="ui-toolbar-group-left">
          <button
            pButton
            type="button"
            icon="fa fa-file-excel-o"
            class="p-button-secondary mr-2"
            title="Export to Excel"
            label="Export"
            (click)="exportExcel(mileRates, selectedProductCols, 'distance_rates')"
          ></button>
          <span *ngIf="isEditing">
            <p-fileUpload
              #fileUpload
              pRipple
              class="p-button-secondary p-button mr-2 no-padding upload-button"
              mode="basic"
              [auto]="true"
              customUpload="true"
              (uploadHandler)="
                importExcel(
                  $event,
                  loadBoundedExcelData,
                  selectedProductCols,
                  mileRatesTable,
                  fileUpload
                )
              "
              chooseLabel="Import"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              maxFileSize="1000000"
            >
            </p-fileUpload>
            <app-adjust-by-percent-button [(rows)]="mileRates"></app-adjust-by-percent-button>
          </span>
        </div>
        <label class="toolbar-right-label">Charge Type</label>
        <p-dropdown
          *ngIf="isEditing"
          [options]="chargeTypes"
          [(ngModel)]="rate.singleChargeTypeId"
          placeholder="Select a Charge Type"
          aria-label="singleMilesChargeType"
          tabbableDropdown
        ></p-dropdown>
        <span *ngIf="!isEditing">{{ rate.singleChargeType?.name }}</span>
      </p-toolbar>
      <app-bounded-table
        #mileRatesTable
        [(rows)]="mileRates"
        [firstColumn]="'MILES'"
        [floor]="'floor'"
        [roof]="'roof'"
        [columns]="selectedProductCols"
        [isEditing]="isEditing"
      >
      </app-bounded-table>
    </div>
  </div>
  <!-- Excess Miles -->
  <div class="row form-group" *ngIf="!isLaneRate">
    <div class="col-md-12">
      <label>Excess Miles</label>
      <p-toolbar styleClass="toolbar-header">
        <div class="ui-toolbar-group-left">
          <button
            pButton
            type="button"
            icon="fa fa-file-excel-o"
            class="p-button-secondary mr-2"
            title="Export to Excel"
            label="Export"
            (click)="exportExcel([singleExcessMilesRates], selectedProductCols, 'excess_miles')"
          ></button>
        </div>
        <label class="toolbar-right-label">Charge Type</label>
        <p-dropdown
          *ngIf="isEditing"
          [options]="chargeTypes"
          [(ngModel)]="rate.excessMilesChargeTypeId"
          placeholder="Select a Charge Type"
          aria-label="excessMilesChargeType"
          tabbableDropdown
        ></p-dropdown>
        <span *ngIf="!isEditing">{{ rate.excessMilesChargeType?.name }}</span>
      </p-toolbar>
      <div style="width: fit-content; min-width: 100%">
        <p-table [value]="[{}]" [autoLayout]="true">
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 200px"></th>
              <th *ngFor="let col of selectedProductCols">
                {{ col.name }}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body">
            <tr>
              <td>SINGLE</td>
              <td *ngFor="let col of selectedProductCols">
                <input
                  [(ngModel)]="singleExcessMilesRates[col.name]"
                  type="number"
                  min="0"
                  step="0.01"
                  *ngIf="isEditing"
                  class="p-inputtext size-8"
                  aria-label="SingleExcessMilesRate"
                />
                <span *ngIf="!isEditing">${{ singleExcessMilesRates[col.name] }}</span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  <!-- Lane Specific Overrides -->
  <div class="row form-group" *ngIf="isLaneRate">
    <div class="col-md-12">
      <label>Rated per Unit and Mile band</label>
      <p-toolbar styleClass="toolbar-header">
        <div class="ui-toolbar-group-left">
          <button
            pButton
            type="button"
            icon="fa fa-file-excel-o"
            class="p-button-secondary mr-2"
            title="Export to Excel"
            label="Export"
            (click)="exportExcel(laneOverrides, laneOverrideCols, 'lane_rates')"
          ></button>
          <app-adjust-by-percent-button
            [(rows)]="laneOverrides"
            [columns]="laneOverrideCols"
            *ngIf="isEditing"
          ></app-adjust-by-percent-button>
        </div>
        <label class="toolbar-right-label">Charge Type</label>
        <p-dropdown
          *ngIf="isEditing"
          [options]="chargeTypes"
          [(ngModel)]="rate.singleChargeTypeId"
          placeholder="Select a Charge Type"
          aria-label="singleMilesChargeType"
          tabbableDropdown
        ></p-dropdown>
        <span *ngIf="!isEditing">{{ rate.singleChargeType?.name }}</span>
      </p-toolbar>
      <div style="width: fit-content; min-width: 100%">
        <p-table [value]="laneOverrides">
          <ng-template pTemplate="header">
            <tr>
              <th *ngFor="let col of laneOverrideCols" [style.width.px]="col.width || 150">
                {{ col.name }}
              </th>
              <th style="width: 70px" *ngIf="isEditing">
                <button
                  pButton
                  type="button"
                  icon="pi pi-plus"
                  class="p-button-success"
                  (click)="addLaneOverrideRow()"
                  aria-label="addLaneOverrideRow"
                ></button>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row let-i="rowIndex">
            <tr>
              <td *ngFor="let col of laneOverrideCols">
                <div *ngIf="col.type == 'location'" [style.width.px]="col.width">
                  <app-location-picker
                    *ngIf="isEditing"
                    [isSelected]="row[col.field]"
                    [locationName]="row[col.field]?.name"
                    tableId="searchTable"
                    [locId]="row[col.field]?.locationId"
                    buttonId="openOriginTableBtn"
                    (locationSelected)="setLaneOverrideLocation($event, i, col.field)"
                  ></app-location-picker>
                  <span *ngIf="!isEditing">{{ row[col.field]?.locationId }}</span>
                </div>
                <div *ngIf="col.type == 'date'" [style.width.px]="col.width">
                  <input
                    aria-label="laneDate"
                    *ngIf="isEditing"
                    type="text"
                    [inputMask]="dateInputMask"
                    autocomplete="off"
                    class="form-control input-md"
                    placeholder="mm/dd/yyyy"
                    [(ngModel)]="row[col.field]"
                    [attr.aria-label]="col.name + ' ' + i"
                  />
                  <span *ngIf="!isEditing">{{ row[col.field] | date : "MM/dd/yyyy" }}</span>
                </div>
                <div *ngIf="col.type == 'boolean'">
                  <p-checkbox
                    aria-label="laneCheckbox"
                    [(ngModel)]="row[col.field]"
                    binary="true"
                    [disabled]="!isEditing"
                    [attr.aria-label]="col.name + ' ' + i"
                  ></p-checkbox>
                </div>
                <div *ngIf="col.type == 'miles'">
                  <div *ngIf="!row.forceMiles || !isEditing">
                    <span *ngIf="row.loadingMiles">...</span>
                    <span *ngIf="!row.loadingMiles">{{ row.routeMiles }}</span>
                  </div>
                  <input
                    [(ngModel)]="row[col.field]"
                    type="number"
                    min="0"
                    step="any"
                    *ngIf="row.forceMiles && isEditing"
                    class="p-inputtext size-8"
                    [attr.aria-label]="col.name + ' ' + i"
                  />
                </div>
                <div *ngIf="col.type == 'dollar'">
                  <input
                    [(ngModel)]="row[col.field]"
                    type="number"
                    min="0"
                    step="any"
                    *ngIf="isEditing"
                    class="p-inputtext size-8"
                    [attr.aria-label]="col.name + ' ' + i"
                  />
                  <span *ngIf="!isEditing">${{ row[col.field] }}</span>
                </div>
              </td>
              <td *ngIf="isEditing">
                <button
                  pButton
                  pRipple
                  type="button"
                  class="p-button-danger"
                  icon="pi pi-trash"
                  (click)="deleteLaneOverrideRow(i)"
                ></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  <hr />
  <h3>{{ payOrCharges }}</h3>
  <div>
    <label class="toolbar-right-label">Stop Charge Type:</label>
    <p-dropdown
      *ngIf="isEditing"
      [options]="chargeTypes"
      [(ngModel)]="rate.stopChargeTypeId"
      placeholder="Select a Charge Type"
      aria-label="stopChargeType"
      tabbableDropdown
    ></p-dropdown>
    <span *ngIf="!isEditing">{{ rate.stopChargeType?.name }}</span>
  </div>
  <!-- Load Charges -->
  <ng-container>
    <div class="row form-group">
      <div class="col-md-12">
        <label>Load {{ payOrCharges }}</label>
        <div class="p-field-checkbox" style="margin-bottom: 0.5rem">
          <p-checkbox
            [(ngModel)]="rate.loadSetFreeTimePerStop"
            binary="true"
            inputId="loadFree"
            (onChange)="updateLoadsTable(loadCharges, rate.loadSetFreeTimePerStop)"
            aria-label="setFreeTimePerStop"
            [disabled]="!isEditing"
          ></p-checkbox>
          <label for="loadFree">Set Free Time Per Stop (Hours)</label>
        </div>

        <p-toolbar styleClass="toolbar-header">
          <div class="ui-toolbar-group-left">
            <button
              pButton
              type="button"
              icon="fa fa-file-excel-o"
              class="p-button-secondary mr-2"
              title="Export to Excel"
              label="Export"
              (click)="exportExcel(loadCharges, loadCols, 'load_charges')"
            ></button>
          </div>
          <label class="toolbar-right-label">Charge Type</label>
          <p-dropdown
            *ngIf="isEditing"
            [options]="chargeTypes"
            [(ngModel)]="rate.loadChargeTypeId"
            placeholder="Select a Charge Type"
            aria-label="loadChargeType"
            tabbableDropdown
          ></p-dropdown>
          <span *ngIf="!isEditing">{{ rate.loadChargeType?.name }}</span>
        </p-toolbar>
        <app-bounded-table
          #loadChargesTable
          [(rows)]="loadCharges"
          firstColumn="MILES"
          floor="floor"
          roof="roof"
          [columns]="loadCols"
          [defaultRange]="100"
          [isEditing]="isEditing"
          lastInputColumnField="stop4"
          (maxChanged)="onUndelayedMaxChange($event)"
        >
        </app-bounded-table>
      </div>
    </div>
    <hr />
  </ng-container>
  <!-- Unload Charges -->
  <ng-container>
    <div class="row form-group">
      <div class="col-md-12">
        <label>Unload {{ payOrCharges }}</label>
        <div class="p-field-checkbox" style="margin-bottom: 0.5rem">
          <p-checkbox
            [(ngModel)]="rate.unloadSetFreeTimePerStop"
            binary="true"
            inputId="unloadFree"
            (onChange)="updateLoadsTable(unloadCharges, rate.unloadSetFreeTimePerStop)"
            [disabled]="!isEditing"
          ></p-checkbox>
          <label for="unloadFree">Set Free Time Per Stop (Hours)</label>
        </div>
        <p-toolbar styleClass="toolbar-header">
          <div class="ui-toolbar-group-left">
            <button
              pButton
              type="button"
              icon="fa fa-file-excel-o"
              class="p-button-secondary mr-2"
              title="Export to Excel"
              label="Export"
              (click)="exportExcel(unloadCharges, loadCols, 'unload_charges')"
            ></button>
          </div>
          <label class="toolbar-right-label">Charge Type</label>
          <p-dropdown
            *ngIf="isEditing"
            [options]="chargeTypes"
            [(ngModel)]="rate.unloadChargeTypeId"
            placeholder="Select a Charge Type"
            aria-label="unloadChargeType"
            tabbableDropdown
          ></p-dropdown>
          <span *ngIf="!isEditing">{{ rate.unloadChargeType?.name }}</span>
        </p-toolbar>
        <app-bounded-table
          #unloadChargesTable
          [(rows)]="unloadCharges"
          firstColumn="MILES"
          floor="floor"
          roof="roof"
          [columns]="unloadCols"
          [defaultRange]="100"
          [isEditing]="isEditing"
          lastInputColumnField="stop4"
          (maxChanged)="onUndelayedMaxChange($event)"
        >
        </app-bounded-table>
      </div>
    </div>
    <hr />
  </ng-container>
  <!-- Delay Charges -->
  <ng-container>
    <div class="row form-group">
      <div class="col-md-12">
        <label>Delay {{ payOrCharges }} - Load/Unload combined</label>
        <div class="p-field-checkbox" style="margin-bottom: 0.5rem">
          <p-checkbox
            aria-label="setFreeTimePerStop"
            [(ngModel)]="rate.delaySetFreeTimePerStop"
            binary="true"
            inputId="delayFree"
            (onChange)="updateLoadsTable(delayedCharges, rate.delaySetFreeTimePerStop)"
            [disabled]="!isEditing"
          ></p-checkbox>
          <label for="delayFree">Set Free Time Per Stop (Hours)</label>
        </div>
        <p-toolbar styleClass="toolbar-header">
          <div class="ui-toolbar-group-left">
            <button
              pButton
              type="button"
              icon="fa fa-file-excel-o"
              class="p-button-secondary mr-2"
              title="Export to Excel"
              label="Export"
              (click)="exportExcel(delayedCharges, loadCols, 'delayed_charges')"
            ></button>
          </div>
          <label class="toolbar-right-label">Charge Type</label>
          <p-dropdown
            *ngIf="isEditing"
            [options]="chargeTypes"
            [(ngModel)]="rate.delayChargeTypeId"
            placeholder="Select a Charge Type"
            aria-label="delayChargeType"
            tabbableDropdown
          ></p-dropdown>
          <span *ngIf="!isEditing">{{ rate.delayChargeType?.name }}</span>
        </p-toolbar>
        <app-bounded-table
          #delayedChargesTable
          [(rows)]="delayedCharges"
          firstColumn="MILES"
          floor="floor"
          roof="roof"
          [columns]="delayCols"
          [defaultRange]="100"
          [isEditing]="isEditing"
          lastInputColumnField="stop4"
        >
        </app-bounded-table>
      </div>
    </div>
    <hr />
  </ng-container>
</div>
