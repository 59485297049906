import { Component, OnInit } from "@angular/core";
import { FuelRecordGridModel } from "@app/shared/models/fuel/FuelRecordGridModel.model";
import { EfsService } from "../efs.service";
import { SettlementsService } from "@app/settlements/shared/settlements.service";
import { ActivatedRoute } from "@angular/router";
import { param } from "jquery";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-efs-batch-history",
  templateUrl: "./efs-batch-history.component.html",
  styleUrl: "./efs-batch-history.component.css",
})
export class EfsBatchHistoryComponent implements OnInit {
  currentTab: string;
  batchesList = [];
  selectedBatch = null;

  fuelRecords: FuelRecordGridModel[] = [];
  moneyCodes: any[] = [];

  constructor(
    private efsService: EfsService,
    private settlementsService: SettlementsService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.currentTab = "fuel";

    this.settlementsService.getGlobalBatchListDropDown().subscribe((batchList: any) => {
      this.batchesList = batchList;

      this.activatedRoute.queryParams.subscribe((params) => {
        if (params.batch) {
          this.selectedBatch = this.batchesList.find((batch) => batch.id === params.batch);
          if (!this.selectedBatch) this.selectedBatch = this.batchesList[0];
          this.loadRecordsForBatch();
        } else if (this.batchesList.length > 0) {
          this.selectedBatch = this.batchesList[0];
          this.loadRecordsForBatch();
        }

        if (params.tab) {
          this.setTab(params.tab);
        }
      });
    });
  }

  loadRecordsForBatch() {
    this.efsService.getFuelRecordsForBatch(this.selectedBatch.id).subscribe({
      next: (data: FuelRecordGridModel[]) => {
        this.fuelRecords = data;
      },
      error: (error: any) => {
        console.error("Error fetching fuel records for batch:", error);
        this.toastr.error("Error fetching fuel records for batch");
      },
    });

    this.efsService.getMoneyCodesForBatch(this.selectedBatch.id).subscribe({
      next: (data: any[]) => {
        this.moneyCodes = data;
      },
      error: (error: any) => {
        console.error("Error fetching money records for batch:", error);
        this.toastr.error("Error fetching money records for batch");
      },
    });
  }

  saveFuelRecords() {
    this.efsService.updateFuelRecordsForBatch(this.selectedBatch.id, this.fuelRecords).subscribe({
      next: () => {
        this.toastr.success("Fuel records saved");
        this.loadRecordsForBatch();
      },
      error: (error: any) => {
        console.error("Error saving fuel records:", error);
        this.toastr.error("Error saving fuel records");
      },
    });
  }

  saveMoneyCodes() {
    this.efsService.updateMoneyCodesForBatch(this.selectedBatch.id, this.moneyCodes).subscribe({
      next: () => {
        this.toastr.success("Express codes saved");
        this.loadRecordsForBatch();
      },
      error: (error: any) => {
        console.error("Error saving express codes:", error);
        this.toastr.error("Error saving express codes");
      },
    });
  }

  setTab(tabName: string) {
    this.currentTab = tabName;
  }
}
