import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { StatesServices } from "@app/shared/services/states/states.service";
import { authenticationService } from "@app/login/auth.service";
import { Permissions } from "@models/permissions.enum";

import { RatesService } from "../shared/rates.service";
import { EditClientRatesComponent } from "@app/rates/edit-rates/rate-types/edit-client-rates/edit-client-rates.component";
import { EditFuelSurchargesComponent } from "@app/rates/edit-rates/rate-types/edit-fuel-surcharges/edit-fuel-surcharges.component";
import { EditDriverFuelSurchargesComponent } from "./rate-types/edit-driver-fuel-surcharges/edit-driver-fuel-surcharges.component";
import { EditInsuranceSurchargesComponent } from "@app/rates/edit-rates/rate-types/edit-insurance-surcharges/edit-insurance-surcharges.component";
import { EditLongevityComponent } from "./rate-types/edit-longevity/edit-longevity.component";
import { EditCustomChargesComponent } from "./rate-types/edit-custom-charges/edit-custom-charges.component";
import { SelectItem } from "primeng/api";
import { CanComponentDeactivate } from "@app/shared/guards/confirmation/confirmation.guard";
import { MaskService } from "../../shared/services/mask/mask.service";
import { GasRegion } from "@app/shared/models/fuel/GasRegion.model";
import { DriverRateViewModel } from "@app/shared/models/rates/DriverRateViewModel.model";
import { BaseRate } from "@app/shared/models/rates/BaseRate.model";
import { ClientRateViewModel } from "@app/shared/models/rates/ClientRateViewModel.model";
import { FuelSurchargeViewModel } from "@app/shared/models/rates/FuelSurchargeViewModel.model";
import { LongevityViewModel } from "@app/shared/models/rates/LongevityViewModel.model";
import { CustomChargeViewModel } from "@app/shared/models/rates/CustomChargeViewModel.model";
import { InsuranceSurchargeViewModel } from "@app/shared/models/rates/InsuranceSurchargeViewModel.model";
import { ClientLaneRateViewModel } from "@app/shared/models/rates/ClientLaneRateViewModel.model";
import { DriverLaneRateViewModel } from "@app/shared/models/rates/DriverLaneRateViewModel.model";
import { DriverFuelSurchargeViewModel } from "@app/shared/models/rates/DriverFuelSurchargeViewModel.model";

@Component({
  selector: "app-edit-rates",
  templateUrl: "./edit-rates.component.html",
  styleUrls: ["./edit-rates.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditRatesComponent implements OnInit, CanComponentDeactivate {
  @Input() isCreating = false;
  @Input() isEditing = false;
  @Input() rateType: string;
  rateLoaded = false;

  @ViewChild("editClient", { static: false })
  editClientRates: EditClientRatesComponent;
  @ViewChild("editFuel", { static: false })
  editFuelSurcharges: EditFuelSurchargesComponent;
  @ViewChild("editDriverFuel", { static: false })
  editDriverFuelSurcharges: EditDriverFuelSurchargesComponent;
  @ViewChild("editInsurance", { static: false })
  editInsuranceSurcharges: EditInsuranceSurchargesComponent;
  @ViewChild("editLongevity", { static: false })
  editLongevityComponent: EditLongevityComponent;
  @ViewChild("editCustomCharges", { static: false })
  editCustomChargesComponent: EditCustomChargesComponent;

  // page state
  rateId: number;
  pageTitle: string;
  useLocations: boolean;
  useValidFrom: boolean = true;
  useFuelRegions: boolean = false;
  useChargeType: boolean;
  hideBillTos: boolean;

  productSettings: any;
  // dropdown data
  states = [];
  chargeTypes: SelectItem[] = [];

  // fuel surcharge values
  // these values are initialized in the fuel surcharge component
  fuelRegions: GasRegion[] = [];
  selectedRegions: GasRegion[] = [];

  // rule settings
  rate: BaseRate = {
    // version: 1,
    name: "",
    isActive: true,
    description: "",
    priority: 1,
    clients: [],
    drivers: [],
    origins: [],
    destinations: [],
    validFromUtc: new Date(),
    validToUtc: null,
    isDefaultBillTo: false,
    version: 1,
    isValid: true,
  };
  dateInputMask: any;

  constructor(
    private route: ActivatedRoute,
    private authService: authenticationService,
    private ratesService: RatesService,
    private maskService: MaskService,
    private statesService: StatesServices,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.states = this.statesService.getAllStates();
    this.chargeTypes = this.route.snapshot.data["chargeTypes"];

    this.dateInputMask = this.maskService.getInputDateOnlyMask();

    this.route.params.subscribe((params) => {
      this.rateId = params["id"];
      this.rateType = params["rateType"].toUpperCase();

      if (!this.isCreating) {
        this.loadRateValues();
      } else {
        this.isEditing = true;
      }

      switch (this.rateType) {
        case "CLIENT":
          this.pageTitle = "Client Rate Details";
          this.useLocations = true;
          this.useChargeType = false;
          break;
        case "DRIVER":
          this.pageTitle = "Driver Rate Details";
          this.useLocations = true;
          this.useChargeType = false;
          break;
        case "LANECLIENT":
          this.pageTitle = "Client Lane Rate Details";
          this.useLocations = false;
          this.useChargeType = false;
          this.useValidFrom = false;
          break;
        case "LANEDRIVER":
          this.pageTitle = "Driver Lane Rate Details";
          this.useLocations = false;
          this.useChargeType = false;
          this.useValidFrom = false;
          break;
        case "FUEL":
          this.pageTitle = "Fuel Surcharge Details";
          this.useLocations = false;
          this.useFuelRegions = true;
          this.useChargeType = true;
          break;
        case "DRIVERFUEL":
          this.pageTitle = "Driver Fuel Surcharge Details";
          this.useLocations = false;
          this.useFuelRegions = false;
          this.useChargeType = false;
          break;
        case "INSURANCE":
          this.pageTitle = "Insurance Surcharge Details";
          this.useChargeType = true;
          break;
        case "LONGEVITY":
          this.pageTitle = "Longevity Details";
          this.useLocations = false;
          this.hideBillTos = true;
          this.useChargeType = true;
          break;
        case "CUSTOM":
          this.pageTitle = "Custom Charge Details";
          this.useLocations = false;
          this.useChargeType = true;
          break;
      }

      this.cdr.markForCheck();
    });
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this.authService.hasPermission(permission);
  }

  loadRateValues() {
    switch (this.rateType) {
      case "CLIENT":
        return this.loadClientRates();
      case "DRIVER":
        return this.loadDriverRates();
      case "LANECLIENT":
        return this.loadLaneClientRates();
      case "LANEDRIVER":
        return this.loadLaneDriverRates();
      case "FUEL":
        return this.loadFuelRates();
      case "DRIVERFUEL":
        return this.loadDriverFuelRates();
      case "INSURANCE":
        return this.loadInsuranceRates();
      case "LONGEVITY":
        return this.loadLongevity();
      case "CUSTOM":
        return this.loadCustomCharge();
    }
  }

  finishLoadingRate() {
    this.rateLoaded = true;
    this.rate.validFromUtc = this.rate.validFromUtc ? new Date(this.rate.validFromUtc) : null;
    this.rate.validToUtc = this.rate.validToUtc ? new Date(this.rate.validToUtc) : null;
  }

  loadClientRates() {
    return this.ratesService
      .getClientRatesById(this.rateId)
      .subscribe((clientRate: ClientRateViewModel) => {
        this.rate = clientRate;
        this.rate.origins = this.statesService.mapLocationsToDropdown(this.rate.origins);
        this.rate.destinations = this.statesService.mapLocationsToDropdown(this.rate.destinations);
        this.finishLoadingRate();
        this.cdr.markForCheck();
      });
  }

  loadDriverRates() {
    this.ratesService
      .getDriverRatesById(this.rateId)
      .subscribe((driverRate: DriverRateViewModel) => {
        this.rate = driverRate;
        this.rate.origins = this.statesService.mapLocationsToDropdown(this.rate.origins);
        this.rate.destinations = this.statesService.mapLocationsToDropdown(this.rate.destinations);
        this.finishLoadingRate();
        this.cdr.markForCheck();
      });
  }

  loadLaneClientRates() {
    return this.ratesService
      .getClientLaneRatesById(this.rateId)
      .subscribe((clientRate: ClientLaneRateViewModel) => {
        this.rate = clientRate;
        this.finishLoadingRate();
        this.cdr.markForCheck();
      });
  }

  loadLaneDriverRates() {
    this.ratesService
      .getDriverLaneRatesById(this.rateId)
      .subscribe((driverRate: DriverLaneRateViewModel) => {
        this.rate = driverRate;
        this.finishLoadingRate();
        this.cdr.markForCheck();
      });
  }

  loadFuelRates() {
    this.ratesService
      .getFuelSurchargesById(this.rateId)
      .subscribe((fuelRate: FuelSurchargeViewModel) => {
        this.rate = fuelRate;
        this.finishLoadingRate();
        this.cdr.markForCheck();
      });
  }

  loadDriverFuelRates() {
    this.ratesService
      .getDriverFuelSurchargesById(this.rateId)
      .subscribe((driverFuelRate: DriverFuelSurchargeViewModel) => {
        this.rate = driverFuelRate;
        this.finishLoadingRate();
        this.cdr.markForCheck();
      });
  }

  loadInsuranceRates() {
    this.ratesService
      .getInsuranceSurchargeById(this.rateId)
      .subscribe((insuranceRate: InsuranceSurchargeViewModel) => {
        this.rate = insuranceRate;
        this.finishLoadingRate();
        this.cdr.markForCheck();
      });
  }

  loadLongevity() {
    this.ratesService.getLongevityById(this.rateId).subscribe((longevity: LongevityViewModel) => {
      this.rate = longevity;
      this.finishLoadingRate();
      this.cdr.markForCheck();
    });
  }

  loadCustomCharge() {
    this.ratesService
      .getCustomChargeById(this.rateId)
      .subscribe((customCharge: CustomChargeViewModel) => {
        this.rate = customCharge;
        this.finishLoadingRate();
        this.cdr.markForCheck();
      });
  }

  confirm() {
    if (this.isEditing) {
      return window.confirm("Leave this page without saving changes?");
    } else {
      return true;
    }
  }

  validate = () => {
    switch (this.rateType) {
      case "CLIENT":
        return this.editClientRates.validate();
      case "DRIVER":
        return this.editClientRates.validate();
      case "LANECLIENT":
        return this.editClientRates.validate();
      case "LANEDRIVER":
        return this.editClientRates.validate();
      case "FUEL":
        return this.editFuelSurcharges.validate();
      case "DRIVERFUEL":
        return this.editDriverFuelSurcharges.validate();
      case "INSURANCE":
        return this.editInsuranceSurcharges.validate();
      case "LONGEVITY":
        return this.editLongevityComponent.validate();
      case "CUSTOM":
        return this.editCustomChargesComponent.validate();
    }
  };

  buildRatesObject = () => {
    switch (this.rateType) {
      case "CLIENT":
        return this.editClientRates.buildRatesObject() as any;
      case "DRIVER":
        return this.editClientRates.buildRatesObject() as any;
      case "LANECLIENT":
        return this.editClientRates.buildRatesObject() as any;
      case "LANEDRIVER":
        return this.editClientRates.buildRatesObject() as any;
      case "FUEL":
        return this.editFuelSurcharges.buildRatesObject() as any;
      case "DRIVERFUEL":
        return this.editDriverFuelSurcharges.buildRatesObject() as any;
      case "INSURANCE":
        return this.editInsuranceSurcharges.buildRatesObject() as any;
      case "LONGEVITY":
        return this.editLongevityComponent.buildRatesObject() as any;
      case "CUSTOM":
        return this.editCustomChargesComponent.buildRatesObject() as any;
    }
  };
}
