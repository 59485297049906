<div class="container">
  <div class="col-md-12">
    <legend>T-Chek Import</legend>
  </div>
  <app-tcheck-import-modal (onFileUpload)="onFileUpload($event)"></app-tcheck-import-modal>
  <div style="margin-top: 40px">
    <div *ngIf="records.length && activeBatch" style="width: fit-content">
      <a *ngIf="activeBatch" class="btn btnUpload" (click)="save()">
        <img src="assets/img/upload.svg" class="imgUpload" width="12" />
        <span>Import</span>
      </a>
    </div>
    <div
      *ngIf="records.length && !activeBatch"
      style="width: fit-content"
      pTooltip="Importing records requires an active batch"
    >
      <a class="btn btnUpload disabled">
        <img src="assets/img/upload.svg" class="imgUpload" width="12" />
        <span>Import</span>
      </a>
    </div>
    <div *ngIf="records.length">
      <a class="btn btnUpload" (click)="clear()">
        <span>Clear Records</span>
      </a>
    </div>
    <div *ngIf="records.length">
      <a class="btn btnUpload" (click)="refresh()">
        <span>Refresh Warnings/Errors</span>
      </a>
    </div>
    <div>
      <div *ngIf="records.length">
        <a class="btn btnUpload" (click)="dowloadCSV()">
          <i style="font-size: 14px" class="fa imgUpload">&#xf1c3;</i>
          <span>Download excel</span>
        </a>
      </div>
    </div>
  </div>

  <div *ngIf="loadingErrors" style="margin: auto">
    <br />
    <p-progressSpinner></p-progressSpinner>
  </div>
  <br />
  <div *ngIf="records.length && !loadingErrors && (successRecords.length || errorRecords.length)">
    <label style="color: red">IMPORT FILE TOTALS</label>
    <p-table [value]="tchekScreenTotals">
      <ng-template pTemplate="header">
        <tr>
          <!--          <th style="width: 550px">ERROR MESSAGE</th>
          <th style="width: 250px">DRIVER NAME / VENDOR</th>
          <th style="width: 150px">TRACTOR UNIT NUMBER</th>
          <th style="width: 150px">TRAILER</th>
          <th style="width: 150px">DATE</th>
          <th style="width: 150px">TOTAL AMOUNT</th>
          <th style="width: 250px">NAME</th>
          <th style="width: 200px">CITY</th>
          <th style="width: 80px">STATE</th>
          <th style="width: 100px">FUEL CARD</th>-->
          <th style="width: 150px">FUEL AMOUNT 1</th>
          <th style="width: 150px">FUEL AMOUNT 2</th>
          <th style="width: 150px">NON HIGHWAY AMOUNT</th>
          <th style="width: 140px">OIL AMOUNT</th>
          <th style="width: 140px">OTHER AMOUNT 1</th>
          <th style="width: 150px">OTHER PRODUCT AMOUNT 2</th>
          <th style="width: 140px">CASH</th>
          <th style="width: 150px">DASHCASH</th>
          <th style="width: 150px">DISCOUNT AMOUNT</th>
          <th style="width: 150px">CUSTOMER FEE</th>
          <th style="width: 150px">EXPRESS</th>
          <th style="width: 140px">TOTAL AMOUNT</th>
          <th style="width: 150px">INVOICE TOTAL</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-total>
        <tr>
          <td style="text-align: right">{{ total.fuelAmount1 | currency }}</td>
          <td style="text-align: right">{{ total.fuelAmount2 | currency }}</td>
          <td style="text-align: right">{{ total.nonHighwayAmount | currency }}</td>
          <td style="text-align: right">{{ total.oilAmount | currency }}</td>
          <td style="text-align: right">{{ total.otherAmount1 | currency }}</td>
          <td style="text-align: right">{{ total.otherProductAmount2 | currency }}</td>
          <td style="text-align: right">{{ total.cash | currency }}</td>
          <td style="text-align: right">{{ total.dashCash | currency }}</td>
          <td style="text-align: right">{{ total.discountAmount | currency }}</td>
          <td style="text-align: right">{{ total.customerFee | currency }}</td>
          <td style="text-align: right">{{ total.express | currency }}</td>
          <td style="text-align: right">{{ total.totalAmount | currency }}</td>
          <td style="text-align: right">{{ total.invoiceTotal | currency }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div *ngIf="!loadingErrors">
    <div *ngIf="errorRecords.length">
      <br />
      <label style="color: red">Errors on Records</label>
      <app-table-report
        #tableReportError
        [record]="errorRecords"
        (onEdit)="calculateTotals()"
        (onSave)="reloadRecords()"
      ></app-table-report>
    </div>

    <div *ngIf="warningRecords.length">
      <br />
      <label style="color: darkgoldenrod">Warnings on Records</label>
      <app-table-report
        #tableReportWarning
        [record]="warningRecords"
        (onEdit)="calculateTotals()"
        (onSave)="reloadRecords()"
      ></app-table-report>
    </div>

    <br />
    <label *ngIf="successRecords.length && (errorRecords.length || warningRecords.length)"
      >Successful Records:</label
    >
    <app-table-report
      *ngIf="successRecords.length"
      #tableReportSuccess
      [record]="successRecords"
      (onEdit)="calculateTotals()"
      (onSave)="reloadRecords()"
    ></app-table-report>
  </div>
</div>

<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '50vw' }"
></p-confirmDialog>
