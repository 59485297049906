<form (ngSubmit)="onSubmit()">
  <div class="form-group">
    <label for="categoryCode">Category Code</label>
    <input
      type="text"
      class="form-control"
      name="categoryCode"
      [ngModel]="mapping.categoryCode"
      (ngModelChange)="mapping.categoryCode = $event.toUpperCase()"
      autofocus
    />
  </div>
  <div class="form-group">
    <label for="description">Description</label>
    <input
      type="text"
      class="form-control"
      name="description"
      [(ngModel)]="mapping.description"
      (ngModelChange)="mapping.description = $event.toUpperCase()"
    />
  </div>
  <div class="form-group">
    <label for="chargeType">Charge Type</label>
    <div>
      <p-dropdown
        name="chargeType"
        [options]="chargeTypes"
        [(ngModel)]="mapping.chargeTypeId"
        placeholder="Select"
        aria-label="chargeType"
        [style]="{ width: '100%' }"
        appendTo="body"
      ></p-dropdown>
    </div>
  </div>
  <div class="form-group">
    <label for="settlementColumn">Settlement Column</label>
    <div>
      <p-dropdown
        name="settlementColumn"
        [options]="settlementColumns"
        [(ngModel)]="mapping.settlementColumn"
        placeholder="Select"
        aria-label="settlementColumn"
        [style]="{ width: '100%' }"
        appendTo="body"
      ></p-dropdown>
    </div>
  </div>
  <div class="form-group">
    <label for="defaultChargeTo">Default Charge To</label>
    <div>
      <p-dropdown
        name="defaultChargeTo"
        [options]="chargeToOptions"
        [(ngModel)]="mapping.defaultChargeTo"
        placeholder="Select"
        aria-label="defaultChargeTo"
        [style]="{ width: '100%' }"
        appendTo="body"
      ></p-dropdown>
    </div>
  </div>
  <p-footer>
    <div class="footer">
      <button type="button" class="btn btn-secondary mr-2" (click)="closeModal()">Cancel</button>
      <button *ngIf="!isEditing" type="submit" class="btn btn-primary createBtn mr-2">
        Create
      </button>
      <button
        *ngIf="isEditing && !mapping.dateDeletedUtc"
        type="button"
        class="btn btn-danger mr-2"
        (click)="confirmationDelete()"
      >
        Delete
      </button>
      <button
        *ngIf="mapping.dateDeletedUtc"
        type="button"
        class="btn btn-primary createBtn mr-2"
        (click)="restore()"
      >
        Restore
      </button>
      <button
        *ngIf="isEditing && !mapping.dateDeletedUtc"
        type="submit"
        class="btn btn-primary createBtn"
      >
        Save
      </button>
    </div>
  </p-footer>
</form>

<p-confirmDialog
  key="deleteDialog"
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>
