import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { GetPictureService } from "../../../app/shared/services/files/getPictures.service";
import { MaskService } from "../../../app/shared/services/mask/mask.service";
import { DriverService } from "../shared/driver.service";
import { LessorService } from "../../lessor/shared/lessor.service";
import { ToastrService } from "ngx-toastr";
import { StatesServices } from "../../shared/services/states/states.service";
import { FileUploadService } from "../../../app/shared/services/files/fileUpload.service";
import { DriverHireDateService } from "../shared/driverHireDate.service";
import { CanComponentDeactivate } from "../../shared/guards/confirmation/confirmation.guard";
import { authenticationService } from "@app/login/auth.service";
import { Permissions } from "@models/permissions.enum";
import { DriverViewModel } from "@app/shared/models/drivers/DriverViewModel.model";
import { DriverValidationService } from "../shared/driverValidation.service";
import { of } from "rxjs";

@Component({
  selector: "app-driverinfo",
  templateUrl: "./driverinfo.component.html",
  styleUrls: ["./driverinfo.component.css"],
})
export class DriverinfoComponent implements OnInit, CanComponentDeactivate {
  @ViewChild("driverDocuments", { static: true }) driverDocuments;

  id: string;
  driverInfo: DriverViewModel = new DriverViewModel();
  is_edit: boolean;
  btnProfile: string;
  dateMask: (string | RegExp)[];
  socialSecurityMask = [];
  usPhoneMask: any;
  drivers: DriverViewModel[] = [];
  allDrivers: DriverViewModel[] = [];
  docType: string;
  newEvent: any;
  isProcessing = false;
  driverSelected = null;
  states = [];
  originalInfo: any;
  newProfileFile: any;
  profilePicture: any;
  lessorOwners: any = [];
  dateInputMask: any;
  constructor(
    private _driverinfo: ActivatedRoute,
    private getPictureService: GetPictureService,
    private maskService: MaskService,
    private driverService: DriverService,
    private lessorService: LessorService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private statesServices: StatesServices,
    private fileUploadService: FileUploadService,
    private driverHireDateService: DriverHireDateService,
    private _AuthService: authenticationService,
    private driverValidationService: DriverValidationService
  ) {
    this.is_edit = true;
    this.btnProfile = "Edit Profile";
    this.docType = "driver";
  }

  ngOnInit() {
    this.lessorService.getActiveLessorList(true).subscribe((response) => {
      this.lessorOwners = response || [];
    });
    this.profilePicture = "assets/img/tractordriver.jpg";
    this.states = this.statesServices.getTwoLettersStates("US");
    this.allDrivers = this.route.snapshot.data["drivers"];
    this.drivers = this.route.snapshot.data["drivers"];
    this.newEvent = {};
    this.dateMask = this.maskService.getDate();
    this.usPhoneMask = this.maskService.getUsPhoneMask();
    this.socialSecurityMask = this.maskService.getSocialSecurityMask();
    this._driverinfo.params.subscribe((params) => {
      this.id = params["id"];
    });
    this.driverService.getDriverInfoById(this.id).subscribe((result) => {
      this.driverInfo = result;
      this.filterTeamDrivers();
      if (this.driverInfo.picture) {
        this.profilePicture = this.getFilePath(this.driverInfo.picture);
      }
      this.initDateFields(this.driverInfo);
      for (let i = 0; i < this.drivers.length; i++) {
        if (this.drivers[i].id === this.driverInfo.id) {
          this.drivers.splice(i, 1);
        }
      }
      this.driverDocuments.getDocuments(
        ("web" + this.docType + "_files").toUpperCase(),
        this.driverInfo.id
      );
    });
    this.dateInputMask = this.maskService.getInputDateOnlyMask();
  }

  filterTeamDrivers() {
    this.drivers = this.allDrivers.filter(
      (driver) => driver.type === this.driverInfo.type && driver.isActive
    );
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this._AuthService.hasPermission(permission);
  }

  confirm() {
    if (!this.is_edit) {
      return window.confirm("Are you sure you want to leave this page?");
    } else {
      return true;
    }
  }

  addDriverHireDate() {
    if (this.driverInfo.hiredDate == null) {
      this.toastr.warning("Hire date must have a from date.", "Alert!");
      return;
    }
    if (this.driverInfo.hiredDateTo == null) {
      this.driverInfo.hiredDateTo = new Date();
    }
    this.driverInfo.driverHireDates.push({
      hiredDate: this.driverInfo.hiredDate,
      hiredDateTo: this.driverInfo.hiredDateTo,
    });
    this.driverInfo.driverHireDates.sort((a, b) => {
      var aDate = new Date(a.hiredDate).getTime();
      var aDateTo = new Date(a.hiredDateTo).getTime();
      var bDate = new Date(b.hiredDate).getTime();
      var bDateTo = new Date(b.hiredDateTo).getTime();
      return aDate == bDate ? bDateTo - aDateTo : bDate - aDate;
    });
    this.driverInfo.hiredDate = null;
    this.driverInfo.hiredDateTo = null;
  }

  driverHireDateCounter() {
    if (this.driverInfo.driverHireDates)
      return new Array(this.driverInfo.driverHireDates.length + 1);
    return new Array(1);
  }

  addHireDates(driverId: string) {
    return this.driverHireDateService.createHireDates(driverId, this.driverInfo.driverHireDates);
  }

  removeDriverHireDate(index: number) {
    this.driverInfo.driverHireDates.splice(index - 1, 1);
  }

  teamSelected(driver) {
    if (!this.driverSelected) {
      driver.teamId = null;
      driver.driverTeamId = null;
      return;
    }
    if (this.driverSelected.team) {
      driver.teamId = this.driverSelected.team.id;
      driver.driverTeamId = null;
    } else {
      driver.driverTeamId = this.driverSelected.id;
      this.driverSelected.earningPercentage = 0;
      driver.teamId = null;
    }
    if (this.driverInfo.team == null) this.driverInfo.team = {};
    this.driverSelected.isPayTo = false;
    this.driverInfo.isPayTo = true;
    if (this.driverSelected.team) {
      // If the selected driver is already part of a team
      // Add us to the team (if we're not already on it) and make us the payto
      let teamDrivers = this.driverSelected.team.drivers
        .filter((driver) => driver.id !== this.driverInfo.id)
        .map((driver) => ({
          ...driver,
          isPayTo: false,
          earningPercentage: driver.earningPercentage ?? 0,
        }));
      this.driverInfo.team.drivers = [...teamDrivers, JSON.parse(JSON.stringify(this.driverInfo))];
    } else {
      this.driverInfo.team.drivers = [
        this.driverSelected,
        JSON.parse(JSON.stringify(this.driverInfo)),
      ];
    }
  }

  removeFromTeam() {
    let driver = this.driverInfo.team.drivers.find((driver) => driver.id === this.driverInfo.id);
    const oldTeamEarningPercentageTotal = this.driverInfo.team.drivers
      .filter((driver) => driver.id !== this.driverInfo.id)
      .reduce((total, driver) => total + driver.earningPercentage, 0);
    if (driver.isPayTo) {
      return this.toastr.warning(
        "Please change the Pay To driver before leaving the team",
        "Alert!"
      );
    } else if (oldTeamEarningPercentageTotal !== 100) {
      return this.toastr.warning(
        "Please change team earning to be 100% without you before you leave",
        "Alert!"
      );
    }
    this.driverInfo.previousTeam = {
      TeamId: this.driverInfo.teamId,
      Team: JSON.parse(JSON.stringify(this.driverInfo.team)),
    };
    delete driver.teamId;
    delete this.driverInfo.teamId;
    delete this.driverInfo.team;
    this.driverSelected = null;
    this.driverInfo.isPayTo = true;
  }

  calculateTotalTeamPercent() {
    return this.driverInfo.team.drivers.reduce(
      (acc, curr) => acc + (curr.earningPercentage || 0),
      0
    );
  }

  editOn(driver) {
    if (this.btnProfile === "Edit Profile") {
      this.btnProfile = "Save Changes";
      this.is_edit = false;
      this.originalInfo = JSON.stringify(this.driverInfo);
    } else {
      if (!this.validateDriver()) return;
      this.isProcessing = true;
      if (this.newProfileFile) {
        this.uploadNewImage().subscribe((url: any) => {
          driver.picture = url.name;
          this.uploadDocuments(driver.id).subscribe({
            next: () => this.updateDriver(driver),
            error: () => {
              this.toastr.error("Error uploading files");
              this.isProcessing = false;
            },
          });
        });
      } else {
        this.uploadDocuments(driver.id).subscribe({
          next: () => this.updateDriver(driver),
          error: () => {
            this.toastr.error("Error uploading files");
            this.isProcessing = false;
          },
        });
      }
    }
  }

  // was used to update the page after a driver was updated. now updating redirects you
  refreshInfo() {
    this.driverService.getDriverInfoById(this.id).subscribe((result) => {
      this.driverInfo = result;
      this.initDateFields(this.driverInfo);

      for (let i = 0; i < this.drivers.length; i++) {
        if (this.drivers[i].id === this.driverInfo.id) {
          this.drivers.splice(i, 1);
        }
      }
      this.toastr.success("Driver updated", "Success!");
      this.isProcessing = false;
      this.is_edit = true;
      this.btnProfile = "Edit Profile";
    });
  }

  convertToDate(date: any): Date | null {
    return date ? new Date(date) : null;
  }

  initDateFields(driverInfo) {
    driverInfo.efsEffectiveDate = this.convertToDate(driverInfo.efsEffectiveDate);
    driverInfo.birthDate = this.convertToDate(driverInfo.birthDate);
    driverInfo.annualReviewDate = this.convertToDate(driverInfo.annualReviewDate);
    driverInfo.expirationDateCGMP = this.convertToDate(driverInfo.expirationDateCGMP);
    driverInfo.hazmatTrainingDate = this.convertToDate(driverInfo.hazmatTrainingDate);
    driverInfo.hazmatTrainingExpirationDate = this.convertToDate(
      driverInfo.hazmatTrainingExpirationDate
    );
    driverInfo.hiredDate = this.convertToDate(driverInfo.hiredDate);
    driverInfo.hmeExpirationDate = this.convertToDate(driverInfo.hmeExpirationDate);
    driverInfo.licenseExpirationDate = this.convertToDate(driverInfo.licenseExpirationDate);
    driverInfo.passportExpirationDate = this.convertToDate(driverInfo.passportExpirationDate);
    driverInfo.physicalExamDate = this.convertToDate(driverInfo.physicalExamDate);
    driverInfo.physicalExamExpirationDate = this.convertToDate(
      driverInfo.physicalExamExpirationDate
    );
    driverInfo.trainingDateCGMP = this.convertToDate(driverInfo.trainingDateCGMP);

    if (driverInfo.driverHireDates) {
      driverInfo.driverHireDates = driverInfo.driverHireDates.map((hireDate) => {
        hireDate.hiredDate = this.convertToDate(hireDate.hiredDate);
        hireDate.hiredDateTo = this.convertToDate(hireDate.hiredDateTo);
        return hireDate;
      });
    }
  }

  getPicturePath(name: string) {
    return this.getPictureService.getPicturePath(name, this.docType);
  }

  loadOwnerValue() {
    const { name, lastName } = this.driverInfo;
    if (lastName && name) {
      return (lastName.slice(0, 4) + name.slice(0, 4)).toUpperCase();
    }
    return "";
  }

  calculatehazmatTrainingExpirationDate(hazmatTrainingDate) {
    if (hazmatTrainingDate) {
      const year = hazmatTrainingDate.getFullYear();
      const month = hazmatTrainingDate.getMonth();
      const day = hazmatTrainingDate.getDate();

      this.driverInfo.hazmatTrainingExpirationDate = new Date(year + 3, month, day);
    } else {
      delete this.driverInfo.hazmatTrainingExpirationDate;
    }
  }

  calculateTrainingDateCGMPExpirationDate(CGMPExpirationDate) {
    if (CGMPExpirationDate) {
      const year = CGMPExpirationDate.getFullYear();
      const month = CGMPExpirationDate.getMonth();
      const day = CGMPExpirationDate.getDate();

      this.driverInfo.expirationDateCGMP = new Date(year + 1, month, day);
    } else {
      delete this.driverInfo.expirationDateCGMP;
    }
  }

  cancelEdit() {
    this.is_edit = true;
    this.btnProfile = "Edit Profile";
    this.driverInfo = JSON.parse(this.originalInfo);
    this.initDateFields(this.driverInfo);
  }

  getFilePath(path) {
    return this.getPictureService.getPicturePath(path, this.docType);
  }

  getTemporalImage(event) {
    if (event.target.files && event.target.files[0]) {
      this.newProfileFile = event.target.files[0];
      this.profilePicture = this.getPictureService.getPreviewImage(event.target.files[0]);
    }
  }

  uploadNewImage() {
    return this.fileUploadService.uploadSingleFileRename(
      [this.newProfileFile],
      this.docType,
      this.driverInfo.driverNo + "_" + this.newProfileFile.name
    );
  }

  uploadDocuments(driverId: string) {
    return this.driverDocuments.uploadDocuments(
      { DriverId: driverId },
      this.docType + "_files",
      driverId
    );
  }

  updateDriver(driver) {
    let errors = this.driverValidationService.validate(driver);
    if (errors && errors.length > 0) {
      this.isProcessing = false;
      for (const error of errors) {
        this.toastr.warning(error, "Alert!");
      }
      return;
    }

    this.addHireDates(driver.id).subscribe({
      next: () => {
        this.driverService.updateDriver(driver).subscribe({
          next: () => {
            this.originalInfo = JSON.stringify(driver);
            this.isProcessing = false;
            this.is_edit = true;
            this.router.navigate(["/drivers-list"]);
          },
          error: (errors) => {
            for (const error of errors) {
              this.toastr.warning(error, "Alert!", {
                closeButton: true,
                enableHtml: true,
              });
              console.dir(error);
            }
            this.isProcessing = false;
          },
        });
      },
      error: (error) => {
        this.toastr.warning(error, "Alert!");
        console.dir(error);
        this.isProcessing = false;
      },
    });
  }

  isPayToChanged(driver) {
    if (this.driverInfo.team && this.driverInfo.team.drivers) {
      for (let i = 0; i < this.driverInfo.team.drivers.length; i++) {
        let driver = this.driverInfo.team.drivers[i];
        let warned = false;
        this.driverService
          .getCurrentBatchStatus(driver.id)
          .subscribe((batchStatusResponse: any) => {
            if (batchStatusResponse.status !== "CLOSED") {
              this.toastr.warning(
                "This team has a batch ongoing, saving your changes will remove this driver from the batch."
              );
              warned = true;
            }
          });
        if (warned) break;
      }
    }

    this.driverInfo.team.drivers.forEach((d) => {
      if (d.id != driver.id) {
        d.isPayTo = !driver.isPayTo;
      }
    });
    if (driver.id != this.driverInfo.id) this.driverInfo.isPayTo = !driver.isPayTo;
  }

  earningPercentageChanged(driver: DriverViewModel) {
    if (driver.id === this.driverInfo.id) {
      this.driverInfo.earningPercentage = driver.earningPercentage;
    }
  }

  driverTypeChanged() {
    if (!this.driverInfo.team) this.driverInfo.isPayTo = true;
    this.filterTeamDrivers();
  }

  validateDriver() {
    if (this.driverInfo.team?.drivers) {
      const totalPercent = this.calculateTotalTeamPercent();
      if (totalPercent !== 100) {
        this.toastr.warning("Total percent must equal 100%", "Alert!");
        return false;
      }
    }
    return true;
  }

  nextDriver() {
    const currentIndex = this.allDrivers.findIndex((d) => d.id === this.driverInfo.id);
    let nextIndex = currentIndex + 1;
    if (nextIndex >= this.allDrivers.length) {
      nextIndex = 0;
    }
    const id = this.allDrivers[nextIndex].id;
    this.router.navigate([`/driverinfo/${id}`]).then(() => {
      this.ngOnInit();
    });
  }

  previousDriver() {
    const currentIndex = this.allDrivers.findIndex((d) => d.id === this.driverInfo.id);
    let prevIndex = currentIndex - 1;
    if (prevIndex < 0) {
      prevIndex = this.allDrivers.length - 1;
    }
    const id = this.allDrivers[prevIndex].id;
    this.router.navigate([`/driverinfo/${id}`]).then(() => {
      this.ngOnInit();
    });
  }
}
