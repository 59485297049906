<div class="row form-group">
  <div class="row">
    <div class="col-md-6">
      <label class="col-md-4">Final Surcharge Decimals</label>
      <div class="col-md-8 form-group">
        <div class="p-field-radiobutton" *ngIf="isEditing">
          <p-radioButton
            inputId="decimals2"
            name="decimals"
            value="2"
            [(ngModel)]="decimals"
          ></p-radioButton>
          <label for="decimals2" class="mr-2">2</label>
          <p-radioButton
            inputId="decimals3"
            name="decimals"
            value="3"
            [(ngModel)]="decimals"
          ></p-radioButton>
          <label for="decimals3">3</label>
        </div>
        <div *ngIf="!isEditing">{{ decimals }}</div>
      </div>
    </div>
    <div class="col-md-6 row">
      <label class="col-md-4">Rated By</label>
      <div class="col-md-8 form-group">
        <div *ngIf="isEditing">
          <div class="p-field-radiobutton">
            <p-radioButton
              name="rateBy"
              value="WEIGHT"
              [(ngModel)]="ratingType"
              inputId="rateByWeight"
            ></p-radioButton>
            <label for="rateByWeight">Weight</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              name="rateBy"
              value="DISTANCE"
              [(ngModel)]="ratingType"
              inputId="rateByDistance"
            ></p-radioButton>
            <label for="rateByDistance">Distance</label>
          </div>
        </div>
        <div *ngIf="!isEditing">{{ ratingType }}</div>
      </div>
    </div>
  </div>
</div>

<hr />

<div class="row form-group" style="margin-left: 0px; margin-right: 0px">
  <div class="col-md-12 rate-by-box" *ngIf="isEditing">
    <div class="col-md-4 form-group">
      <div class="p-field-radiobutton">
        <p-radioButton
          name="rateBy"
          value="PRICE"
          [(ngModel)]="rateBy"
          (click)="rateByChanged('PRICE')"
          inputId="priceSurcharge"
        ></p-radioButton>
        <label for="priceSurcharge">Price Surcharge</label>
      </div>
      <div class="p-field-radiobutton">
        <p-radioButton
          name="rateBy"
          value="PERCENTAGE"
          [(ngModel)]="rateBy"
          (click)="rateByChanged('PERCENTAGE')"
          inputId="percentageSurcharge"
        ></p-radioButton>
        <label for="percentageSurcharge">Percentage Surcharge</label>
      </div>
      <div class="p-field-radiobutton">
        <p-radioButton
          name="rateBy"
          value="DATERANGE"
          [(ngModel)]="rateBy"
          (click)="rateByChanged('DATERANGE')"
          inputId="dateRangeSurcharge"
        ></p-radioButton>
        <label for="dateRangeSurcharge">Percentage By Date Range Surcharge</label>
      </div>
    </div>
    <hr />
  </div>
</div>

<!-- Tables -->
<div>
  <!-- Client Fuel Surcharges -->
  <div class="row form-group">
    <div class="col-md-12">
      <label>Client Fuel Surcharge</label>
      <p-toolbar styleClass="toolbar-header">
        <div class="ui-toolbar-group-left">
          <button
            pButton
            type="button"
            icon="fa fa-file-excel-o"
            class="p-button-secondary mr-2"
            title="Export to Excel"
            label="Export"
            (click)="exportExcel(clientRates, columns, 'client_fuel_surcharges')"
          ></button>
          <span *ngIf="isEditing">
            <p-fileUpload
              #fileUpload
              pRipple
              class="p-button-secondary p-button mr-2 no-padding upload-button"
              mode="basic"
              [auto]="true"
              customUpload="true"
              (uploadHandler)="
                importExcel($event, loadBoundedExcelData, columns, clientRatesTable, fileUpload)
              "
              chooseLabel="Import"
              name="myfile[]"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              maxFileSize="1000000"
            >
            </p-fileUpload>
            <app-adjust-by-percent-button [(rows)]="clientRates"></app-adjust-by-percent-button>
          </span>
        </div>
      </p-toolbar>
      <app-bounded-table
        #clientRatesTable
        [(rows)]="clientRates"
        [(firstColumn)]="firstColumnString"
        [(firstColumnType)]="columns[0].type"
        floor="floor"
        roof="roof"
        [columns]="columns"
        [isEditing]="isEditing"
        [decimals]="3"
        [increment]="0.001"
        [defaultRange]="1"
      >
      </app-bounded-table>
      <br />
      <div
        class="row col-md-6"
        *ngIf="hasPermission('BusinessRulesChange') && (isEditing || isCreating)"
      >
        <label
          >Client Price Increment
          <i
            class="fa fa-question-circle"
            pTooltip="For every change in fuel price by this amount...
          "
          ></i>
        </label>
        <input
          type="number"
          [(ngModel)]="clientPriceIncrement"
          min="0"
          step="any"
          class="form-control p-inputtext"
        />
        <label
          >Client Price Interval<i
            class="fa fa-question-circle"
            pTooltip="...the Fuel Surcharge will increase by this amount
          "
          ></i
        ></label>
        <input
          type="number"
          [(ngModel)]="clientPriceInterval"
          min="0"
          step="any"
          class="form-control p-inputtext"
        />
      </div>
      <div *ngIf="!(isEditing || isCreating)">
        <label
          >Client Price Increment
          <i
            class="fa fa-question-circle"
            pTooltip="For every change in fuel price by this amount...
        "
          ></i
        ></label>
        ${{ clientPriceIncrement }} <br />
        <div *ngIf="rateBy === 'PRICE'">
          <label>Client Price Interval</label>
            <i
              class="fa fa-question-circle"
              pTooltip="...the Fuel Surcharge will increase by this amount"
            ></i>
          ${{ clientPriceInterval | number : "1.0-3" }}
        </div>
        <div *ngIf="rateBy === 'PERCENTAGE'">
          <label>
            Client Price Interval
            <i
              class="fa fa-question-circle"
              pTooltip="...the Fuel Surcharge will increase by this amount"
            ></i>
          </label>
          {{ +clientPriceInterval * 100 | number }}%
        </div>
      </div>
    </div>
  </div>
  <hr />
  <!-- Driver Fuel Surcharges -->
  <div class="row form-group">
    <div class="col-md-12">
      <div class="row" *ngIf="hasPermission('BusinessRulesChange') && (isEditing || isCreating)">
        <div class="col-md-4 form-group">
          <div class="p-field-radiobutton">
            <p-radioButton
              name="driverFuelSurchargeRadio"
              value="PASSTHROUGH"
              [(ngModel)]="driverFuelSurchargeRadio"
              (click)="driverFuelSurchargeChanged('PASSTHROUGH')"
              inputId="passthrough"
            ></p-radioButton>
            <label for="passthrough">Straight Passthrough</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              name="driverFuelSurchargeRadio"
              value="LINK"
              [(ngModel)]="driverFuelSurchargeRadio"
              (click)="driverFuelSurchargeChanged('LINK')"
              inputId="link"
            ></p-radioButton>
            <label for="link">Link Driver Fuel Surcharge</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              name="driverFuelSurchargeRadio"
              value="CREATE"
              [(ngModel)]="driverFuelSurchargeRadio"
              (click)="driverFuelSurchargeChanged('CREATE')"
              inputId="create"
            ></p-radioButton>
            <label for="create">Specify Custom Driver Fuel Surcharge</label>
          </div>
        </div>
      </div>
      <div *ngIf="!(isEditing || isCreating)">
        <div class="col-md-4 form-group">
          <div class="p-field-radiobutton">
            <p-radioButton
              name="driverFuelSurchargeRadio"
              value="PASSTHROUGH"
              [(ngModel)]="driverFuelSurchargeRadio"
              disabled="true"
            ></p-radioButton>
            <label for="priceSurcharge">Straight Passthrough</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              name="driverFuelSurchargeRadio"
              value="LINK"
              [(ngModel)]="driverFuelSurchargeRadio"
              disabled="true"
            ></p-radioButton>
            <label for="percentageSurcharge">Link Driver Fuel Surcharge</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              name="driverFuelSurchargeRadio"
              value="CREATE"
              [(ngModel)]="driverFuelSurchargeRadio"
              disabled="true"
            ></p-radioButton>
            <label for="dateRangeSurcharge">Specify Custom Driver Fuel Surcharge</label>
          </div>
        </div>
      </div>
      <br />

      <span [hidden]="rate.isStraightPassThrough">
        <label>Driver Fuel Surcharge</label>
        <span [hidden]="rate.linkDriverFuelSurcharge">
          <p-toolbar styleClass="toolbar-header">
            <div class="ui-toolbar-group-left">
              <button
                pButton
                type="button"
                icon="fa fa-file-excel-o"
                class="p-button-secondary mr-2"
                title="Export to Excel"
                label="Export"
                (click)="exportExcel(driverRates, columns, 'driver_fuel_surcharges')"
              ></button>
              <span *ngIf="isEditing">
                <p-fileUpload
                  #fileUpload
                  pRipple
                  class="p-button-secondary p-button mr-2 no-padding upload-button"
                  mode="basic"
                  [auto]="true"
                  customUpload="true"
                  (uploadHandler)="
                    importExcel($event, loadBoundedExcelData, columns, driverRatesTable, fileUpload)
                  "
                  chooseLabel="Import"
                  name="myfile[]"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  maxFileSize="1000000"
                >
                </p-fileUpload>
                <app-adjust-by-percent-button [(rows)]="driverRates"></app-adjust-by-percent-button>
              </span>
            </div>
          </p-toolbar>
          <app-bounded-table
            #driverRatesTable
            [(rows)]="driverRates"
            [(firstColumn)]="firstColumnString"
            [(firstColumnType)]="columns[0].type"
            floor="floor"
            roof="roof"
            [columns]="columns"
            [isEditing]="isEditing"
            [decimals]="3"
            [increment]="0.001"
            [defaultRange]="1"
          >
          </app-bounded-table>
          <br />
          <div
            class="row col-md-6"
            *ngIf="hasPermission('BusinessRulesChange') && (isEditing || isCreating)"
          >
            <label
              >Driver Price Increment
              <i
                class="fa fa-question-circle"
                pTooltip="For every change in fuel price by this amount...
            "
              ></i
            ></label>
            <input
              type="number"
              [(ngModel)]="driverPriceIncrement"
              min="0"
              step="any"
              class="form-control p-inputtext"
            />
            <label
              >Driver Price Interval
              <i
                class="fa fa-question-circle"
                pTooltip="...the Fuel Surcharge will increase by this amount
            "
              ></i
            ></label>
            <input
              type="number"
              [(ngModel)]="driverPriceInterval"
              min="0"
              step="any"
              class="form-control p-inputtext"
            />
          </div>
          <div *ngIf="!(isEditing || isCreating)">
            <label
              >Driver Price Increment
              <i
                class="fa fa-question-circle"
                pTooltip="For every change in fuel price by this amount...
            "
              ></i
            ></label>
            ${{ driverPriceIncrement }}
            <div *ngIf="rateBy === 'PRICE'">
              <label>Driver Price Interval</label>
              {{ driverPriceInterval | currency }}
            </div>
            <div *ngIf="rateBy === 'PERCENTAGE'">
              <label
                >Driver Price Interval
                <i
                  class="fa fa-question-circle"
                  pTooltip="...the Fuel Surcharge will increase by this amount
              "
                ></i
              ></label>
              {{ +driverPriceInterval * 100 | number }}%
            </div>
          </div>
        </span>
        <span [hidden]="!rate.linkDriverFuelSurcharge">
            <p-dropdown
              [options]="driverFuelSurcharges"
              [(ngModel)]="selectedDriverFuelSurcharge"
              optionLabel="name"
              [style]="{ width: '100%' }"
              [disabled]="!(isEditing || isCreating)"
              (onChange)="loadLinkedDriverFuelSurcharge(selectedDriverFuelSurcharge.id)"
            ></p-dropdown>
            <app-bounded-table
              #linkedDriverRatesTable
              [(rows)]="linkedDriverRates"
              [(firstColumn)]="firstColumnString"
              [(firstColumnType)]="columns[0].type"
              floor="floor"
              roof="roof"
              [columns]="columns"
              [isEditing]="false"
              [decimals]="3"
              [increment]="0.001"
              [defaultRange]="1"
            >
            </app-bounded-table>
            <br/>
            <label
              >Driver Price Increment
              <i
                class="fa fa-question-circle"
                pTooltip="For every change in fuel price by this amount...
            "
              ></i
            ></label>
            ${{ linkedDriverPriceIncrement }} <br />
            <div *ngIf="rateBy === 'PRICE'">
              <label>Driver Price Interval</label>
                <i
                  class="fa fa-question-circle"
                  pTooltip="...the Fuel Surcharge will increase by this amount"
                ></i>
              ${{ linkedDriverPriceInterval | number : "1.0-3" }}
            </div>
            <div *ngIf="rateBy === 'PERCENTAGE'">
              <label>
                Driver Price Interval
                <i
                  class="fa fa-question-circle"
                  pTooltip="...the Fuel Surcharge will increase by this amount"
                ></i>
              </label>
              {{ +linkedDriverPriceInterval * 100 | number }}%
            </div>
        </span>
      </span>
    </div>
  </div>
</div>
