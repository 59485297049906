<div class="container">
  <div class="row firstRowContent">
    <legend>All Loads for Batch {{ batchInfo?.number }}</legend>
    <div class="col-md-6 divTableZero">
      <div *ngIf="trips.length > 0">
        Total: {{ totalEarnings | currency }}
        <button
          class="btn"
          style="background: lightgrey; margin-bottom: 6px"
          (click)="selectAllEarnings()"
        >
          Select All Earnings
        </button>
      </div>
      <p-table
        #dtBilled
        [columns]="cols"
        [value]="trips"
        dataKey="tripNo"
        sortMode="multiple"
        scrollHeight="flex"
        stateStorage="session"
        stateKey="newbilled-trips-table"
        [expandedRowKeys]="trips"
      >
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="columns.length">No records found</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col style="width: 170px" />
            <col *ngFor="let col of columns" [style.width.px]="col.width || 180" />
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [title]="col.header">
              {{ col.header }}
              <p-sortIcon *ngIf="col.header.length" [field]="col.field"></p-sortIcon>
            </th>
            <th>Include</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-trip let-expanded="expanded">
          <tr class="clickable-row" style="height: 22px">
            <td
              *ngFor="let col of cols"
              [ngStyle]="{
                'text-align': col.type === 'number' || col.type === 'currency' ? 'right' : 'left'
              }"
            >
              <span *ngIf="!col.type">{{ trip[col.field] }}</span>
              <span *ngIf="col.type === 'boolean'">{{ trip[col.field] | yesNo }}</span>
              <span *ngIf="col.type === 'date'">
                {{ trip[col.field] | date : "MM/dd/yy HH:mm"}}
              </span>
              <span *ngIf="col.type === 'number'">
                {{ trip[col.field] | number : "1.0" : "en-US" }}
              </span>
              <span *ngIf="col.type === 'currency'">{{ trip[col.field] | currency }}</span>
            </td>
            <td>
              <p-checkbox
                  [(ngModel)]="trip.include"
                  binary="true"
                  name="switchActive"
                  (onChange)="calculateTotal()"
                  [disabled]="!batchInfo.isOpen"
                >
                </p-checkbox>
            </td>
            <td>
              <p-button style="color: black" pRipple [pRowToggler]="trip" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" />
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-trip>
          <tr>
              <td colspan="7">
                    <p-table #dt1 [columns]="colsCharge" [value]="trip.driverCharges" [autoLayout]="true">
                      <ng-template pTemplate="colgroup" let-columns>
                        <colgroup>
                          <col *ngFor="let col of columns" [style.width.px]="col.width || 180" />
                          <col style="width: 50px" />
                        </colgroup>
                      </ng-template>
                      <ng-template pTemplate="header" let-columns>
                        <tr>
                          <th *ngFor="let col of columns">
                            {{ col.header }}
                          </th>
                          <th></th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-charge let-y="rowIndex">
                        <tr *ngIf="charge.delete == false">
                          <!-- Charge Type -->
                          <td
                            id="celda0{{ y }}scrollable"
                            [pEditableColumn]="charge"
                            [pEditableColumnField]="charge.chargeType"
                          >
                            <div>
                              <p-cellEditor class="elements-table">
                                <ng-template pTemplate="input">
                                  <p-dropdown
                                    [options]="driverChargeTypes"
                                    [(ngModel)]="charge.chargeType"
                                    (onChange)="editCharge(charge)"
                                    placeholder="Select a Charge Type"
                                    filter="true"
                                    [disabled]="!batchInfo.isOpen"
                                    appendTo="body"
                                    tabbableDropdown
                                  ></p-dropdown>
                                </ng-template>
                                <ng-template pTemplate="output" style="text-align: center">
                                  {{ charge.chargeType?.description }}
                                </ng-template>
                              </p-cellEditor>
                            </div>
                          </td>
                          <!-- Description -->
                          <td
                            id="celda1{{ y }}scrollable"
                            [pEditableColumn]="charge"
                            [pEditableColumnField]="charge.description"
                          >
                            <div>
                              <p-cellEditor class="elements-table">
                                <ng-template pTemplate="input">
                                  <input
                                    pInputText
                                    type="text"
                                    [(ngModel)]="charge.description"
                                    (change)="editCharge(charge)"
                                    [disabled]="!batchInfo.isOpen"
                                  />
                                </ng-template>
                                <ng-template pTemplate="output" style="text-align: center">
                                  {{ charge.description }}
                                </ng-template>
                              </p-cellEditor>
                            </div>
                          </td>
                          <!-- Units -->
                          <td
                            id="celda2{{ y }}scrollable"
                            [pEditableColumn]="charge"
                            [pEditableColumnField]="charge.units"
                            style="text-align: right"
                          >
                            <div>
                              <p-cellEditor class="elements-table">
                                <ng-template pTemplate="input">
                                  <input
                                    pInputText
                                    type="number"
                                    [(ngModel)]="charge.units"
                                    (change)="editCharge(charge)"
                                    [disabled]="!batchInfo.isOpen"
                                  />
                                </ng-template>
                                <ng-template pTemplate="output" style="text-align: center">
                                  {{ charge.units | number : "1.2-2" }}
                                </ng-template>
                              </p-cellEditor>
                            </div>
                          </td>
                          <!-- Miles -->
                          <td
                            id="celda3{{ y }}scrollable"
                            [pEditableColumn]="charge"
                            [pEditableColumnField]="charge.miles"
                            style="text-align: right"
                          >
                            <div>
                              <p-cellEditor class="elements-table">
                                <ng-template pTemplate="input">
                                  <input
                                    pInputText
                                    type="number"
                                    [(ngModel)]="charge.miles"
                                    (change)="editCharge(charge)"
                                    [disabled]="!batchInfo.isOpen"
                                  />
                                </ng-template>
                                <ng-template pTemplate="output" style="text-align: center">
                                  {{ charge.miles | number }}
                                </ng-template>
                              </p-cellEditor>
                            </div>
                          </td>
                          <!-- Rate -->
                          <td
                            id="celda4{{ y }}scrollable"
                            [pEditableColumn]="charge"
                            [pEditableColumnField]="charge.chargeRate"
                            style="text-align: right"
                          >
                            <div>
                              <p-cellEditor class="elements-table">
                                <ng-template pTemplate="input">
                                  <input
                                    pInputText
                                    type="number"
                                    [(ngModel)]="charge.chargeRate"
                                    (change)="editCharge(charge)"
                                    [disabled]="!batchInfo.isOpen"
                                  />
                                </ng-template>
                                <ng-template pTemplate="output" style="text-align: center">
                                  {{ charge.chargeRate | currency }}
                                </ng-template>
                              </p-cellEditor>
                            </div>
                          </td>
                          <!-- Total -->
                          <td style="text-align: right">{{ charge.price | currency }}</td>
                          <!-- Delete -->
                          <td>
                            <button
                              (click)="removeCharge(trip.driverCharges, charge)"
                              [disabled]="!batchInfo.isOpen"
                            >
                              -
                            </button>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
              </td>
          </tr>
      </ng-template>
      </p-table>

      <div align="center" style="margin-top: 20px">
        <button
          class="btn buttonBottom minWidthbutton"
          (click)="saveChanges()"
          [disabled]="!batchInfo?.isOpen"
        >
          SAVE
        </button>
      </div>
    </div>
  </div>
</div>
