<legend style="color: green">Express Code Totals</legend>
<p-table [columns]="totalsCols" [value]="totals" [scrollable]="true" [autoLayout]="true">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns">
        {{ col.header }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-total>
    <tr>
      <td *ngFor="let col of totalsCols">
        {{ total[col.field] | currency }}
      </td>
    </tr>
  </ng-template>
</p-table>

<legend style="color: green; margin-top: 10px">Express Codes</legend>

<div style="height: 700px; margin-bottom: 70px">
  <p-toolbar styleClass="toolbar-header">
    <div class="ui-toolbar-group-left">
      <button
        pButton
        type="button"
        icon="fa fa-file-excel-o"
        class="p-button-secondary mr-2"
        title="Export to Excel"
        label="Export"
        (click)="exportExcel()"
      ></button>
    </div>
  </p-toolbar>
  <p-table
    #moneyCodeTable
    [columns]="moneyCodeCols"
    [value]="moneyCodes"
    [scrollable]="true"
    [virtualScroll]="true"
    [virtualScrollItemSize]="42"
    [autoLayout]="true"
    scrollHeight="flex"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th></th>
        <th *ngIf="showBatchLink"></th>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
          {{ col.header }}
        </th>
      </tr>
      <tr>
        <th></th>
        <th *ngIf="showBatchLink"></th>
        <th *ngFor="let col of columns" [ngSwitch]="col.field">
          <input
            *ngIf="!col.hide && col.type != 'number'"
            type="text"
            style="width: 100%"
            (input)="$any(moneyCodeTable).filter($event.target.value, col.field, 'contains')"
            [value]="$any(moneyCodeTable).filters[col.field]?.value"
          />
          <input
            *ngIf="col.field == 'chargeTypeId'"
            type="text"
            style="width: 100%"
            (input)="
              $any(moneyCodeTable).filter($event.target.value, 'filterChargeType', 'contains')
            "
            [value]="$any(moneyCodeTable).filters['filterChargeType']?.value"
          />
          <input
            *ngIf="col.field == 'batchDriverId'"
            type="text"
            style="width: 100%"
            (input)="
              $any(moneyCodeTable).filter($event.target.value, 'filterDriverName', 'contains')
            "
            [value]="$any(moneyCodeTable).filters['filterDriverName']?.value"
          />
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-moneyCode let-y="rowIndex">
      <tr [ngClass]="{ 'deleted-row': moneyCode.softDelete }">
        <td>
          <button
            *ngIf="!moneyCode.softDelete"
            pButton
            type="button"
            icon="pi pi-trash"
            class="p-button-danger"
            (click)="setSoftDelete(moneyCode, true)"
            title="Delete Record"
          ></button>
          <button
            *ngIf="moneyCode.softDelete"
            pButton
            type="button"
            icon="pi pi-plus"
            class="p-button-secondary"
            (click)="setSoftDelete(moneyCode, false)"
            title="Restore"
          ></button>
        </td>
        <td style="text-overflow: clip" *ngIf="showBatchLink">
          <img
            *ngIf="moneyCode.batchDriverId"
            style="cursor: pointer; width: 21px"
            src="assets/img/details.svg"
            title="Go To Fuel Expense Grid"
            (click)="openDriverSettlement(moneyCode.batchDriver)"
          />
        </td>
        <ng-template ngFor let-col [ngForOf]="moneyCodeCols">
          <td *ngIf="col.type == 'driverId'">
            {{ moneyCode[col.field] }}
          </td>
          <td *ngIf="col.type == 'driver'">
            <p-dropdown
              [options]="drivers"
              [style]="{ minWidth: '100%', maxWidth: '100%' }"
              [filter]="true"
              [(ngModel)]="moneyCode.batchDriverId"
              optionLabel="label"
              optionValue="value"
              placeholder="Select a Driver"
              (onChange)="onChange()"
              appendTo="body"
            ></p-dropdown>
          </td>
          <td *ngIf="col.type == 'chargeType'">
            <p-dropdown
              [style]="{ minWidth: '100%', maxWidth: '100%' }"
              [options]="chargeTypes"
              optionLabel="label"
              optionValue="value"
              [(ngModel)]="moneyCode.chargeTypeId"
              (onChange)="onChange()"
              placeholder="none"
              appendTo="body"
            ></p-dropdown>
          </td>
          <td *ngIf="col.type === 'date'">
            {{ moneyCode[col.field] | date : "MM/dd/yyyy HH:mm" }}
          </td>
          <td
            *ngIf="col.type === 'string' || col.type === 'number' || col.type === 'currency'"
            [ngClass]="{ 'align-right': col.type === 'number' || col.type === 'currency' }"
            class="editable-cell"
            [pEditableColumn]="moneyCode"
            [pEditableColumnField]="moneyCode[col.field]"
          >
            <p-cellEditor class="elements-table">
              <ng-template pTemplate="input">
                <input
                  *ngIf="col.type === 'string'"
                  style="min-width: 100px"
                  type="text"
                  [(ngModel)]="moneyCode[col.field]"
                  (blur)="onChange()"
                  [title]="moneyCode[col.field]"
                />
                <input
                  *ngIf="col.type === 'number' || col.type === 'currency'"
                  style="min-width: 100px; text-align: right"
                  type="number"
                  min="0"
                  [(ngModel)]="moneyCode[col.field]"
                  (blur)="onChange()"
                  [title]="moneyCode[col.field]"
                />
              </ng-template>
              <ng-template pTemplate="output">
                <span
                  *ngIf="col.type === 'string'"
                  [style.color]="getEditedColor(moneyCode, col.field)"
                  [title]="moneyCode[col.field]"
                >
                  {{ moneyCode[col.field] }}
                </span>
                <span
                  *ngIf="col.type === 'number'"
                  [style.color]="getEditedColor(moneyCode, col.field)"
                  [title]="moneyCode[col.field]"
                >
                  {{ moneyCode[col.field] | number }}
                </span>
                <span
                  *ngIf="col.type === 'currency'"
                  [style.color]="getEditedColor(moneyCode, col.field)"
                  [title]="moneyCode[col.field]"
                >
                  {{ moneyCode[col.field] | negativeToParens }}
                </span>
              </ng-template>
            </p-cellEditor>
          </td>
        </ng-template>
      </tr>
    </ng-template>
  </p-table>
</div>
