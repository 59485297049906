import { Injectable } from "@angular/core";
import { TripOptions } from "@app/shared/models/trips/TripOptions.model";
import { NewTripService } from "./trip.service";
import { ProductService } from "@app/product/shared/products.service";
import { TripCharge } from "@app/shared/models/trips/TripCharge.model";
import { TripStop } from "@app/shared/models/trips/TripStop.model";
import moment from "moment";
import { StatesServices } from "@app/shared/services/states/states.service";
import { DialogService } from "primeng/dynamicdialog";
import { DuplicateRatesModalComponent } from "../components/duplicate-rates-modal/duplicate-rates-modal.component";
import { take } from "rxjs/operators";
import { SpinnerService } from "@app/shared/services/spinner/spinner.service";
import { firstValueFrom } from "rxjs";
import { LongevityViewModel } from "@app/shared/models/rates/LongevityViewModel.model";
import { FuelSurchargeViewModel } from "@app/shared/models/rates/FuelSurchargeViewModel.model";
import { InsuranceSurchargeViewModel } from "@app/shared/models/rates/InsuranceSurchargeViewModel.model";
import { ClientRateViewModel } from "@app/shared/models/rates/ClientRateViewModel.model";
import { DriverRateViewModel } from "@app/shared/models/rates/DriverRateViewModel.model";
import {
  ClientDriverRateViewModel,
  LoadingChargeViewModel,
} from "@app/shared/models/rates/ClientDriverRateViewModel.model";
import { Rate } from "@app/shared/models/rates/Rate.model";
import {
  CustomChargeCondition,
  CustomChargeRate,
  CustomChargeViewModel,
} from "@app/shared/models/rates/CustomChargeViewModel.model";
import { MinimumRateViewModel } from "@app/shared/models/rates/MinimumRateViewModel.model";
import { BaseRate } from "@app/shared/models/rates/BaseRate.model";

@Injectable({
  providedIn: "root",
})
export class RateCalculatorService {
  constructor(
    private DialogService: DialogService,
    private TripService: NewTripService,
    private ProductService: ProductService,
    private StatesService: StatesServices,
    private SpinnerService: SpinnerService
  ) {}

  // Put Linehaul/tonnage/flat first, then stop charges
  maxSortPriority: 14;
  sortPriority = {
    "SINGLE MILES": 1,
    "TEAM MILES": 2,
    "EXCESS MILES": 3,
    "LOAD TIME CHARGE": 4,
    "UNLOAD TIME CHARGE": 5,
    "ANY TIME CHARGE": 6,
    "STOP CHARGE": 7,
    "FUEL CHARGE": 8,
    "INSURANCE SURCHARGE": 9,
    "GURANTEED PAY": 10,
    "GUARANTEED MILES": 11,
    LONGEVITY: 12,
    CUSTOM: 13,
  };

  // --------------------------------------------------
  // Utility Functions
  // --------------------------------------------------
  roundToDollar(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  dateDifference(from: Date, to: Date, type: string, extraDays: number) {
    if (from && to) {
      let toDate = moment(to);
      let fromDate = moment(from);
      fromDate = fromDate.add(extraDays, "days");

      switch (type) {
        case "YEAR":
          return toDate.diff(fromDate, "years");
        case "MONTH":
          return toDate.diff(fromDate, "months");
        case "DAY":
          return toDate.diff(fromDate, "days");
      }
    }
    return 0;
  }

  findMatchingRate(rates: Rate[], value: number, kind?: string) {
    if (!kind) return rates.find((rate) => rate.floor <= value && rate.roof >= value);
    return rates.find((rate) => rate.kind === kind && rate.floor <= value && rate.roof >= value);
  }

  async resolveDuplicateRates(rates: BaseRate[], tripOptions: TripOptions, rateType: string) {
    this.SpinnerService.hide();
    const result = await firstValueFrom(
      this.DialogService.open(DuplicateRatesModalComponent, {
        data: {
          rates,
          tripOptions,
          rateType,
        },
        header: "Duplicate Rates",
        width: "600px",
      }).onClose.pipe(take(1))
    );
    this.SpinnerService.show();
    return result;
  }

  // --------------------------------------------------
  // Trip Charge Functions
  // --------------------------------------------------

  // Calculate the units/miles/rate for a custom charge
  calculateCustomCharge(
    chargeModel: CustomChargeViewModel,
    tripOptions: TripOptions,
    driver?: any
  ): TripCharge {
    const type = driver ? "DRIVER" : "CLIENT";
    const tripData = tripOptions.tripData;
    const rate: CustomChargeRate = chargeModel.customChargeRates.find(
      (chargeRate) => chargeRate.type === type
    );
    const name = chargeModel.name;
    const id = chargeModel.id;
    const chargeRate = rate.rate;
    const { flatOrVariable, chargePer } = rate;
    let usesUnits = true;
    let numUnits = 0;
    let miles = 0;
    let isVariable = true;

    if (flatOrVariable === "FLAT") {
      switch (chargePer) {
        case "TRIP":
          isVariable = false;
          break;
        case "STOP":
          numUnits = tripOptions.tripStops.length;
          break;
        case "DAY":
          numUnits = tripOptions.totalTime / (60 * 24);
          break;
        case "MILE":
          miles = tripOptions.miles;
          usesUnits = false;
          break;
        case "HOUR":
          numUnits = (tripOptions.totalTime / (60 * 24)) * 24;
          break;
        case "QUARTER":
          // what is "QUARTER" supposed to be?
          numUnits = 1;
          break;
      }
    } else if (flatOrVariable === "VARIABLE") {
      const { units, free, interval } = rate;
      if (chargePer === "TRIP") {
        // totalMiles | stopsNo | totalTime
        numUnits = tripData[units];
        numUnits -= free;
        if (units === "totalMiles") {
          miles = tripData[units];
          miles -= free;
          numUnits = 0;
          usesUnits = false;
        }
      } else if (chargePer === "STOP") {
        for (const stop of tripData.tripStops) {
          numUnits += stop[units];
          // round to the nearest interval
          numUnits = Math.round(numUnits / interval);
          numUnits -= free;
          numUnits = Math.max(0, numUnits);
        }
      }
    }

    let description = chargeModel.chargeType?.description;
    if (driver?.name) {
      description = `${description} - ${driver.name}`;
    }

    const tripCharge: TripCharge = {
      flatOrVariable: isVariable ? "VARIABLE" : "FLAT",
      chargeRate,
      usesUnits,
      miles,
      units: numUnits,
      rateLink: `/rates/custom/${id}`,
      rateName: name,
      price: 0,
      description,
      chargeTypeId: chargeModel.chargeType?.id,
      chargeType: chargeModel.chargeType,
      ratingType: "CUSTOM",
    };

    tripCharge.price = this.calculateChargePrice(tripCharge);

    return tripCharge;
  }

  // Calculate the insurance surcharge rate
  calculateInsuranceSurcharge(
    subtotal: number,
    insuranceRate: InsuranceSurchargeViewModel
  ): TripCharge {
    if (!insuranceRate) return null;

    const tripCharge: TripCharge = {
      flatOrVariable: "VARIABLE",
      miles: 0,
      chargeRate: insuranceRate.amount,
      units: subtotal,
      price: 0,
      usesUnits: true,
      rateLink: `/rates/insurance/${insuranceRate.id}`,
      isBeforeInsurance: false,
      description: insuranceRate.chargeType?.description,
      chargeTypeId: insuranceRate.chargeTypeId,
      chargeType: insuranceRate.chargeType,
      ratingType: "INSURANCE SURCHARGE",
    };

    if (insuranceRate) tripCharge.rateName = insuranceRate.name;
    tripCharge.price = this.calculateChargePrice(tripCharge);

    return tripCharge;
  }

  calculateLongevityPaymentForDriver(
    driver: any,
    tripOptions: TripOptions,
    rate: LongevityViewModel
  ) {
    const { chargePer, value, longevityRates } = rate;
    let chargeRate = 0;

    if (chargePer === "TRIP") {
      chargeRate = value;
    } else if (chargePer === "MILE") {
      const longevityDays = this.dateDifference(driver.hiredDate, tripOptions.startDate, "DAY", -1);
      if (longevityDays < 0) {
        throw new Error("Trip date is before Driver hired date");
      }
      const matchingRate = this.findMatchingRate(longevityRates, longevityDays);
      chargeRate = matchingRate?.price ?? 0;
    }

    const charge: TripCharge = {
      flatOrVariable: "VARIABLE",
      miles: tripOptions.miles,
      chargeRate,
      units: 0,
      usesUnits: false,
      rateName: rate.name,
      rateLink: `/rates/longevity/${rate.id}`,
      ratingType: "LONGEVITY",
      price: 0,
      description: `${rate.chargeType?.description} - ${driver.name}`,
      chargeTypeId: rate.chargeTypeId,
      chargeType: rate.chargeType,
      driverId: driver.id,
    };

    charge.price = this.calculateChargePrice(charge);

    return charge;
  }

  async calculateLongevityPayments(tripOptions: TripOptions) {
    const longevityPayments = [];
    for (const driver of tripOptions.drivers) {
      const rate = await this.getLongevityRatesFromOptions(tripOptions, driver.id);
      if (!rate) continue;
      const charge = this.calculateLongevityPaymentForDriver(driver, tripOptions, rate);
      if (charge) longevityPayments.push(charge);
    }
    return longevityPayments;
  }

  // Calculate the fuel surcharge rate
  async calculateFuelSurcharges(
    tripOptions: TripOptions,
    isDriver: boolean,
    baseRate: number,
    rate: FuelSurchargeViewModel,
    overrideGasPrice?: number // for testing
  ) {
    let { rateBy, fuelSurchargeRates, isStraightPassThrough, linkDriverFuelSurcharge, driverFuelSurcharge } = rate;
    if(rateBy === "DATERANGE") return this.calculateBimonthlyFuelSurcharge(tripOptions, isDriver, baseRate, rate, overrideGasPrice);
    // Get the gas price to use for the trip. If the trip passes through canada, use the
    // first canadian gas price. Otherwise, use the load stop's gas price.
    const { startDate, tripStops, tripData } = tripOptions;
    const loadStop = tripStops.find((stop) => stop.stopType === "LOAD");
    let state = loadStop ? loadStop.state : tripData.originLocState;
    const firstCanadaStop = tripStops.find(
      (stop) => this.StatesService.getStateCountry(stop.state) === "Canada"
    );
    if (firstCanadaStop) state = firstCanadaStop.state;

    let gasPrice: number;
    if (overrideGasPrice) gasPrice = overrideGasPrice;
    else {
      if (!state) return null;
      const { region } = await this.TripService.getFuelSurchargeRegionForState(rate.id, state);
      if (!region) return null;
      const fuelPrice = await this.TripService.getFuelPriceByRegionAndDateAsync(region, startDate);
      if (!fuelPrice) return null;

      gasPrice = fuelPrice;
    }

    let chargeRate = 0;
    let tonnage = 0; // used as units for percentage
    if (rateBy === "PERCENTAGE" || rateBy === "DATERANGE") {
      tonnage = Number(baseRate.toFixed(2));
    }

    // Compare fuel rates to fuel price
    const matchingRate = (isDriver && linkDriverFuelSurcharge)?  this.findMatchingRate(
      driverFuelSurcharge.fuelSurchargeRates,
      gasPrice,
      "DRIVER"
    ) : this.findMatchingRate(
      fuelSurchargeRates,
      gasPrice,
      isDriver && !isStraightPassThrough ? "DRIVER" : "CLIENT"
    )
    if (matchingRate) chargeRate = matchingRate.price;

    // Get highest rate roof
    const rates = (isDriver && linkDriverFuelSurcharge)? driverFuelSurcharge.fuelSurchargeRates.filter(
      (surchargeRate) =>
        surchargeRate.kind === "DRIVER"
    ) : fuelSurchargeRates.filter(
      (surchargeRate) =>
        surchargeRate.kind === (isDriver && !isStraightPassThrough ? "DRIVER" : "CLIENT")
    )
    // get the rate with the highest roof
    const highestRate = rates.reduce((prev, current) =>
      prev.roof > current.roof ? prev : current
    );
    const highestRoof = highestRate.roof;
    const highestPrice = highestRate.price;

    // If rates are all too low, use client/driver intervals
    if (highestRoof < gasPrice) {
      if (isDriver && !isStraightPassThrough) {
        chargeRate = linkDriverFuelSurcharge? this.calculateFuelSurchargeExcess(
          gasPrice,
          highestRoof,
          highestPrice,
          rate.driverFuelSurcharge.driverPriceIncrement,
          rate.driverFuelSurcharge.driverPriceInterval
        ) : this.calculateFuelSurchargeExcess(
          gasPrice,
          highestRoof,
          highestPrice,
          rate.driverPriceIncrement,
          rate.driverPriceInterval
        )
      } else {
        chargeRate = this.calculateFuelSurchargeExcess(
          gasPrice,
          highestRoof,
          highestPrice,
          rate.clientPriceIncrement,
          rate.clientPriceInterval
        );
      }
    }

    // Convert the charge rate to a string with the correct number of decimal places
    const roundedChargeRate = Number(chargeRate.toFixed(rate.decimals));

    const charge: TripCharge = {
      flatOrVariable: "VARIABLE",
      miles: rateBy === "PRICE" ? tripOptions.miles : 0,
      chargeRate: roundedChargeRate,
      units: tonnage,
      usesUnits: rateBy === "PERCENTAGE",
      rateName: rate.name,
      rateLink: `/rates/fuel/${rate.id}`,
      price: 0,
      description: `${rate.chargeType ? rate.chargeType.description : ""}`,
      chargeTypeId: rate.chargeTypeId,
      chargeType: rate.chargeType,
      ratingType: "FUEL",
    };

    charge.price = this.calculateChargePrice(charge);
    return charge;
  }

  async calculateBimonthlyFuelSurcharge(
    tripOptions: TripOptions,
    isDriver: boolean,
    baseRate: number,
    rate: FuelSurchargeViewModel,
    overrideGasPrice?: number // for testing)
  ) {
    let { rateBy, fuelSurchargeRates, isStraightPassThrough, linkDriverFuelSurcharge, driverFuelSurcharge } = rate;
    const { startDate, tripStops, tripData } = tripOptions;
    const loadStop = tripStops.find((stop) => stop.stopType === "LOAD");
    let state = loadStop ? loadStop.state : tripData.originLocState;
    const firstCanadaStop = tripStops.find(
      (stop) => this.StatesService.getStateCountry(stop.state) === "Canada"
    );
    if (firstCanadaStop) state = firstCanadaStop.state;

    let gasPrice: number;
    if (overrideGasPrice) gasPrice = overrideGasPrice;
    else {
      if (!state) return null;
      const { region } = await this.TripService.getFuelSurchargeRegionForState(rate.id, state);
      if (!region) return null;
      const fuelPrice = await this.TripService.getFuelPriceByRegionAndDateAsync(region, startDate);
      if (!fuelPrice) return null;

      gasPrice = fuelPrice;
    }

    let chargeRate = 0;
    let tonnage = Number(baseRate.toFixed(2));

    // Compare fuel rates to fuel price
    const matchingRate = (isDriver && linkDriverFuelSurcharge)?  this.findMatchingRate(
      driverFuelSurcharge.fuelSurchargeRates,
      gasPrice,
      "DRIVER"
    ) : this.findMatchingRate(
      fuelSurchargeRates,
      gasPrice,
      isDriver && !isStraightPassThrough ? "DRIVER" : "CLIENT"
    )
    if (matchingRate) chargeRate = matchingRate.price;

    // Get highest rate roof
    const rates = (isDriver && linkDriverFuelSurcharge)? driverFuelSurcharge.fuelSurchargeRates.filter(
      (surchargeRate) =>
        surchargeRate.kind === "DRIVER"
    ) : fuelSurchargeRates.filter(
      (surchargeRate) =>
        surchargeRate.kind === (isDriver && !isStraightPassThrough ? "DRIVER" : "CLIENT")
    )
    //get rate where trip start date falls withing rate.dateFloor and rate.dateRoof
    const matchingDateRate = rates.find((rate) => new Date(rate.dateFloor) <= new Date(tripOptions.startDate) && new Date(rate.dateRoof) >= new Date(tripOptions.startDate));
    const highestRoof = matchingDateRate.roof;
    const highestPrice = matchingDateRate.price;
    // If rates are all too low, use client/driver intervals
    if (highestRoof < gasPrice) {
      if (isDriver && !isStraightPassThrough) {
        console.log("driver intervals");
        chargeRate = linkDriverFuelSurcharge? this.calculateFuelSurchargeExcess(
          gasPrice,
          highestRoof,
          highestPrice,
          rate.driverFuelSurcharge.driverPriceIncrement,
          rate.driverFuelSurcharge.driverPriceInterval
        ) : this.calculateFuelSurchargeExcess(
          gasPrice,
          highestRoof,
          highestPrice,
          rate.driverPriceIncrement,
          rate.driverPriceInterval
        )
      } else {
        console.log("client intervals");
        chargeRate = this.calculateFuelSurchargeExcess(
          gasPrice,
          highestRoof,
          highestPrice,
          rate.clientPriceIncrement,
          rate.clientPriceInterval
        );
      }
    }

    // Convert the charge rate to a string with the correct number of decimal places
    const roundedChargeRate = Number(chargeRate.toFixed(rate.decimals));

    const charge: TripCharge = {
      flatOrVariable: "VARIABLE",
      miles: rateBy === "PRICE" ? tripOptions.miles : 0,
      chargeRate: roundedChargeRate,
      units: tonnage,
      usesUnits: rateBy === "PERCENTAGE" || rateBy === "DATERANGE",
      rateName: rate.name,
      rateLink: `/rates/fuel/${rate.id}`,
      price: 0,
      description: `${rate.chargeType ? rate.chargeType.description : ""}`,
      chargeTypeId: rate.chargeTypeId,
      chargeType: rate.chargeType,
      ratingType: "FUEL",
    };

    charge.price = this.calculateChargePrice(charge);
    return charge;
  }


  calculateFuelSurchargeExcess(
    gasPrice: number,
    peg: number,
    basePrice: number,
    increment: number,
    interval: number
  ) {
    if (increment === 0) return 0; //Can't divide by 0
    const numIncrements = (gasPrice - peg) / increment + 0.999; // adding 0.999 instead of 1, so the top of the interval rounds down
    const roundedIncrements = Math.floor(numIncrements);
    const amountToAdd = roundedIncrements * interval;
    return basePrice + amountToAdd;
  }

  getExcessRate(ratesGroup: ClientDriverRateViewModel, kind: string) {
    const excessRates = ratesGroup.excessRateItems;

    const excessRate = excessRates.find((rate) => rate.type === "SINGLE" && rate.kind === kind);
    return {
      type: "VARIABLE",
      chargeRate: excessRate.rate,
      chargeType: ratesGroup.excessMilesChargeType,
      isLaneOverride: false,
    };
  }

  // Parse the miles rates of a rates group, to find the rate that falls within the range
  // if a rate is not found, the excess rate is used
  getMilesRate(
    ratesGroup: ClientDriverRateViewModel,
    tripOptions: TripOptions,
    equipmentOrProduct: string
  ) {
    const { productName, trailerType, miles } = tripOptions;
    const rates = ratesGroup.rateItems;
    const kind = equipmentOrProduct === "PRODUCT" ? productName : trailerType;

    if (ratesGroup.laneOverrides) {
      const laneOverride = this.getLaneOverride(tripOptions, ratesGroup);

      const overrideRate = laneOverride.productValues.find(
        (product) => product.product?.name === kind || product.equipmentCode?.name === kind
      );
      return {
        type: "FLAT",
        chargeRate: overrideRate.value,
        chargeType: ratesGroup.singleChargeType,
        isLaneOverride: true,
      };
    }

    const parsedRates = rates.map((rate) => ({
      floor: rate.floor,
      roof: rate.roof,
      price: rate.rate,
      kind: rate.kind,
    }));
    const matchedRate = this.findMatchingRate(parsedRates, miles, kind);

    if (matchedRate) {
      const singleRate = {
        type: "FLAT",
        chargeRate: matchedRate.price,
        chargeType: ratesGroup.singleChargeType,
        isLaneOverride: false,
      };
      return singleRate;
    } else {
      // If there wasn't a matching rate, use the excess rate
      const excessRate = this.getExcessRate(ratesGroup, kind);
      return excessRate;
    }
  }

  // Get the total amount unloaded during the trip, converted to targetUnits
  async getTotalUnloadAmount(tripOptions: TripOptions, targetUnits: string) {
    const { unloadAmounts, productName } = tripOptions;
    let sum = 0;
    for (const stop of unloadAmounts) {
      const { amount, units } = stop;

      const convertedAmount = await this.ProductService.convertTo(
        productName,
        amount,
        units,
        targetUnits
      );

      sum += convertedAmount;
    }
    return Number(sum.toFixed(2));
  }

  // Updates the price on the charge, depending on the rate type
  // If the type is flat, the charge rate is the price
  // if the type is variable, the charge rate is multipled by either the miles or units
  calculateChargePrice(charge: TripCharge) {
    const { flatOrVariable, chargeRate, miles, units, usesUnits } = charge;
    const rateUnits = usesUnits ? units : miles;
    const price = flatOrVariable === "VARIABLE" ? rateUnits * Number(chargeRate) : chargeRate;

    charge.price = this.roundToDollar(Number(price));

    return Number(charge.price);
  }

  getPeriodLengthInHours(period: string) {
    switch (period) {
      case "DAILY":
        return 24;
    }
  }

  calculateGuaranteedPayCharge(
    allCharges: TripCharge[],
    tripOptions: TripOptions,
    minimumRate: MinimumRateViewModel,
    beforeInsurance: boolean
  ) {
    const { guaranteeType, guaranteePeriod, rate, chargeType, excludedChargeTypes } = minimumRate;
    const { totalTime } = tripOptions;
    const totalTimeHours = totalTime / 60;
    const periodLengthInHours = this.getPeriodLengthInHours(guaranteePeriod);

    if (guaranteeType !== "PAY") throw new Error("Invalid guarantee type for guaranteed pay");

    // sum all charges that don't have a charge type in the excludedChargeTypes array
    const excludedChargeTypeIds = excludedChargeTypes?.map((chargeType) => chargeType.id) ?? [];
    const adjustedGross = this.roundToDollar(
      allCharges
        .filter((charge) => charge != null && !excludedChargeTypeIds.includes(charge.chargeTypeId))
        .filter((charge) => (beforeInsurance ? charge.isBeforeInsurance : true))
        .reduce((acc, charge) => acc + Number(charge.price), 0)
    );
    const hourlyRate = rate / periodLengthInHours;
    const guaranteedAmount = totalTimeHours * hourlyRate;
    const extraRate = this.roundToDollar(guaranteedAmount - adjustedGross);

    if (extraRate > 0) {
      const charge: TripCharge = {
        flatOrVariable: "FLAT",
        miles: 0,
        chargeRate: extraRate,
        units: 0,
        usesUnits: true,
        rateName: minimumRate.name,
        rateLink: `/minimums/${minimumRate.id}`,
        price: 0,
        description: chargeType.description,
        chargeTypeId: chargeType.id,
        chargeType: chargeType,
        ratingType: "GUARANTEED PAY",
      };
      //added the calculated values here since I moved that up to before the guarantee calculation in the main driver charge function
      charge.price = this.calculateChargePrice(charge);
      charge.calculatedMiles = charge.miles;
      charge.calculatedChargeRate = charge.chargeRate;
      charge.calculatedUnits = charge.units;
      charge.calculatedPrice = charge.price;
      charge.calculatedDescription = charge.description;
      return charge;
    }
  }

  // If the trip does not have enough miles to meet the guaranteed trip miles
  // this charge is used in place of the regular single miles charge
  calculateGuaranteedMilesCharge(
    tripOptions: TripOptions,
    minimumRate: MinimumRateViewModel,
    excessRate: any
  ) {
    const { guaranteeType, guaranteePeriod, rate, chargeType } = minimumRate;
    const { miles, totalTime } = tripOptions;
    const totalTimeHours = totalTime / 60;
    const periodLengthInHours = this.getPeriodLengthInHours(guaranteePeriod);

    if (guaranteeType !== "MILES") throw new Error("Invalid guarantee type for guaranteed miles");

    const guaranteedMilesPerHour = rate / periodLengthInHours;
    const guaranteedMiles = Math.round(totalTimeHours * guaranteedMilesPerHour);
    const extraMiles = guaranteedMiles - miles;

    if (extraMiles > 0) {
      const charge: TripCharge = {
        flatOrVariable: "VARIABLE",
        miles: extraMiles,
        chargeRate: excessRate.chargeRate,
        units: 0,
        usesUnits: false,
        rateName: minimumRate.name,
        rateLink: `/minimums/${minimumRate.id}`,
        price: 0,
        description: chargeType.description,
        chargeTypeId: chargeType.id,
        chargeType: chargeType,
        ratingType: "GUARANTEED MILES",
        isBeforeInsurance: true, // guarantees are always before insurance
      };

      charge.price = this.calculateChargePrice(charge);
      return charge;
    }
  }

  // Calculate the single miles and team miles charges
  // If the driver has a guaranteed rate, use it if the calculated charge is below the guarantee
  async calculateMilesCharges(
    tripOptions: TripOptions,
    ratesGroup: ClientDriverRateViewModel,
    guaranteedRate?: MinimumRateViewModel
  ) {
    const equipmentOrProduct = ratesGroup.equipmentOrProduct;
    const weightOrDistance = ratesGroup.weightOrDistance;
    const decimals = ratesGroup.decimals;
    let miles = tripOptions.miles;
    let extraMiles = 0;
    let units = 0;
    let charges = [];

    const { productName, trailerType } = tripOptions;
    const kind = equipmentOrProduct === "PRODUCT" ? productName : trailerType;
    const milesRate = this.getMilesRate(ratesGroup, tripOptions, equipmentOrProduct);

    // Check if there is a guaranteed client/driver miles rate, and use it if the trip
    // doesn't have enough miles to meet the guarantee (lane overrides don't have excess rates)
    if (guaranteedRate?.guaranteeType === "MILES" && !milesRate.isLaneOverride) {
      const excessRate = this.getExcessRate(ratesGroup, kind);
      const guaranteedMilesCharge = this.calculateGuaranteedMilesCharge(
        tripOptions,
        guaranteedRate,
        excessRate
      );
      if (guaranteedMilesCharge) charges.push(guaranteedMilesCharge);
      extraMiles = guaranteedMilesCharge?.miles;
    }

    // always use the excess rate if we're using guaranteed miles
    let singleRate: { type: string; chargeRate: number; chargeType: any };
    if (milesRate.isLaneOverride) singleRate = milesRate;
    else if (extraMiles > 0) singleRate = this.getExcessRate(ratesGroup, kind);
    else singleRate = milesRate;

    if (!singleRate) return null;

    if (weightOrDistance === "WEIGHT") {
      // If we're calculating by weight, then the units are the weight
      const targetUnits = ratesGroup.rateByUnits;
      units = await this.getTotalUnloadAmount(tripOptions, targetUnits);
      singleRate.type = "VARIABLE";
    }

    const usesUnits = weightOrDistance === "WEIGHT";

    // Add the single miles charge
    charges.push({
      miles: miles,
      flatOrVariable: singleRate.type,
      chargeRate: Number(singleRate.chargeRate.toFixed(decimals)),
      units,
      usesUnits,
      description: singleRate.chargeType?.description,
      chargeTypeId: singleRate.chargeType?.id,
      chargeType: singleRate.chargeType,
      ratingType: "SINGLE MILES",
    });
    // Add the team charge, if it has one
    if (tripOptions.isTeam && weightOrDistance === "DISTANCE") {
      charges.push({
        miles: miles,
        flatOrVariable: "VARIABLE",
        chargeRate: Number(ratesGroup.teamCharge.toFixed(decimals)),
        units: 0,
        usesUnits: false,
        description: ratesGroup.teamChargeType?.description,
        chargeTypeId: ratesGroup.teamChargeType?.id,
        chargeType: ratesGroup.teamChargeType,
        ratingType: "TEAM MILES",
      });
    }

    return charges;
  }

  // Calculate charges based on the time spent at each stop
  calculateStopTimeCharge(stopType: string, rate: LoadingChargeViewModel, tripStops: TripStop[]) {
    if (!rate) return;
    const { freeTime, pricePerHour, timeInterval, useFreeTimePerStop } = rate;
    const { stop1FreeTime, stop2FreeTime, stop3FreeTime, stop4FreeTime } = rate;
    const freeTimeMinutes = freeTime * 60;

    const getFreeTimeMinutesForStop = (stopNumber: number) => {
      if (!useFreeTimePerStop) return freeTimeMinutes;
      switch (stopNumber) {
        case 1:
          return stop1FreeTime * 60;
        case 2:
          return stop2FreeTime * 60;
        case 3:
          return stop3FreeTime * 60;
        case 4:
          return stop4FreeTime * 60;
        default:
          return stop4FreeTime;
      }
    };

    // Units is the amount of hours
    let numIntervals = 0;
    let stopNumber = 0;
    if (useFreeTimePerStop) {
      for (let stop of tripStops) {
        if (stop.stopType === stopType || (stopType === "ANY" && stop.stopType !== "OTHER")) {
          stopNumber++;
          const { stopTimeMinutes } = stop;
          // Free time is subtracted from each stop
          const freeTimeForStop = getFreeTimeMinutesForStop(stopNumber);
          const chargedTime = stopTimeMinutes - freeTimeForStop;
          if (chargedTime > 0) {
            // Round to the nearest interval
            numIntervals += Math.round(chargedTime / timeInterval);
          }
        }
      }
    } else {
      const stops = tripStops.filter(
        (stop) => stop.stopType === stopType || (stopType === "ANY" && stop.stopType !== "OTHER")
      );
      const totalStopTime = stops.reduce((n, { stopTimeMinutes }) => n + stopTimeMinutes, 0);
      if (totalStopTime > freeTimeMinutes)
        numIntervals = Math.round((totalStopTime - freeTimeMinutes) / timeInterval);
    }

    const intervalsToHours = this.roundToDollar((numIntervals * timeInterval) / 60);
    const chargeRate = pricePerHour;
    const units = intervalsToHours;

    return {
      miles: 0,
      flatOrVariable: "VARIABLE",
      rateLink: "",
      usesUnits: true,
      chargeRate,
      units,
      ratingType: `${stopType} TIME CHARGE`,
    };
  }

  findLoadChargeRate(rates: LoadingChargeViewModel[], miles: number) {
    return rates.find(
      (rate) => rate.milesFloor <= miles && (rate.milesRoof == null || rate.milesRoof >= miles)
    );
  }

  hasStopType(stopTypes: LoadingChargeViewModel[], type: string) {
    return stopTypes.some(
      (stopType) => stopType.loadingChargeType.toLowerCase() === type.toLocaleLowerCase()
    );
  }

  parseLoadingCharges(loadingCharges: LoadingChargeViewModel[], type: string) {
    return loadingCharges
      .filter((charge) => charge.loadingChargeType === type)
      .sort((row1, row2) => row1.milesFloor - row2.milesFloor);
  }

  shouldUseDelayedCharges(delayedCharges: LoadingChargeViewModel[], miles: number) {
    if (delayedCharges && delayedCharges[0]) {
      return delayedCharges[0].milesFloor <= miles;
    } else {
      return false;
    }
  }

  // Calculate the stop charges for a trip from a rates group
  calculateStopCharges(tripOptions: TripOptions, ratesGroup: ClientDriverRateViewModel) {
    // Calculate the charges for each stop
    // Creates one line for each different charge Rate
    const getChargesPerStop = (rate: any, stopType: string, tripStops: TripStop[]) => {
      if (!rate) return [];

      const charges = [];
      let stopCount = 0;
      let chargedStops = 0;
      for (let stop of tripStops) {
        if (stop.stopType === stopType || (stopType === "ANY" && stop.stopType !== "OTHER")) {
          stopCount += 1;
          const stopNumber = Math.min(stopCount, 4);
          const stopName = `stop${stopNumber}`;
          const stopCharge = Number(rate[stopName]) || 0;

          if (stopCharge > 0) {
            chargedStops += 1;
            charges.push(stopCharge);
          }
        }
      }

      return charges;
    };

    const { miles, tripStops } = tripOptions;
    const { loadingCharges } = ratesGroup;
    const loadCharges = this.parseLoadingCharges(loadingCharges, "LOADING");
    const unloadCharges = this.parseLoadingCharges(loadingCharges, "UNLOADING");
    const delayedCharges = this.parseLoadingCharges(loadingCharges, "DELAYED");

    let charges = [];
    let stopCharges = [];

    // determine if we should use Delayed Charges
    if (this.shouldUseDelayedCharges(delayedCharges, miles)) {
      const rate = this.findLoadChargeRate(delayedCharges, miles);
      rate.useFreeTimePerStop = ratesGroup.delaySetFreeTimePerStop;
      let delayedCharge: any = this.calculateStopTimeCharge("ANY", rate, tripStops);
      if (delayedCharge) {
        delayedCharge.description = ratesGroup.delayChargeType?.description;
        delayedCharge.chargeTypeId = ratesGroup.delayChargeType?.id;
        delayedCharge.chargeType = ratesGroup.delayChargeType;
        charges.push(delayedCharge);
      }
      // Delay Charges only count unload stops when getting the number of stops
      // (They do still include the stop time for the load)
      stopCharges = stopCharges.concat(getChargesPerStop(rate, "UNLOAD", tripStops));
    } else {
      // Otherise, calculate both load and unload charges
      const loadRate = this.findLoadChargeRate(loadCharges, miles);
      if (loadRate) {
        loadRate.useFreeTimePerStop = ratesGroup.loadSetFreeTimePerStop;
        const loadingCharge: any = this.calculateStopTimeCharge("LOAD", loadRate, tripStops);
        loadingCharge.description = ratesGroup.loadChargeType?.description;
        loadingCharge.chargeTypeId = ratesGroup.loadChargeType?.id;
        loadingCharge.chargeType = ratesGroup.loadChargeType;
        if (loadingCharge) charges.push(loadingCharge);
      }

      stopCharges = stopCharges.concat(getChargesPerStop(loadRate, "LOAD", tripStops));

      const unloadRate = this.findLoadChargeRate(unloadCharges, miles);
      if (unloadRate) {
        unloadRate.useFreeTimePerStop = ratesGroup.unloadSetFreeTimePerStop;
        const unloadingCharge: any = this.calculateStopTimeCharge("UNLOAD", unloadRate, tripStops);
        unloadingCharge.description = ratesGroup.unloadChargeType?.description;
        unloadingCharge.chargeTypeId = ratesGroup.unloadChargeType?.id;
        unloadingCharge.chargeType = ratesGroup.unloadChargeType;
        if (unloadingCharge) charges.push(unloadingCharge);
      }

      stopCharges = stopCharges.concat(getChargesPerStop(unloadRate, "UNLOAD", tripStops));
    }

    // stop charges is a list of the charge rates for each stop
    const stopChargeFreqs = stopCharges.reduce((acc, curr) => {
      return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
    }, {});

    // Create a charge for every different stop charge rate
    // Units are the number of stops with that exact rate
    for (const charge in stopChargeFreqs) {
      const chargeFreq = stopChargeFreqs[charge];
      const chargeRate = Number(charge);
      charges.push({
        miles: 0,
        flatOrVariable: "VARIABLE",
        rateLink: "",
        usesUnits: true,
        chargeRate: chargeRate,
        units: chargeFreq,
        description: ratesGroup.stopChargeType?.description,
        chargeTypeId: ratesGroup.stopChargeType?.id,
        chargeType: ratesGroup.stopChargeType,
        ratingType: "STOP CHARGE",
      });
    }

    return charges;
  }

  // calculate the charges from a rates group (which is itself a collection of rates)
  async calculateRatesGroupCharges(
    ratesGroup: ClientDriverRateViewModel,
    tripOptions: TripOptions,
    rateLink: string,
    guaranteedRate?: MinimumRateViewModel
  ) {
    let charges = [];

    const milesCharges = await this.calculateMilesCharges(tripOptions, ratesGroup, guaranteedRate);
    const stopCharges = this.calculateStopCharges(tripOptions, ratesGroup);

    charges = charges.concat(milesCharges);
    charges = charges.concat(stopCharges);

    // Add the link and calculate the price for all of the charges
    for (let charge of charges) {
      if (!charge.rateLink) charge.rateLink = rateLink;
      if (!charge.rateName) charge.rateName = ratesGroup.name;
      charge.price = this.calculateChargePrice(charge);
    }
    return charges;
  }

  async getClientRatesFromOptions(tripOptions: TripOptions): Promise<ClientRateViewModel> {
    const laneRatePromise = this.TripService.getClientLaneRatesFromOptions(tripOptions);
    const ratesPromise = this.TripService.getClientRatesFromOptions(tripOptions);
    const [laneRate, rates] = await Promise.all([laneRatePromise, ratesPromise]);

    if (laneRate.length > 0) return laneRate[0];

    if (rates.length === 0) return;
    else if (rates.length > 1) {
      return await this.resolveDuplicateRates(rates, tripOptions, "CLIENT");
    } else {
      return rates[0];
    }
  }

  async getDriverRatesFromOptions(tripOptions: TripOptions): Promise<DriverRateViewModel> {
    const laneRatePromise = this.TripService.getDriverLaneRatesFromOptions(tripOptions);
    const ratesPromise = this.TripService.getDriverRatesFromOptions(tripOptions);
    const [laneRate, rates] = await Promise.all([laneRatePromise, ratesPromise]);

    if (laneRate.length > 0) return laneRate[0];

    if (rates.length === 0) return;
    else if (rates.length > 1) {
      return await this.resolveDuplicateRates(rates, tripOptions, "DRIVER");
    } else {
      return rates[0];
    }
  }

  async getInsuranceRatesFromOptions(
    tripOptions: TripOptions
  ): Promise<InsuranceSurchargeViewModel> {
    const insuranceRates = await this.TripService.getInsuranceRatesFromOptions(tripOptions);

    if (insuranceRates.length === 0) return;
    else if (insuranceRates.length > 1) {
      return await this.resolveDuplicateRates(insuranceRates, tripOptions, "INSURANCE");
    } else {
      return insuranceRates[0];
    }
  }

  async getFuelRatesFromOptions(
    tripOptions: TripOptions,
    ratingType: string
  ): Promise<FuelSurchargeViewModel> {
    const rates = await this.TripService.getFuelRatesFromOptions({ ...tripOptions }, ratingType);

    if (rates.length === 0) return;
    else if (rates.length > 1) {
      return await this.resolveDuplicateRates(rates, tripOptions, "FUEL");
    } else {
      return rates[0];
    }
  }

  async getLongevityRatesFromOptions(
    tripOptions: TripOptions,
    driverId: string
  ): Promise<LongevityViewModel> {
    const rates = await this.TripService.getLongevityFromOptions({
      ...tripOptions,
      driverId,
    });

    if (rates.length === 0) return;
    else if (rates.length > 1) {
      return await this.resolveDuplicateRates(rates, tripOptions, "LONGEVITY");
    } else {
      return rates[0];
    }
  }

  getLaneOverride(tripOptions: TripOptions, ratesGroup: ClientDriverRateViewModel) {
    const firstLoad = tripOptions.tripStops.find((stop) => stop.stopType === "LOAD");
    const unloadStops = tripOptions.tripStops.filter((stop) => stop.stopType === "UNLOAD");
    const lastUnload = unloadStops[unloadStops.length - 1];

    return ratesGroup.laneOverrides.find(
      (override) =>
        override.loadLocationId.toLowerCase() === firstLoad.locationId.toLowerCase() &&
        override.unloadLocationId.toLowerCase() === lastUnload.locationId.toLowerCase()
    );
  }

  // Match a client ratesgroup on the server, then calculate the charges
  async calculateClientCharges(
    tripOptions: TripOptions,
    clientRates: ClientRateViewModel,
    guaranteedRate?: MinimumRateViewModel
  ): Promise<TripCharge[]> {
    // If the rate uses one way miles, set the billable miles to the one way miles
    // (unless we've already manually set the billable miles)
    const { totalMiles, totalOneWayMiles } = tripOptions.tripData;
    const shouldUpdateBillableMiles =
      !tripOptions.miles ||
      tripOptions.miles === totalMiles ||
      tripOptions.miles === totalOneWayMiles;
    if (shouldUpdateBillableMiles) {
      if (clientRates.isOneWay) {
        tripOptions.miles = totalOneWayMiles;
        tripOptions.tripData.billableMiles = totalOneWayMiles;
      } else {
        tripOptions.miles = totalMiles;
        tripOptions.tripData.billableMiles = totalMiles;
      }
    }

    // If the rate is a lane override, set the billable miles to the miles on the lane override
    if (clientRates.laneOverrides) {
      const laneOverride = this.getLaneOverride(tripOptions, clientRates);
      const routeMiles = Math.round(laneOverride.routeMiles);

      tripOptions.miles = routeMiles;
      tripOptions.tripData.billableMiles = routeMiles;
    }

    return await this.calculateRatesGroupCharges(
      clientRates,
      tripOptions,
      `/rates/${clientRates.laneOverrides ? "lane" : ""}client/${clientRates.id}`,
      guaranteedRate
    );
  }

  // find the matching driver ratesgroup, then use it to calculate the charges
  async calculateDriverCharges(
    tripOptions: TripOptions,
    driverRates: DriverRateViewModel,
    guaranteedRate?: MinimumRateViewModel
  ): Promise<TripCharge[]> {
    // If the rate uses one way miles, set the payable miles to the one way miles
    // (unless we've already manually set the payable miles)
    const { totalMiles, totalOneWayMiles } = tripOptions.tripData;
    const shouldUpdatePayableMiles =
      !tripOptions.miles ||
      tripOptions.miles === totalMiles ||
      tripOptions.miles === totalOneWayMiles;
    if (shouldUpdatePayableMiles) {
      if (driverRates.isOneWay) {
        tripOptions.miles = totalOneWayMiles;
        tripOptions.tripData.payableMiles = totalOneWayMiles;
      } else {
        tripOptions.miles = totalMiles;
        tripOptions.tripData.payableMiles = totalMiles;
      }
    }

    // If the rate is a lane override, set the billable miles to the miles on the lane override
    if (driverRates.laneOverrides) {
      const laneOverride = this.getLaneOverride(tripOptions, driverRates);
      const routeMiles = Math.round(laneOverride.routeMiles);

      tripOptions.miles = routeMiles;
      tripOptions.tripData.payableMiles = routeMiles;
    }

    return await this.calculateRatesGroupCharges(
      driverRates,
      tripOptions,
      `/rates/${driverRates.laneOverrides ? "lane" : ""}driver/${driverRates.id}`,
      guaranteedRate
    );
  }

  // check if this trip meets the condition for a custom charge
  checkCustomChargeConditions(conditions: CustomChargeCondition[], tripOptions: TripOptions) {
    const tripData = tripOptions.tripData;
    for (const condition of conditions) {
      // for now, the only type is string, the only tables are "Trip" and "TripStop"
      const { field, table, type, textMultiple } = condition;
      if (table === "Trip") {
        const value = tripData[field];
        if (type === "string" && !textMultiple.includes(value)) {
          return false;
        }
      } else if (table === "TripStop") {
        // Just one of the stops needs to match the condition
        let found = false;
        for (let stop of tripData.tripStops) {
          const value = stop[field];
          if (type === "string" && textMultiple.includes(value)) {
            found = true;
          }
        }
        if (!found) {
          return false;
        }
      }
    }

    return true;
  }

  // check if we meet the conditions for each custom charge, and if so, create it
  getCustomChargesFromModels(
    customChargeModels: CustomChargeViewModel[],
    tripOptions: TripOptions,
    driver?: any
  ) {
    let charges = [];
    for (const chargeModel of customChargeModels) {
      // If the custom charge has conditions, determine if we meet them
      const conditions = chargeModel.customChargeConditions;
      if (this.checkCustomChargeConditions(conditions, tripOptions)) {
        const charge = this.calculateCustomCharge(chargeModel, tripOptions, driver);
        if (charge) charges.push(charge);
      }
    }
    return charges;
  }

  // Calculate the custom charges for a client or driver
  async getCustomCharges(tripOptions: TripOptions, type: string): Promise<TripCharge[]> {
    let charges = [];

    if (type === "DRIVER") {
      // Only pay to drivers custom charges are applied
      let driver = tripOptions.drivers.find((driver) => driver.isPayTo);
      if (!driver) driver = tripOptions.drivers[0];
      const driverId = driver.id;
      const customChargeModels: CustomChargeViewModel[] =
        await this.TripService.getCustomChargesFromOptions(tripOptions, type, driverId);
      const driverCharges = this.getCustomChargesFromModels(
        customChargeModels,
        tripOptions,
        driver
      );
      charges = charges.concat(driverCharges);
    } else if (type === "CLIENT") {
      const customChargeModels: CustomChargeViewModel[] =
        await this.TripService.getCustomChargesFromOptions(tripOptions, type);
      const clientCharges = this.getCustomChargesFromModels(customChargeModels, tripOptions);
      charges = charges.concat(clientCharges);
    }

    return charges;
  }

  // Get the sum of all miles charges (single, team, guaranteed)
  getTonnageBaseRate(charges: TripCharge[]) {
    const mileChargeTypes = ["SINGLE MILES", "TEAM MILES", "GUARANTEED MILES"];
    const milesCharges = charges.filter(
      (charge) => charge != null && mileChargeTypes.includes(charge.ratingType)
    );

    // Add the link and calculate the price for all of the charges
    let total = 0;
    for (let charge of milesCharges) {
      total += this.calculateChargePrice(charge);
    }

    return total;
  }

  sortCharges(charges: TripCharge[]) {
    return charges.sort((a, b) => {
      const sortPriorityA = this.sortPriority[a.ratingType] || this.maxSortPriority;
      const sortPriorityB = this.sortPriority[b.ratingType] || this.maxSortPriority;
      return sortPriorityA - sortPriorityB;
    });
  }

  addCalculatedFields(charges: TripCharge[]) {
    return charges.map((charge) => ({
      ...charge,
      locked: false,
      calculatedMiles: charge.miles,
      calculatedChargeRate: charge.chargeRate,
      calculatedUnits: charge.units,
      calculatedPrice: charge.price,
      calculatedDescription: charge.description,
    }));
  }

  addCalculatedFieldsToCharge(charge: TripCharge) {
    return {
      ...charge,
      locked: false,
      calculatedMiles: charge.miles,
      calculatedChargeRate: charge.chargeRate,
      calculatedUnits: charge.units,
      calculatedPrice: charge.price,
      calculatedDescription: charge.description,
    };
  }

  // Client Charges
  async calculateCharges(tripOptions: TripOptions) {
    this.SpinnerService.show();

    try {
      let charges: TripCharge[] = [];

      // Get the insurance rate first, so we know if charge types are before insurance
      const insuranceRate = await this.getInsuranceRatesFromOptions(tripOptions);
      const clientRates = await this.getClientRatesFromOptions(tripOptions);
      if (!clientRates) throw new Error("No client rates were found that apply to this trip");
      const ratingType = clientRates.weightOrDistance;
      let useFuelSurcharge = true;
      if (clientRates.laneOverrides) {
        const laneOverride = this.getLaneOverride(tripOptions, clientRates);
        if (laneOverride.suppressFuelSurcharge) useFuelSurcharge = false;
      }

      let fuelRate: FuelSurchargeViewModel;
      if (useFuelSurcharge) {
        fuelRate = await this.getFuelRatesFromOptions(tripOptions, ratingType);
        if (!fuelRate) throw new Error("No fuel rates were found that apply to this trip");
      }

      if (!insuranceRate) throw new Error("No insurance rates were found that apply to this trip");

      const { includedChargeTypes } = insuranceRate;
      tripOptions.beforeInsuranceCharges = includedChargeTypes.map((charge) => charge.name);

      const { billToId, endDate } = tripOptions;
      const guaranteedRate = await this.TripService.getClientGuaranteedRate(billToId, endDate);

      const addClientCharges = async () => {
        const clientCharges = await this.calculateClientCharges(
          tripOptions,
          clientRates,
          guaranteedRate
        );
        if (!clientCharges) throw new Error("No client rates were found that apply to this trip");
        charges = charges.concat(clientCharges);
      };
      const addCustomCharges = async () => {
        const customCharges = await this.getCustomCharges(tripOptions, "CLIENT");
        charges = charges.concat(customCharges);
      };

      const promises = [];
      promises.push(addClientCharges());
      promises.push(addCustomCharges());

      await Promise.all(promises);

      // fuel charges use the miles charges calculated in addClientCharges for the base rate
      const baseRate = this.getTonnageBaseRate(charges);
      if (fuelRate) {
        const fuelCharges = await this.calculateFuelSurcharges(
          tripOptions,
          false,
          baseRate,
          fuelRate
        );
        if (fuelCharges) charges = charges.concat(fuelCharges);
      }

      // Mark all charges as before/after insurance
      if (insuranceRate) {
        for (const charge of charges) {
          const { beforeInsuranceCharges } = tripOptions;
          const chargeType = charge.chargeType.name;
          const beforeInsurance = beforeInsuranceCharges.includes(chargeType);
          if (beforeInsurance) charge.isBeforeInsurance = true;
        }
      } else {
        // If we don't have an insurance rate, assume all charges are before insurance
        charges = charges.map((charge) => ({
          ...charge,
          isBeforeInsurance: true,
        }));
      }
      charges = charges.filter((charge) => Number(charge.price) > 0);

      this.sortCharges(charges);
      charges = this.addCalculatedFields(charges);
      this.updateChargesPreserveLock(tripOptions.tripData.tripCharges, charges);

      // Guaranteed Minimum Rate (is always before insurance)
      if (guaranteedRate?.guaranteeType === "PAY") {
        const guaranteeCharge = this.calculateGuaranteedPayCharge(
          charges,
          tripOptions,
          guaranteedRate,
          true
        );
        // hard coding this to be before insurance - may need to change in the future
        if (guaranteeCharge) {
          guaranteeCharge.isBeforeInsurance = true;
          charges.push(this.addCalculatedFieldsToCharge(guaranteeCharge));
        }
      }

      // Insurance has to be calculated last
      const lockedInsuranceRate = charges.find(
        (charge) => charge.ratingType === "INSURANCE SURCHARGE" && charge.isLocked
      );

      if (insuranceRate) {
        const subtotal = this.roundToDollar(
          charges
            .filter((charge) => charge.isBeforeInsurance)
            .reduce((a, b) => a + Number(b.price), 0)
        );
        const calculatedSubtotal = this.roundToDollar(
          charges
            .filter((charge) => charge.isBeforeInsurance)
            .reduce((a, b) => a + Number(b.calculatedPrice), 0)
        );
        let insuranceCharge = this.calculateInsuranceSurcharge(subtotal, insuranceRate);
        insuranceCharge = this.addCalculatedFieldsToCharge(insuranceCharge);

        // Manually calculate the calculated units and price, so that it shows as red
        // If there is a changed locked value in one of the prices we use to generate the subtotal
        insuranceCharge.calculatedUnits = calculatedSubtotal;
        insuranceCharge.calculatedPrice = this.roundToDollar(
          calculatedSubtotal * Number(insuranceCharge.chargeRate)
        );

        if (!lockedInsuranceRate) {
          charges.push(insuranceCharge);
        } else {
          lockedInsuranceRate.calculatedUnits = insuranceCharge.calculatedUnits;
          lockedInsuranceRate.calculatedPrice = insuranceCharge.calculatedPrice;
        }
      }

      charges = charges.filter((charge) => Number(charge.price) > 0);

      this.sortCharges(charges);
      this.updateChargesPreserveLock(tripOptions.tripData.tripCharges, charges);
      return charges;
    } catch (error) {
      throw error;
    } finally {
      this.SpinnerService.hide();
    }
  }

  // Driver Payments
  async calculatePayments(tripOptions: TripOptions) {
    this.SpinnerService.show();
    const { driverId, endDate: tripDate } = tripOptions;
    let charges: TripCharge[] = [];
    const promises = [];

    try {
      const guaranteedRate = await this.TripService.getDriverGuaranteedRate(driverId, tripDate);
      const driverRates = await this.getDriverRatesFromOptions(tripOptions);
      if (!driverRates) throw new Error("No driver rates were found that apply to this trip");
      const ratingType = driverRates.weightOrDistance;
      let useFuelSurcharge = true;
      if (driverRates.laneOverrides) {
        const laneOverride = this.getLaneOverride(tripOptions, driverRates);
        if (laneOverride.suppressFuelSurcharge) useFuelSurcharge = false;
      }

      let fuelRate: FuelSurchargeViewModel;
      if (useFuelSurcharge) {
        fuelRate = await this.getFuelRatesFromOptions(tripOptions, ratingType);
      }

      const addDriverCharges = async () => {
        const driverCharges = await this.calculateDriverCharges(
          tripOptions,
          driverRates,
          guaranteedRate
        );
        if (driverCharges) charges = charges.concat(driverCharges);
        else throw new Error("No driver rates were found that apply to this trip");
      };

      const addCustomCharges = async () => {
        const customCharges = await this.getCustomCharges(tripOptions, "DRIVER");
        charges = charges.concat(customCharges);
      };

      const addLongevityCharges = async () => {
        const longevityPayments = await this.calculateLongevityPayments(tripOptions);
        charges = charges.concat(longevityPayments);
      };

      promises.push(addDriverCharges());
      promises.push(addCustomCharges());
      promises.push(addLongevityCharges());

      await Promise.all(promises);

      const baseRate = this.getTonnageBaseRate(charges);
      if (fuelRate) {
        const fuelCharge = await this.calculateFuelSurcharges(
          tripOptions,
          true,
          baseRate,
          fuelRate
        );
        if (fuelCharge) charges = charges.concat(fuelCharge);
      }

      charges = charges.filter((charge) => charge != null && Number(charge.price) > 0);

      this.sortCharges(charges);
      charges = this.addCalculatedFields(charges);

      this.updateChargesPreserveLock(tripOptions.tripData.driverCharges, charges);
      // Guaranteed minimum payment
      if (guaranteedRate && guaranteedRate.guaranteeType === "PAY") {
        const guaranteeCharge = this.calculateGuaranteedPayCharge(
          charges,
          tripOptions,
          guaranteedRate,
          false
        );
        if (guaranteeCharge) charges.push(this.addCalculatedFieldsToCharge(guaranteeCharge));
      }

      this.sortCharges(charges);

      this.updateChargesPreserveLock(tripOptions.tripData.driverCharges, charges);
      return charges;
    } catch (error) {
      throw error;
    } finally {
      this.SpinnerService.hide();
    }
  }

  // For after we've calculated new charges, update the existing charges, preserving any charges
  // that are locked
  updateChargesPreserveLock(currentCharges: TripCharge[], calculatedCharges: TripCharge[]) {
    if (!currentCharges) return calculatedCharges;

    // If we are updating a locked charge, keep the locked charge values, but update the rest
    for (const charge of calculatedCharges) {
      const { ratingType, chargeTypeId } = charge;
      const existingCharge = currentCharges.find(
        (c) => c.ratingType === ratingType && c.chargeTypeId === chargeTypeId
      );
      if (existingCharge && existingCharge.isLocked) {
        charge.isLocked = true;
        charge.miles = existingCharge.miles;
        charge.chargeRate = existingCharge.chargeRate;
        charge.units = existingCharge.units;
        charge.price = existingCharge.price;
        charge.description = existingCharge.description;
      }
    }

    // Also, re-add any locked charges that aren't in the calculated charges
    for (const charge of currentCharges) {
      const foundChargeWithSameRating = calculatedCharges.find(
        (c) => c.ratingType === charge.ratingType && c.chargeTypeId === charge.chargeTypeId
      );
      if (charge.isLocked && !foundChargeWithSameRating) {
        calculatedCharges.push(charge);
      }
    }

    this.sortCharges(calculatedCharges);

    return calculatedCharges;
  }
}
