import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app.router";
import { NgxSpinnerModule } from "ngx-spinner";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";

import { SharedModule } from "./shared/shared.module";

import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { HeaderComponent } from "./header/header.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { HomeComponent } from "./home/home.component";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
// trip services and components
// trailer services and components
import { TrailerinfoComponent } from "./trailer/trailerinfo/trailerinfo.component";
import { TrailerService } from "./trailer/shared/trailer.service";
import { TrailerlistComponent } from "./trailer/trailerlist/trailerlist.component";
import { AddtrailerComponent } from "./trailer/addtrailer/addtrailer.component";
// tractor services and components
import { TruckinfoComponent } from "./tractor/tractorinfo/truckinfo.component";
import { TractorlistComponent } from "./tractor/tractorlist/tractorlist.component";
import { TractorService } from "./tractor/shared/tractor.service";
import { AddtractorComponent } from "./tractor/addtractor/addtractor.component";
// Driver services and components
import { DriverinfoComponent } from "./driver/driverinfo/driverinfo.component";
import { DriversListComponent } from "./driver/drivers-list/drivers-list.component";
import { DriversResolver } from "./driver/shared/driver.resolver";
import { DriversWithTeamsResolver } from "./driver/shared/driver-with-teams.resolver";
import { DriverService } from "./driver/shared/driver.service";
import { DriverValidationService } from "./driver/shared/driverValidation.service";
import { DriverHireDateService } from "./driver/shared/driverHireDate.service";

import { homeService } from "./home/home.service";
import { authenticationService } from "./login/auth.service";
import { AddDriverComponent } from "./driver/add-driver/add-driver.component";
// History Services and Components
import { LocationsService } from "./locations/shared/locations.service";
// client ListServices and Component
import { ClientlistComponent } from "./client/clientlist/clientlist.component";
import { clientService } from "./client/shared/client.service";
import { ClientinfoComponent } from "./client/clientinfo/clientinfo.component";
import { ClientprofileComponent } from "./client/clientprofile/clientprofile.component";
import { AddclientComponent } from "./client/addclient/addclient.component";
// lessor Services and Component
import { LessorlistComponent } from "./lessor/lessorlist/lessorlist.component";
import { LessorService } from "./lessor/shared/lessor.service";
import { LessorinfoComponent } from "./lessor/lessorinfo/lessorinfo.component";
// new product ListServices and Component
import { ProductsComponent } from "./product/products.component";
import { ProductService } from "./product/shared/products.service";
// new clientProduct Services and Components
import { ClientProductListComponent } from "./client/clientproductlist/clientproductlist.component";
import { ClientProductService } from "./client/shared/clientProduct.service";
// new equipment Services and Components
import { EquipmentComponent } from "./equipment/equipment-list/equipment.component";
import { EquipmentService } from "./equipment/shared/equipment.service";
// truck schedule ListServices and Component
import { AddscheduleComponent } from "./addschedule/addschedule.component";
import { ScheduleComponent } from "./schedule/schedule.component";
import { scheduleService } from "./schedule/schedule.service";
import { ScheduleinfoComponent } from "./scheduleinfo/scheduleinfo.component";
import { scheduleinfoService } from "./scheduleinfo/scheduleinfo.service";
// Accessorials
//import { AccessorialService } from "./accessorials/shared/accessorials.service";
import { BillAddressService } from "./billAddress/shared/billAddress.service";

import { PasswordModule } from "primeng/password";
import { CalendarModule } from "primeng/calendar";
import { InputSwitchModule } from "primeng/inputswitch";

import { RadioButtonModule } from "primeng/radiobutton";
import { CheckboxModule } from "primeng/checkbox";
import { AccordionModule } from "primeng/accordion";
import { InputTextareaModule } from "primeng/inputtextarea";
import { ToggleButtonModule } from "primeng/togglebutton";
import { PaginatorModule } from "primeng/paginator";
import { TableModule } from "primeng/table";
import { MultiSelectModule } from "primeng/multiselect";
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from "primeng/dropdown";
import { DatePipe } from "@angular/common";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmationService } from "primeng/api";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { ToolbarModule } from "primeng/toolbar";
import { InputNumberModule } from "primeng/inputnumber";
import { TooltipModule } from "primeng/tooltip";
import { ProgressSpinnerModule } from "primeng/progressspinner";

import { ProductSelectFormatResolver } from "./product/shared/productSelectFormat.resolver";
import { ProductsResolver } from "./product/shared/products.resolver";

import { DriversSelectFormatResolver } from "./driver/shared/driverSelectFormat.resolver";
import { TractorsSelectFormatResolver } from "./tractor/shared/tractorSelectFormat.resolver";
import { TractorsResolver } from "./tractor/shared/tractor.resolver";

import { TrailersSelectFormatResolver } from "./trailer/shared/trailerSelectFormat.resolver";
import { TrailersResolver } from "./trailer/shared/trailer.resolver";

import { ClientsResolver } from "./client/shared/client.resolver";
import { LessorsResolver } from "./lessor/shared/lessor.resolver";

import { PeoplenetTestComponent } from "./peoplenet-test/peoplenet-test.component";
import { PeoplenetTestService } from "./peoplenet-test/shared/peoplenet-test.service";

import { FuelComponent } from "./fuel/fuel.component";
import { FuelService } from "./fuel/fuel.service";

import { SettlementsService } from "./settlements/shared/settlements.service";
import { SettlementsHomeComponent } from "./settlements/settlementsHome/settlementsHome.component";
import { EscrowComponent } from "./settlements/escrow/escrow.component";
import { DeficitComponent } from "./settlements/deficit/deficit.component";
import { ColumnResolver } from "./settlements/shared/columns.resolver";

import { YesterdayLocationsComponent } from "./locations/yesterday-locations/yesterday-locations.component";
import { ShipperComponent } from "./shipper/shipper.component";
import { ShipperService } from "./shipper/shipper.service";
import { ShipperResolver } from "./shipper/shipper.resolver";
import { TChekComponent } from "./tcheck/tcheck.component";
import { TcheckService } from "./tcheck/tcheck.service";
import { LoadsComponent } from "./settlements/loads/loads.component";
import { AllLoadsComponent } from "./settlements/allloads/all-loads.component";
import { PeoplenetResolver } from "./peoplenet-test/shared/peoplenet.resolver";
import { BillToComponent } from "./client/bill-to/bill-to.component";
import { LocationListComponent } from "./locations/location-list/location-list.component";
import { LocationsComponent } from "./locations/locations.component";
import { LocationsConsigneesResolver } from "./locations/shared/locations-consignees.resolver";
import { LocationsShippersResolver } from "./locations/shared/locations-shippers.resolver";
import { LocationsBillToResolver } from "./locations/shared/locations-billTo.resolver";
import { DriverExpensesComponent } from "./settlements/driverExpenses/driverExpenses.component";
import { CompanyExpensesComponent } from "./settlements/companyExpenses/companyExpenses.component";
import { DriversNoTractorAssignedResolver } from "./driver/shared/driver-no-tractor-assigned.resolver";
import { TchekImportManagerComponent } from "./tchek-import-manager/tchek-import-manager.component";
import { BillToResolver } from "./client/shared/billTo.resolver";
import { ClientProductsResolver } from "./client/shared/clientProduct.resolver";
import { ParentsResolver } from "./client/shared/parent.resolver";
import { TrailersTypesResolver } from "./trailer/shared/trailerTypes.resolver";
import { ChargeTypesResolver } from "./chargeType/shared/chargetypes.resolver";
import { ChargeTypesIdResolver } from "./chargeType/shared/chargeTypesId.resolver";
import { GlobalChargeTypesResolver } from "./chargeType/shared/globalchargetypes.resolver";
import { EquipmentResolver } from "./equipment/shared/equipment.resolver";
import { SettlementsMenuComponent } from "./settlements/shared/menu/settlements-menu.component";
import { CurrencyPipe } from "@angular/common";
import { UtilitiesComponent } from "./utilities/utilities.component";
import { RolesComponent } from "./utilities/roles/roles.component";
import { AddUserComponent } from "./utilities/users/adduser/adduser.component";
import { AddRoleComponent } from "./utilities/roles/addrole/addrole.component";
import { RoleService } from "./utilities/roles/shared/role.service";
import { ListRolesComponent } from "./utilities/roles/listroles/listroles.component";
import { UserService } from "./utilities/users/shared/user.service";
import { ListusersComponent } from "./utilities/users/listusers/listusers.component";
import { ConfigResolver } from "./utilities/config/shared/config.resolver";
import { ConfigService } from "./utilities/config/shared/config.service";
import { ConfigComponent } from "./utilities/config/config.component";
import { RoleResolver } from "./utilities/roles/shared/role.resolver";
import { UserResolver } from "./utilities/users/shared/user.resolver";
import { EditroleComponent } from "./utilities/roles/editrole/editrole.component";
import { UpdateuserComponent } from "./utilities/users/updateuser/updateuser.component";
import { EditRatesComponent } from "./rates/edit-rates/edit-rates.component";
import { AddBillTosComponent } from "./rates/edit-rates/add-bill-tos/add-bill-tos.component";
import { BoundedTableComponent } from "./rates/edit-rates/bounded-table/bounded-table.component";
import { RatesListComponent } from "./rates/rateslist.component";
import { ClientRatesComponent } from "./rates/client-rates/client-rates.component";
import { DriverRatesComponent } from "./rates/driver-rates/driver-rates.component";
import { RatesService } from "./rates/shared/rates.service";
import { CreateRatesComponent } from "./rates/create-rates/create-rates.component";
import { EditClientRatesComponent } from "./rates/edit-rates/rate-types/edit-client-rates/edit-client-rates.component";
import { EditableTableComponent } from "./rates/edit-rates/editable-table/editable-table.component";
import { StatesPickerComponent } from "./rates/edit-rates/states-picker/states-picker.component";
import { EditRatesTableComponent } from "./client/bill-to/edit-rates-table/edit-rates-table.component";
import { AdjustByPercentButtonComponent } from "./rates/edit-rates/adjust-by-percent-button/adjust-by-percent-button.component";
import { FileUploadModule } from "primeng/fileupload";
import { GenericRatesComponent } from "./rates/generic-rates/generic-rates.component";
import { EditFuelSurchargesComponent } from "./rates/edit-rates/rate-types/edit-fuel-surcharges/edit-fuel-surcharges.component";
import { EditDriverFuelSurchargesComponent } from "./rates/edit-rates/rate-types/edit-driver-fuel-surcharges/edit-driver-fuel-surcharges.component";
import { EditInsuranceSurchargesComponent } from "./rates/edit-rates/rate-types/edit-insurance-surcharges/edit-insurance-surcharges.component";
import { EditLongevityComponent } from "./rates/edit-rates/rate-types/edit-longevity/edit-longevity.component";
import { RatesHeaderComponent } from "./rates/edit-rates/rates-header/rates-header.component";
import { EditCustomChargesComponent } from "./rates/edit-rates/rate-types/edit-custom-charges/edit-custom-charges.component";
import { ConditionEditorComponent } from "./rates/edit-rates/rate-types/edit-custom-charges/condition-editor/condition-editor.component";
import { ConditionDisplayNamePipe } from "./rates/edit-rates/rate-types/edit-custom-charges/condition-editor/condition-display-name.pipe";
import { ChargeTypesComponent } from "./chargeType/charge-types/charge-types.component";
import { EditChargeTypeComponent } from "./chargeType/edit-charge-type/edit-charge-type.component";
import { SendBatchEmailsComponent } from "./settlements/settlementsHome/send-batch-emails/send-batch-emails.component";
import { DriversSelectorComponent } from "./rates/edit-rates/drivers-selector/drivers-selector.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { FuelRegionsSelectorComponent } from "./rates/edit-rates/rate-types/edit-fuel-surcharges/fuel-regions-selector/fuel-regions-selector.component";
import { TeamslistComponent } from "./teams/teamslist/teamslist.component";
import { TeamComponent } from "./teams/team/team.component";
import { ClientWeeklyReportService } from "./clientPanel/weeklyReport/shared/clientWeeklyReport.service";
import { NewTripService } from "./trip-new/shared/trip.service";
import { EditDailyMinimumComponent } from "./rates/daily-minimum-rates/edit-daily-minimum/edit-daily-minimum.component";
import { AddMinimumDriverComponent } from "./rates/daily-minimum-rates/add-minimum-driver/add-minimum-driver.component";
import { DailyMinimumHistoryComponent } from "./rates/daily-minimum-rates/daily-minimum-history/daily-minimum-history.component";
import { AddMinimumClientComponent } from "./rates/daily-minimum-rates/add-minimum-client/add-minimum-client.component";
import { SettlementInvoiceComponent } from "./settlements/loads/settlement-invoice/settlement-invoice.component";
import { TeamHistoryComponent } from "./teams/team-history/team-history.component";
import { SettlementFuelTabComponent } from "./settlements/loads/settlement-fuel-tab/settlement-fuel-tab.component";
import { SettlementTeamsTabComponent } from "./settlements/loads/settlement-teams-tab/settlement-teams-tab.component";
import { TcheckImportModalComponent } from "./tcheck/tcheck-import-modal/tcheck-import-modal.component";
import { TchekHistoricalComponent } from "./tchek-historical/tchek-historical.component";
import { ImportDeductionsComponent } from "./settlements/settlementsHome/import-deductions/import-deductions.component";
import { DeductionsListComponent } from "./settlements/deductions-list/deductions-list.component";
import { DeductionComponent } from "./settlements/deduction/deduction.component";

import { InputMaskModule } from "primeng/inputmask";
import { InputMaskModule as NeatInputMaskModule } from "@ngneat/input-mask";
import { ResetComponent } from "./login/reset.component";
import { RaygunErrorHandler } from "../raygun";
import { EfsImportComponent } from "./efs/efs-import/efs-import.component";
import { ClientLaneRatesComponent } from "./rates/client-lane-rates/client-lane-rates.component";
import { DriverLaneRatesComponent } from "./rates/driver-lane-rates/driver-lane-rates.component";
import { EfsImportModalComponent } from "./efs/efs-import/efs-import-modal/efs-import-modal.component";
import { SyncedFuelRecordsComponent } from "./efs/efs-grids/synced-fuel-records/synced-fuel-records.component";
import { EfsGridsComponent } from "./efs/efs-grids/efs-grids.component";
import { SyncedMoneyCodesComponent } from "./efs/efs-grids/synced-money-codes/synced-money-codes.component";
import { EfsChargeTypesComponent } from "./efs/efs-charge-types/efs-charge-types.component";
import { EditChargeTypeMappingComponent } from "./efs/efs-charge-types/edit-charge-type-mapping/edit-charge-type-mapping.component";
import { EditableFuelRecordsTableComponent } from "./efs/efs-grids/editable-fuel-records-table/editable-fuel-records-table.component";
import { EfsBatchHistoryComponent } from "./efs/efs-batch-history/efs-batch-history.component";
import { EditableMoneyCodesTableComponent } from "./efs/efs-grids/editable-money-codes-table/editable-money-codes-table.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetComponent,
    HeaderComponent,
    SidebarComponent,
    HomeComponent,
    DriversListComponent,
    TractorlistComponent,
    TrailerlistComponent,
    DriverinfoComponent,
    TruckinfoComponent,
    TrailerinfoComponent,
    ClientlistComponent,
    ClientinfoComponent,
    LessorlistComponent,
    LessorinfoComponent,
    ProductsComponent,
    ClientProductListComponent,
    EquipmentComponent,
    ClientprofileComponent,
    AddscheduleComponent,
    ScheduleComponent,
    ScheduleinfoComponent,
    AddDriverComponent,
    AddtractorComponent,
    AddtrailerComponent,
    AddclientComponent,
    PeoplenetTestComponent,
    FuelComponent,
    SettlementsHomeComponent,
    EscrowComponent,
    DeficitComponent,
    YesterdayLocationsComponent,
    ShipperComponent,
    TChekComponent,
    EfsImportComponent,
    EditableFuelRecordsTableComponent,
    EditableMoneyCodesTableComponent,
    LoadsComponent,
    AllLoadsComponent,
    BillToComponent,
    LocationListComponent,
    LocationsComponent,
    DriverExpensesComponent,
    CompanyExpensesComponent,
    TchekImportManagerComponent,
    SettlementsMenuComponent,
    UtilitiesComponent,
    RolesComponent,
    AddUserComponent,
    AddRoleComponent,
    ListRolesComponent,
    ListusersComponent,
    ConfigComponent,
    EditroleComponent,
    UpdateuserComponent,
    RatesListComponent,
    ClientRatesComponent,
    DriverRatesComponent,
    ClientLaneRatesComponent,
    DriverLaneRatesComponent,
    EditRatesComponent,
    AddBillTosComponent,
    BoundedTableComponent,
    CreateRatesComponent,
    EditClientRatesComponent,
    EditableTableComponent,
    StatesPickerComponent,
    EditRatesTableComponent,
    AdjustByPercentButtonComponent,
    GenericRatesComponent,
    EditFuelSurchargesComponent,
    EditDriverFuelSurchargesComponent,
    EditInsuranceSurchargesComponent,
    EditLongevityComponent,
    RatesHeaderComponent,
    EditCustomChargesComponent,
    ConditionEditorComponent,
    ConditionDisplayNamePipe,
    ChargeTypesComponent,
    EditChargeTypeComponent,
    SendBatchEmailsComponent,
    DriversSelectorComponent,
    NotFoundComponent,
    FuelRegionsSelectorComponent,
    TeamslistComponent,
    TeamComponent,
    EditDailyMinimumComponent,
    AddMinimumDriverComponent,
    DailyMinimumHistoryComponent,
    AddMinimumClientComponent,
    SettlementInvoiceComponent,
    TeamHistoryComponent,
    SettlementFuelTabComponent,
    SettlementTeamsTabComponent,
    TcheckImportModalComponent,
    TchekHistoricalComponent,
    ImportDeductionsComponent,
    DeductionsListComponent,
    DeductionComponent,
    EfsImportModalComponent,
    EfsGridsComponent,
    SyncedFuelRecordsComponent,
    SyncedMoneyCodesComponent,
    EfsChargeTypesComponent,
    EditChargeTypeMappingComponent,
    EfsBatchHistoryComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    CommonModule,
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 8000,
    }),
    ReactiveFormsModule,
    NgxSpinnerModule,
    PasswordModule,
    CalendarModule,
    InputSwitchModule,
    InputMaskModule,
    NeatInputMaskModule.forRoot({ inputSelector: "input", isAsync: true }),
    RadioButtonModule,
    CheckboxModule,
    AccordionModule,
    InputTextareaModule,
    ToggleButtonModule,
    PaginatorModule,
    TableModule,
    MultiSelectModule,
    DialogModule,
    DropdownModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    ScrollPanelModule,
    ToolbarModule,
    InputNumberModule,
    TooltipModule,
    ProgressSpinnerModule,
    FileUploadModule,
  ],
  providers: [
    homeService,
    authenticationService,
    LocationsService,
    ProductService,
    ClientProductService,
    EquipmentService,
    scheduleService,
    scheduleinfoService,
    clientService,
    LessorService,
    DriverService,
    DriverValidationService,
    DriverHireDateService,
    TractorService,
    TrailerService,
    //AccessorialService,
    DriversResolver,
    DriversWithTeamsResolver,
    BillAddressService,
    ProductSelectFormatResolver,
    ProductsResolver,
    DriversSelectFormatResolver,
    TractorsSelectFormatResolver,
    TractorsResolver,
    TrailersSelectFormatResolver,
    TrailersResolver,
    ClientsResolver,
    LessorsResolver,
    PeoplenetTestService,
    FuelService,
    SettlementsService,
    ColumnResolver,
    ShipperService,
    ShipperResolver,
    TcheckService,
    PeoplenetResolver,
    LocationsConsigneesResolver,
    LocationsShippersResolver,
    LocationsBillToResolver,
    DriversNoTractorAssignedResolver,
    BillToResolver,
    ClientProductsResolver,
    ParentsResolver,
    TrailersTypesResolver,
    ChargeTypesResolver,
    ChargeTypesIdResolver,
    GlobalChargeTypesResolver,
    EquipmentResolver,
    CurrencyPipe,
    ConfigResolver,
    ConfigService,
    RoleService,
    UserService,
    RoleResolver,
    UserResolver,
    RatesService,
    DatePipe,
    ConfirmationService,
    ClientWeeklyReportService,
    NewTripService,
    {
      provide: ErrorHandler,
      useClass: RaygunErrorHandler,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
