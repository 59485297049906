import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EfsChargeTypeMapping } from "@app/shared/models/fuel/EfsChargeTypeMapping.model";
import { FuelRecordGridModel } from "@app/shared/models/fuel/FuelRecordGridModel.model";
import { MoneyCodeGridModel } from "@app/shared/models/fuel/MoneyCodeGridModel";
import { catchError, map, throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EfsService {
  constructor(private _http: HttpClient) {}

  //#region Charge Types
  getChargeTypeMappings() {
    return this._http
      .get("/api/fuel/chargeTypeMappings")
      .pipe(map((res: EfsChargeTypeMapping[]) => res));
  }

  addChargeTypeMapping(mapping: EfsChargeTypeMapping) {
    return this._http
      .post("/api/fuel/addChargeTypeMapping", mapping)
      .pipe(map((res: EfsChargeTypeMapping) => res));
  }

  editChargeTypeMapping(mapping: EfsChargeTypeMapping) {
    return this._http
      .patch(`/api/fuel/editChargeTypeMapping/`, mapping)
      .pipe(map((res: EfsChargeTypeMapping) => res));
  }

  deleteChargeTypeMapping(id: string) {
    return this._http.delete(`/api/fuel/deleteChargeTypeMapping/${id}`).pipe(map((res) => res));
  }

  restoreChargeTypeMapping(mapping: EfsChargeTypeMapping) {
    return this._http.patch(`/api/fuel/restoreChargeTypeMapping`, mapping).pipe(map((res) => res));
  }
  //#endregion

  //#region Synced Records
  getSyncedFuelRecords(
    filter = {},
    pageSize = 20,
    pageOffset = 0,
    sortField: string,
    sortOrder: number
  ) {
    return this._http
      .post("api/fuel/getAllTransactions", {
        filter,
        pageSize,
        pageOffset,
        sortField,
        sortOrder,
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => throwError(error.message || "Server error"))
      );
  }

  getSyncedMoneyCodes(
    filter = {},
    pageSize = 20,
    pageOffset = 0,
    sortField: string,
    sortOrder: number
  ) {
    return this._http
      .post("api/fuel/getAllMoneyCodes", {
        filter,
        pageSize,
        pageOffset,
        sortField,
        sortOrder,
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => throwError(error.message || "Server error"))
      );
  }

  downloadSyncedFuelRecords(filter = {}, sortField: string, sortOrder: number) {
    return this._http
      .post(
        "api/fuel/downloadFuelRecords",
        {
          filter,
          sortField,
          sortOrder,
        },
        {
          responseType: "blob",
          headers: {
            Accept: "text/csv",
            "Content-Type": "application/json",
          },
        }
      )
      .pipe(
        map((response: Blob) => {
          return new Blob([response], { type: "text/csv" });
        })
      );
  }

  downloadSyncedMoneyCodes(filter = {}, sortField: string, sortOrder: number) {
    return this._http
      .post(
        "api/fuel/downloadMoneyCodes",
        {
          filter,
          sortField,
          sortOrder,
        },
        {
          responseType: "blob",
          headers: {
            Accept: "text/csv",
            "Content-Type": "application/json",
          },
        }
      )
      .pipe(
        map((response: Blob) => {
          return new Blob([response], { type: "text/csv" });
        })
      );
  }

  getLastSyncDate() {
    return this._http.get("/api/fuel/lastSyncDate").pipe(map((res) => res));
  }

  sync() {
    return this._http.post("/api/fuel/downloadTransactions", {}).pipe(map((res) => res));
  }

  syncWithEfs(endDate: Date) {
    // set the endDate to the end of the day
    endDate.setHours(23, 59, 59, 999);
    return this._http.post("/api/fuel/importFromEfs", { endDate }).pipe(map((res) => res));
  }
  //#endregion

  finalizeImport(records: FuelRecordGridModel[], moneyCodes: MoneyCodeGridModel[]) {
    return this._http
      .post("/api/fuel/finalizeImport", { records, moneyCodes })
      .pipe(map((res) => res));
  }

  deleteMoneyCode(id: string) {
    return this._http.delete(`/api/fuel/deleteMoneyCode/${id}`).pipe(map((res) => res));
  }

  getFuelRecordsForBatch(batchId: string) {
    return this._http.get(`/api/fuel/getFuelRecordsForBatch/${batchId}`).pipe(map((res: any) => res));
  }

  getMoneyCodesForBatch(batchId: string) {
    return this._http.get(`/api/fuel/getMoneyCodesForBatch/${batchId}`).pipe(map((res) => res));
  }

  updateFuelRecordsForBatch(batchId: string, records: FuelRecordGridModel[]) {
    return this._http
      .post(`/api/fuel/updateFuelRecordsForBatch/${batchId}`, records)
      .pipe(map((res) => res));
  }

  updateMoneyCodesForBatch(batchId: string, moneyCodes: MoneyCodeGridModel[]) {
    return this._http
      .post(`/api/fuel/updateMoneyCodesForBatch/${batchId}`, moneyCodes)
      .pipe(map((res) => res));
  }
}
