import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-bounded-table",
  templateUrl: "./bounded-table.component.html",
  styleUrls: ["./bounded-table.component.css"],
})
export class BoundedTableComponent implements OnInit, OnChanges {
  @Input() rows: any[];
  @Input() firstColumn: string;
  @Input() floor: string = "floor";
  @Input() roof: string = "roof";
  @Input() columns: { field: string; name: string; type: string }[];
  @Input() lastInputColumnField: string;
  @Input() noUpperBound: boolean = false;

  // Optional inputs
  @Input() defaultRange: number = 100;
  @Input() defaultPrice: number = 0;
  @Input() minValue: number = 0;
  @Input() isEditing: boolean = true;
  @Input() firstColumnType: string = "";
  @Input() decimals: number = 0;
  @Input() increment: number = 1;

  @Output() rowsChange = new EventEmitter();
  @Output() maxChanged = new EventEmitter();

  maxRoof = 1;

  constructor() {}

  ngOnInit() {
    if (this.noUpperBound) {
      if (this.rows.length == 0) {
        this.addRow(true);
      }

      const lastRow = this.rows[this.rows.length - 1];
      if (lastRow[this.roof] != null) {
        this.addRow(true);
      }
    }
    for (let i = 0; i < this.rows.length; i++) {
      this.updateIntervalPrice(this.rows[i]);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.increment) {
      this.fixRanges();
    }
  }

  updateRowFloor(rowIndex: number) {
    // const row = this.rows[rowIndex];
    // const floor = row[this.floor];

    // if (rowIndex > 0) {
    //   const prevRowFloor = this.rows[rowIndex - 1][this.floor];

    //   // if we're trying to pass multiple rows
    //   if (prevRowFloor > floor) {
    //     this.rows[rowIndex][this.floor] = prevRowFloor;
    //     this.rows[rowIndex - 1][this.roof] = prevRowFloor - this.increment;
    //   } else {
    //     this.rows[rowIndex - 1][this.roof] = floor - this.increment;
    //   }
    // }
    this.updatedRows();
  }

  updateRowCeiling(rowIndex: number) {
    // const row = this.rows[rowIndex];
    // const roof = row[this.roof];

    // if (rowIndex < this.rows.length - 1) {
    //   const nextRowRoof = this.rows[rowIndex + 1][this.roof];

    //   if (nextRowRoof != null && roof > nextRowRoof) {
    //     this.rows[rowIndex][this.roof] = nextRowRoof;
    //     this.rows[rowIndex + 1][this.floor] = nextRowRoof + this.increment;
    //   } else {
    //     this.rows[rowIndex + 1][this.floor] = roof + this.increment;
    //   }
    // } else {
    //   // we updated the roof of the last row
    //   this.maxChanged.emit(roof);
    // }
    this.updatedRows();
  }

  getLastBoundedRow() {
    if (!this.noUpperBound) {
      if (this.rows.length == 0) return null;
      return this.rows[this.rows.length - 1];
    } else {
      if (this.rows.length == 1) return null;
      return this.rows[this.rows.length - 2];
    }
  }

  hasUnboundedRow() {
    if (this.rows.length == 0) return false;
    const lastRow = this.rows[this.rows.length - 1];
    if (!lastRow[this.roof]) return true;
    return false;
  }

  addRow(isUnboundedRow: boolean = false) {
    // Insert a new row, using the roof of the last row as the floor
    const lastRow = this.getLastBoundedRow();
    let floor = lastRow ? lastRow[this.roof] + this.increment : this.minValue;

    let newRow = {};

    if (isUnboundedRow) {
      newRow[this.floor] = floor;
      newRow[this.roof] = null;
    } else {
      newRow[this.floor] = floor;
      newRow[this.roof] = floor + this.defaultRange;
    }

    newRow["values"] = {};
    for (let col of this.columns) {
      newRow["values"][col.field] = this.defaultPrice;
    }

    // If this has an upper bound, and this isn't the unbounded row
    // Then we push to the second to last row. Otherwise, we push
    // to the last row
    if (!this.noUpperBound) this.rows.push(newRow);
    else {
      if (isUnboundedRow) this.rows.push(newRow);
      else this.rows.splice(this.rows.length - 1, 0, newRow);

      if (this.rows.length >= 2) this.updateRowCeiling(this.rows.length - 2);
    }

    this.onRowValueUpdated(this.rows.length - 1);
    this.updatedRows();
  }

  deleteRow(rowIndex: number) {
    const row = this.rows[rowIndex];
    const roof = row[this.roof];

    // update the previous row
    if (rowIndex === this.rows.length - 1) {
      // don't update
    } else if (rowIndex > 0) {
      this.rows[rowIndex - 1][this.roof] = roof;
    } else {
      this.rows[1][this.floor] = 0;
    }

    this.rows.splice(rowIndex, 1);

    this.updatedRows();
  }

  getMaxRoof() {
    return this.rows.reduce((p, c) => (p[this.roof] > c[this.roof] ? p : c));
  }

  updatedRows() {
    // round all floor/roof values to the nearest increment decimal
    const roundValue = 1 / this.increment;
    for (let row of this.rows) {
      row[this.floor] = Math.round(row[this.floor] * roundValue) / roundValue;
      row[this.roof] = Math.round(row[this.roof] * roundValue) / roundValue;
    }
    this.rowsChange.emit(this.rows);
    this.sortByUpperBound();
  }

  onRowValueUpdated(rowIndex: number) {
    const row = this.rows[rowIndex];

    // If we have a number of decimals to always round to
    if (this.decimals) {
      for (let [key, value] of Object.entries(row["values"])) {
        row["values"][key] =
          Math.round(Number(value) * Math.pow(10, this.decimals)) / Math.pow(10, this.decimals);
      }
    }

    // This only needs to be done if there's an interval price
    // but there's no harm in running it all the time
    this.updateIntervalPrice(row);
    
    //sort rows in case user trying to add row in middle of grid
    this.sortByUpperBound();
  }

  updateIntervalPrice(row: any) {
    const { pricePerHour, timeInterval } = row["values"];
    if (pricePerHour === undefined || timeInterval === undefined) {
      this.updatedRows();
      return;
    }

    const intervalPrice = pricePerHour / (60 / timeInterval);

    row["values"]["intervalPrice"] = intervalPrice.toFixed(2);
    this.updatedRows();
  }

  onTab(field: any, rowIndex: any) {
    if (field === this.lastInputColumnField && this.rows.length - 1 === rowIndex) {
      this.addRow();
    } else if (
      this.lastInputColumnField === undefined &&
      field === this.columns[this.columns.length - 1].field &&
      this.rows.length - 1 === rowIndex
    ) {
      this.addRow();
    }
  }

  fixRanges() {
    let previousRoof = -1;
    for (let row of this.rows) {
      if (row[this.floor] === undefined) return false;
      if (row[this.roof] === undefined) return false;
      const roundValue = 1 / this.increment;
      let targetValue = previousRoof + this.increment;
      targetValue = Math.round(targetValue * roundValue) / roundValue;
      if (previousRoof >= 0) {
        row[this.floor] = targetValue;
      }
      previousRoof = row[this.roof];
    }
    return true;
  }

  validateRanges() {
    let previousRoof = -1;
    for (let row of this.rows) {
      if (row[this.floor] === undefined) return false;
      if (row[this.roof] === undefined) return false;
      // The first row floor can be anything
      // (This for delay charges, where the floor is not 0)
      const roundValue = 1 / this.increment;
      let targetValue = previousRoof + this.increment;
      targetValue = Math.round(targetValue * roundValue) / roundValue;
      if (previousRoof >= 0 && row[this.floor] !== targetValue) return false;
      previousRoof = row[this.roof];
    }
    return true;
  }

  sortByUpperBound() {
    this.rows.sort((a, b) => a[this.roof] - b[this.roof]);
  }
}
