<div class="container">
  <div class="col-md-12">
    <legend>Create New Driver</legend>
    <div class="row">
      <div class="col-md-9">
        <div class="col-md-6">
          <div class="form-group">
            <label
              *ngIf="driver.type == 'LEASE OPERATOR'"
              class="col-md-6 control-label"
              for="radios"
              >Lessor ID</label
            >
            <label
              *ngIf="driver.type == 'COMPANY DRIVER'"
              class="col-md-6 control-label"
              for="radios"
              >Driver ID</label
            >
            <label
              *ngIf="driver.type == 'OWNER OPERATOR'"
              class="col-md-6 control-label"
              for="radios"
              >Owner/Operator ID</label
            >
            <div class="col-md-6 inputSpace">
              <input
                id="driverNo"
                required
                name="driverNo"
                type="text"
                placeholder="Insert COMPANY DRIVER"
                class="form-control"
                [(ngModel)]="driver.driverNo"
                [disabled]="!driver.name && !driver.lastName"
                maxlength="16"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="radios">PeopleNet Id</label>
            <div class="col-md-6 inputSpace">
              <input
                id="peopleNetId"
                tabindex="1"
                name="peopleNetId"
                type="text"
                placeholder="Insert People Net Id"
                [(ngModel)]="driver.peopleNetId"
                class="form-control input-md"
                maxlength="7"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="radios">Termination Date</label>
            <div class="col-md-6 inputSpace">
              <input
                id="terminationDate"
                name="terminationDate"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                class="form-control input-md"
                [tabIndex]="3"
                [(ngModel)]="driver.terminationDate"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="company">Type</label>
            <div class="col-md-6 inputSpace">
              <select
                class="form-control"
                type="text"
                id="driverType"
                tabindex="4"
                name="driverType"
                [(ngModel)]="driver.type"
                (change)="driverTypeChanged(driver.type)"
              >
                <option value="COMPANY DRIVER" selected>COMPANY DRIVER</option>
                <option value="OWNER OPERATOR">OWNER OPERATOR</option>
                <option value="LEASE OPERATOR">Lessor</option>
              </select>
            </div>
            <br />
            <div *ngIf="driver.type == 'LEASE OPERATOR'">
              <label class="col-md-6 control-label" for="company">Select lessor</label>
              <div class="col-md-6 inputSpace">
                <select
                  class="form-control"
                  tabindex="6"
                  type="text"
                  id="driverType"
                  name="driverType"
                  [(ngModel)]="driver.lessorId"
                >
                  <option [ngValue]="null">--Select lessor --</option>
                  <option
                    *ngFor="let lessorOwner of lessorOwners; let i = index"
                    [ngValue]="lessorOwner.id"
                  >
                    {{ lessorOwner.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-md-6 control-label" for="isActive">Active</label>
            <div class="col-md-6 inputSpace">
              <p-checkbox
                id="isActive"
                name="isActive"
                [(ngModel)]="driver.isActive"
                binary="true"
              ></p-checkbox>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="radios">Termination Type</label>
            <div class="col-md-6 inputSpace">
              <input
                id="terminationType"
                name="terminationType"
                tabindex="2"
                type="text"
                placeholder="Insert termination Type"
                [(ngModel)]="driver.terminationType"
                class="form-control input-md"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="rehire">Eligible Rehire</label>
            <div class="col-md-6 inputSpace">
              <p-checkbox
                id="rehire"
                name="rehire"
                checked
                [(ngModel)]="driver.rehire"
                binary="true"
              ></p-checkbox>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="radios">EFS Effective Date</label>
            <div class="col-md-6 inputSpace">
              <input
                id="efsEffectiveDate"
                name="efsEffectiveDate"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                class="form-control input-md"
                [tabIndex]="5"
                [(ngModel)]="driver.efsEffectiveDate"
              />
            </div>
          </div>
        </div>
        <br />
        <hr />
        <!--start par numbers-->
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-md-6 control-label" for="firstName">First Name</label>
            <div class="col-md-6 inputSpace">
              <input
                id="firstName"
                name="firstName"
                type="text"
                tabindex="7"
                placeholder="Insert new driver name"
                class="form-control input-md"
                (change)="updatedDriverNo()"
                [(ngModel)]="driver.name"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="radios">Middle Name </label>
            <div class="col-md-6 inputSpace">
              <input
                id="StartDate"
                name="StartDate"
                tabindex="9"
                type="text"
                placeholder="Insert Middle Name"
                class="form-control"
                [(ngModel)]="driver.middleName"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="company">Cellphone</label>
            <div class="col-md-6 inputSpace">
              <input
                id="cellphone"
                name="cellphone"
                tabindex="11"
                type="text"
                placeholder="(XXX) XXX-XXXX"
                class="form-control input-md"
                [(ngModel)]="driver.cellphone"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="emergencyContName"
              >Emergency Cont Name</label
            >
            <div class="col-md-6 inputSpace">
              <input
                id="emergencyContName"
                name="emergencyContName"
                tabindex="13"
                type="text"
                placeholder="Insert emergency contact name"
                class="form-control input-md"
                [(ngModel)]="driver.emergencyContName"
              />
            </div>
          </div>
          <br />
          <div class="form-group">
            <label class="col-md-6 control-label" for="homeAddress">Home Address</label>
            <div class="col-md-6 inputSpace">
              <input
                id="homeAddress"
                name="homeAddress"
                tabindex="15"
                type="text"
                placeholder="Insert home address"
                class="form-control input-md"
                [(ngModel)]="driver.homeAddress"
              />
            </div>
          </div>
          <br />
          <div class="form-group">
            <label class="col-md-6 control-label" for="state">State</label>
            <div class="col-md-6 inputSpace">
              <select
                class="selectState"
                type="text"
                tabindex="17"
                id="state"
                name="state"
                [(ngModel)]="driver.state"
              >
                <option *ngFor="let state of states" [ngValue]="state.abbreviation">
                  {{ state.fullName }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-md-6 control-label" for="lastName">Last Name</label>
            <div class="col-md-6 inputSpace">
              <input
                id="lastName"
                name="lastName"
                type="text"
                tabindex="8"
                placeholder="Insert Last Name"
                (change)="updatedDriverNo()"
                [(ngModel)]="driver.lastName"
                class="form-control input-md"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="email">Email</label>
            <div class="col-md-6 inputSpace">
              <input
                id="email"
                name="email"
                type="email"
                tabindex="10"
                placeholder="email"
                class="form-control input-md"
                [(ngModel)]="driver.email"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="settlementEmail">Settlement Email</label>
            <div class="col-md-6 inputSpace">
              <input
                id="settlementEmail"
                name="settlementEmail"
                tabindex="12"
                type="email"
                placeholder="Settlement Email"
                class="form-control input-md"
                [(ngModel)]="driver.settlementEmail"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="company">Phone</label>
            <div class="col-md-6 inputSpace">
              <input
                id="phone"
                name="phone"
                type="text"
                tabindex="14"
                placeholder="(XXX) XXX-XXXX"
                class="form-control input-md"
                [(ngModel)]="driver.phone"
              />
            </div>
          </div>
          <br />
          <div class="form-group">
            <label class="col-md-6 control-label" for="emergencyContPhone"
              >Emergency Cont Phone</label
            >
            <div class="col-md-6 inputSpace">
              <input
                id="emergencyContPhone"
                tabindex="16"
                name="emergencyContPhone"
                type="text"
                placeholder="(XXX) XXX-XXXX"
                class="form-control input-md"
                [(ngModel)]="driver.emergencyContPhone"
              />
            </div>
          </div>
          <br />
          <div class="form-group">
            <label class="col-md-6 control-label" for="city">City</label>
            <div class="col-md-6 inputSpace">
              <input
                id="city"
                name="city"
                type="text"
                tabindex="18"
                placeholder="Insert city"
                class="form-control input-md"
                [(ngModel)]="driver.city"
              />
            </div>
          </div>
          <br />
          <div class="form-group">
            <label class="col-md-6 control-label" for="zipCode">Zip Code</label>
            <div class="col-md-6 inputSpace">
              <input
                id="zipCode"
                name="zipCode"
                type="text"
                tabindex="19"
                placeholder="Insert zip code"
                class="form-control input-md"
                [(ngModel)]="driver.zipCode"
              />
            </div>
          </div>
        </div>
        <br />
        <hr />
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-md-6 control-label" for="radios">Birth Date </label>
            <div class="col-md-6 inputSpace">
              <input
                id="birthDate"
                name="birthDate"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                class="form-control input-md"
                [tabIndex]="20"
                [(ngModel)]="driver.birthDate"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="taxId">Tax ID</label>
            <div class="col-md-6 inputSpace">
              <input
                id="taxId"
                name="taxId"
                type="text"
                tabindex="22"
                placeholder="Insert tax Id"
                class="form-control input-md"
                [(ngModel)]="driver.taxId"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="company">License Number </label>
            <div class="col-md-6 inputSpace">
              <input
                id="licenseNumber"
                name="licenseNumber"
                tabindex="24"
                type="text"
                placeholder="Insert license number"
                class="form-control input-md"
                [(ngModel)]="driver.licenseNumber"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="radios">License State</label>
            <div class="col-md-6 inputSpace">
              <select
                class="selectState"
                id="licenseState"
                tabindex="26"
                name="licenseState"
                [(ngModel)]="driver.licenseState"
              >
                <option *ngFor="let state of states" [ngValue]="state.abbreviation">
                  {{ state.fullName }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="radios">License Expiration</label>
            <div class="col-md-6 inputSpace">
              <input
                id="licenseExpirationDate"
                name="licenseExpirationDate"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                class="form-control input-md"
                [tabIndex]="28"
                [(ngModel)]="driver.licenseExpirationDate"
              />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-md-6 control-label" for="socialS">SSN</label>
            <div class="col-md-6 inputSpace">
              <p-inputMask
                id="socialSecurityNumber"
                tabindex="21"
                name="socialSecurityNumber"
                type="text"
                mask="999-99-9999"
                placeholder="XXX-XX-XXXX"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [(ngModel)]="driver.socialSecurityNumber"
              />
            </div>
          </div>
          <br />
          <div class="form-group">
            <label class="col-md-6 control-label" for="physicalExamDate">Physical Exam Date</label>
            <div class="col-md-6 inputSpace">
              <input
                id="physicalExamDate"
                name="physicalExamDate"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                class="form-control input-md"
                [tabIndex]="23"
                [(ngModel)]="driver.physicalExamDate"
              />
            </div>
          </div>
          <br />
          <div class="form-group">
            <label class="col-md-6 control-label" for="physicalExamExpirationDate"
              >Physical Exam Expiration</label
            >
            <div class="col-md-6 inputSpace">
              <input
                id="physicalExamExpirationDate"
                name="physicalExamExpirationDate"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                class="form-control input-md"
                [tabIndex]="25"
                [(ngModel)]="driver.physicalExamExpirationDate"
              />
            </div>
          </div>
          <br />
          <div class="form-group">
            <label class="col-md-6 control-label" for="hmeExpirationDate">HME Expiration</label>
            <div class="col-md-6 inputSpace">
              <input
                id="hmeExpirationDate"
                name="hmeExpirationDate"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                class="form-control input-md"
                [tabIndex]="27"
                [(ngModel)]="driver.hmeExpirationDate"
              />
            </div>
          </div>
          <br />
          <div class="form-group">
            <label class="col-md-6 control-label" for="passportExpirationDate"
              >Passport Expiration</label
            >
            <div class="col-md-6 inputSpace">
              <input
                id="passportExpirationDate"
                name="passportExpirationDate"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                class="form-control input-md"
                [tabIndex]="29"
                [(ngModel)]="driver.passportExpirationDate"
              />
            </div>
          </div>
          <br />
          <div class="form-group">
            <label class="col-md-6 control-label" for="hiredDate">Hire Dates</label>
            <div class="col-md-6">
              <div *ngFor="let item of driverHireDateCounter(); let i = index">
                <div style="display: flex" [style.margin-top]="i > 0 ? '5px' : '0'">
                  <div style="flex: 1; min-width: 43%; width: 43%">
                    <input
                      *ngIf="i == 0"
                      id="HireDateStart0"
                      name="HireDateStart0"
                      type="text"
                      styleClass="driver-mask-class"
                      inputStyleClass="driver-mask-class"
                      [inputMask]="dateInputMask"
                      autocomplete="off"
                      placeholder="mm/dd/yyyy"
                      aria-label="HireDateStart0"
                      class="form-control input-md"
                      [tabIndex]="30"
                      [(ngModel)]="driver.hiredDate"
                    />

                    <input
                      *ngIf="i > 0"
                      id="hiredDate"
                      name="hiredDate"
                      type="text"
                      styleClass="driver-mask-class"
                      inputStyleClass="driver-mask-class"
                      [inputMask]="dateInputMask"
                      autocomplete="off"
                      placeholder="mm/dd/yyyy"
                      aria-label="HireDateStart"
                      class="form-control input-md"
                      [tabIndex]="32"
                      [(ngModel)]="driver.driverHireDates[i - 1].hiredDate"
                    />
                  </div>
                  <div style="display: flex">
                    <label style="flex: 1">-</label>
                    <div style="display: flex">
                      <div style="flex: 1">
                        <input
                          *ngIf="i == 0"
                          id="HireDateEnd0"
                          name="HireDateEnd0"
                          type="text"
                          styleClass="driver-mask-class"
                          inputStyleClass="driver-mask-class"
                          [inputMask]="dateInputMask"
                          autocomplete="off"
                          placeholder="mm/dd/yyyy"
                          aria-label="HireDateEnd0"
                          class="form-control input-md"
                          [tabIndex]="31"
                          [(ngModel)]="driver.hiredDateTo"
                        />

                        <input
                          *ngIf="i > 0"
                          id="HireDateEnd"
                          name="HireDateEnd"
                          type="text"
                          styleClass="driver-mask-class"
                          inputStyleClass="driver-mask-class"
                          [inputMask]="dateInputMask"
                          autocomplete="off"
                          placeholder="mm/dd/yyyy"
                          aria-label="HireDateEnd0"
                          class="form-control input-md"
                          [tabIndex]="33"
                          [(ngModel)]="driver.driverHireDates[i - 1].hiredDateTo"
                        />
                      </div>
                      <button
                        *ngIf="i == 0"
                        class="button-form-control"
                        (click)="addDriverHireDate()"
                      >
                        +
                      </button>
                      <button
                        *ngIf="i > 0"
                        class="button-form-control"
                        (click)="removeDriverHireDate(i)"
                      >
                        -
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <hr />
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-md-6 control-label" for="annualReviewDate"
              >Last Annual Review Date</label
            >
            <div class="col-md-6 inputSpace">
              <input
                id="annualReviewDate"
                name="annualReviewDate"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                class="form-control input-md"
                [tabIndex]="34"
                [(ngModel)]="driver.annualReviewDate"
              />
            </div>
          </div>
          <br />
          <div class="form-group">
            <label class="col-md-6 control-label" for="trainingDateCGMP">CGMP Training Date</label>
            <div class="col-md-6 inputSpace">
              <input
                id="trainingDateCGMP"
                name="trainingDateCGMP"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                class="form-control input-md"
                (ngModelChange)="calculateTrainingDateCGMPExpirationDate(driver.trainingDateCGMP)"
                [tabIndex]="36"
                [(ngModel)]="driver.trainingDateCGMP"
              />
            </div>
          </div>
          <br />
          <div class="form-group">
            <label class="col-md-6 control-label" for="expirationDateCGMP"
              >CGMP Expiration Date</label
            >
            <div class="col-md-6 inputSpace">
              <input
                id="expirationDateCGMP"
                name="expirationDateCGMP"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                class="form-control input-md"
                [tabIndex]="38"
                [(ngModel)]="driver.expirationDateCGMP"
              />
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label class="col-md-6 control-label" for="hazmatTrainingDate"
              >Hazmat Training Date</label
            >
            <div class="col-md-6 inputSpace">
              <input
                id="hazmatTrainingDate"
                name="hazmatTrainingDate"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                class="form-control input-md"
                [tabIndex]="35"
                (ngModelChange)="calculatehazmatTrainingExpirationDate(driver.hazmatTrainingDate)"
                [(ngModel)]="driver.hazmatTrainingDate"
              />
            </div>
          </div>
          <br />
          <div class="form-group">
            <label class="col-md-6 control-label" for="hazmatTrainingExpirationDate"
              >Hazmat Training Expire Date</label
            >
            <div class="col-md-6 inputSpace">
              <input
                id="hazmatTrainingExpirationDate"
                name="hazmatTrainingExpirationDate"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                class="form-control input-md"
                [tabIndex]="37"
                [(ngModel)]="driver.hazmatTrainingExpirationDate"
              />
            </div>
          </div>
          <br />
          <div class="form-group">
            <label for="client-list" class="col-md-6">Team</label>
            <div class="col-md-6 inputSpace">
              <select
                id="client-list"
                id="team"
                name="team"
                tabindex="39"
                [(ngModel)]="driverSelected"
                (change)="teamSelected(driver)"
                class="selectState"
                type="text"
              >
                <option [ngValue]="'-1'" selected>--No Team--</option>
                <option *ngFor="let item of drivers" [ngValue]="item">
                  {{ item.lastName }} {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group col-md-12" *ngIf="driver.team && driver.team.drivers">
            <table style="width: 100%">
              <tr>
                <th><label>Team Members</label></th>
                <th><label>Is Pay To</label></th>
                <th><label>Percent</label></th>
              </tr>
              <tr *ngFor="let teamDriver of driver.team.drivers">
                <td>{{ teamDriver.name }} {{ teamDriver.lastName }}</td>
                <td>
                  <p-checkbox
                    *ngIf="teamDriver.id === driver.id"
                    [(ngModel)]="driver.isPayTo"
                    binary="true"
                    [disabled]="driver.isPayTo"
                    (onChange)="setDriverPayTo(driver)"
                  ></p-checkbox>
                  <p-checkbox
                    *ngIf="teamDriver.id !== driver.id"
                    [(ngModel)]="teamDriver.isPayTo"
                    binary="true"
                    [disabled]="teamDriver.isPayTo"
                    (onChange)="setDriverPayTo(teamDriver)"
                  ></p-checkbox>
                </td>
                <td style="display: flex; align-items: center; width: 100px">
                  <input
                    [(ngModel)]="teamDriver.earningPercentage"
                    (change)="earningPercentageChanged(teamDriver)"
                    type="number"
                    min="0"
                    class="form-control"
                  />
                  <span style="margin-left: 0.25rem">%</span>
                </td>
              </tr>
            </table>
            <div>
              <button (click)="removeFromTeam()">Leave The Team</button>
            </div>
          </div>
          <div class="form-group" *ngIf="!driver.team || !driver.team.drivers">
            <label for="payTo" class="col-md-6">Is Pay To</label>
            <div class="col-md-6 inputSpace">
              <p-checkbox [(ngModel)]="driver.isPayTo" binary="true" [disabled]="true"></p-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" align="center">
        <div class="col-md-12">
          <img
            class="img-responsive"
            [src]="previewProfilePicture"
            [appGetFile]="previewProfilePicture || 'assets/img/tractordriver.jpg'"
            [appPreviewFile]="true"
          />
        </div>
        <div class="form-group btnUploadPicture col-md-12">
          <label class="col-md-4 control-label">Upload Picture</label>
          <div class="col-md-8">
            <input
              type="file"
              class="form-control-file inpFile"
              id="uploadfile"
              #profilePicture
              (change)="getTemporalImage($event)"
            />
          </div>
        </div>
        <!-- Document input-->
        <div class="form-group col-md-12">
          <label class="col-md-4 control-label">Documents</label>
          <createDocument #driverDocuments></createDocument>
        </div>
        <div></div>
      </div>
    </div>
    <div class="row" class="bottom-btns">
      <div class="col-sm-6" align="right">
        <button
          type="button"
          class="btn btn-warning createBtn"
          type="submit"
          [disabled]="isProcessing"
          (click)="confirmationModal()"
        >
          Save Profile
        </button>
      </div>
      <br />
    </div>
  </div>
</div>
<button
  type="button"
  style="display: none"
  class="btn btn-primary"
  id="openModal"
  data-toggle="modal"
  data-target="#modalConfirm"
></button>

<!-- Modal -->
<div
  class="modal fade"
  id="modalConfirm"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h5 class="modal-title" id="exampleModalLabel">
          Are you sure you want to create this driver?
        </h5>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="createNewDriver()"
        >
          Save
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '50vw' }"
></p-confirmDialog>
