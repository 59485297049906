import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { SettlementsService } from "../shared/settlements.service";
import { ToastrService } from "ngx-toastr";
import { SelectItem } from "primeng/api";
import { TripCharge } from "@app/shared/models/trips/TripCharge.model";
import { RateCalculatorService } from "@app/trip-new/shared/rate-calculator.service";
//import { AccessorialService } from "../../accessorials/shared/accessorials.service";
@Component({
  selector: "app-allloads",
  templateUrl: "./all-loads.component.html",
  styleUrls: ["./all-loads.component.css"],
})
export class AllLoadsComponent implements OnInit {
  cols: any[];
  colsCharge: { field: string; header: string; width: number }[];
  driverChargeTypes: SelectItem[] = [];

  batchId: string;
  trips: any = [];
  batchInfo: any;
  totalEarnings = 0;

  constructor(
    private settlementsService: SettlementsService,
    private activatedRoute: ActivatedRoute,
    private rateCalculaterService: RateCalculatorService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.cols = [
      { field: "tripNo", header: "Trip No", width: 100 },
      { field: "driverName", header: "Driver", width: 230 },
      { field: "startDate", header: "Start Date", type: "date" },
      { field: "endDate", header: "End Date", type: "date" },
      { field: "netNonTripExpense", header: "Total", type: "currency", width: 100 },
    ];

    this.colsCharge = [
      { field: "chargeType?.description", header: "Type", width: 250},
      { field: "description", header: "Description", width: 250 },
      { field: "units", header: "Units", width: 50 },
      { field: "miles", header: "Miles", width: 50 },
      { field: "chargeRate", header: "Rate", width: 100 },
      { field: "price", header: "Total", width: 100 },
    ];

    this.activatedRoute.params.subscribe((params: Params) => {
      this.batchId = params["batchId"];
      this.getAllLoads();
      this.loadBatchInfo();
    });

    const chargeTypes = this.activatedRoute.snapshot.data.chargeTypes;
    this.driverChargeTypes = chargeTypes.filter(
      (item: SelectItem) => item.value.expenseGL && !item.value.isAccessorial
    );
  }

  loadBatchInfo() {
    this.settlementsService.getBatch(this.batchId).subscribe((batchInfoResult: any) => {
      this.batchInfo = batchInfoResult;
    });
  }

  getAllLoads() {
    this.settlementsService.getAllLoadsForBatch(this.batchId).subscribe((res) => {
      this.trips = res;
      this.calculateTotal();
    });
  }

  calculateTotal() {
    // total earnings is the sum of all driver charges on all trips
    const sumDriverCharges = (trip) => {
      return trip.driverCharges.reduce((acc, charge) => {
        return acc + (charge.delete ? 0 : charge.price);
      }, 0);
    };

    for (const trip of this.trips) {
      trip.netNonTripExpense = sumDriverCharges(trip);
    }

    this.totalEarnings = this.trips
      .filter((trip) => trip.include)
      .reduce((acc, trip) => {
        return acc + trip.netNonTripExpense;
      }, 0);
  }

  selectAllEarnings() {
    for (const trip of this.trips) {
      trip.include = true;
    }
    this.calculateTotal();
  }

  editCharge(charge: TripCharge) {
    charge.price = this.rateCalculaterService.calculateChargePrice(charge);
    charge.changed = true;

    this.calculateTotal();
  }

  addCharge(tripPayment) {
    tripPayment.driverCharges.push({
      TripId: tripPayment.TripId,
      PaymentTripId: tripPayment.id,
      changed: true,
    });
  }

  removeCharge(driverCharges: TripCharge[], charge: TripCharge) {
    if (charge.id == null) driverCharges.splice(driverCharges.indexOf(charge), 1);
    else charge.delete = true;

    this.calculateTotal();
  }

  saveChanges() {
    this.settlementsService.updateAllLoads(this.batchId, this.trips).subscribe(
      (res) => {
        this.toastr.success("Earnings updated successfully");
        this.getAllLoads();
      },
      (err) => {
        this.toastr.error("Server Error when saving changes");
      }
    );
  }
}
