<p-table #dt2 [columns]="cols" [value]="fuelRecords" [autoLayout]="true">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th></th>
      <th
        *ngFor="let col of columns"
        [ngClass]="{ 'align-right': col.type === 'currency' || col.type === 'number' }"
      >
        {{ col.header }}
      </th>
      <th class="align-right">Total</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-fuelRecord>
    <tr *ngIf="fuelRecord">
      <td style="text-overflow: clip">
        <img
          ng-if="fuelRecord.batchId"
          style="cursor: pointer; width: 21px"
          src="assets/img/details.svg"
          title="Go To Fuel Expense Grid"
          (click)="openFuelExpensesGrid(fuelRecord, 'fuel')"
        />
      </td>
      <ng-template ngFor let-col [ngForOf]="cols">
        <td *ngIf="col.type === 'code'">{{ fuelRecord.transactionId }}</td>
        <td *ngIf="col.type === 'date'">{{ fuelRecord[col.field] | date : "MM/dd/yyyy" }}</td>
        <td
          *ngIf="col.type === 'string' || col.type === 'currency' || col.type === 'number'"
          [ngClass]="{ 'align-right': col.type === 'currency' || col.type === 'number' }"
          class="editable-cell"
          [pEditableColumn]="fuelRecord"
          [pEditableColumnField]="fuelRecord[col.field]"
        >
          <p-cellEditor class="elements-table">
            <ng-template pTemplate="input">
              <input
                *ngIf="col.type === 'string'"
                style="width: 100px"
                type="text"
                [(ngModel)]="fuelRecord[col.field]"
                (ngModelChange)="onRecordChanged($event, fuelRecord, col.field)"
              />
              <input
                *ngIf="col.type === 'number' || col.type === 'currency'"
                style="width: 100px; text-align: right"
                type="number"
                min="0"
                [(ngModel)]="fuelRecord[col.field]"
                (ngModelChange)="onRecordChanged($event, fuelRecord, col.field)"
              />
            </ng-template>
            <ng-template pTemplate="output">
              <span
                *ngIf="col.type === 'string'"
                [style.color]="getEditedColor(fuelRecord, col.field)"
              >
                {{ fuelRecord[col.field] }}
              </span>
              <span
                *ngIf="col.type === 'number'"
                [style.color]="getEditedColor(fuelRecord, col.field)"
              >
                {{ fuelRecord[col.field] | number }}
              </span>
              <span
                *ngIf="col.type === 'currency'"
                [style.color]="getEditedColor(fuelRecord, col.field)"
              >
                {{ fuelRecord[col.field] | negativeToParens }}
              </span>
            </ng-template>
          </p-cellEditor>
        </td>
      </ng-template>
      <td class="align-right">
        {{
          fuelRecord.fuel +
            fuelRecord.other +
            fuelRecord.cash +
            fuelRecord.fee +
            fuelRecord.discount | negativeToParens
        }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="footer" let-columns>
    <tr>
      <td></td>
      <td>TOTALS</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td class="align-right">{{ fuelRecordTotals.fuel | negativeToParens }}</td>
      <td class="align-right">{{ fuelRecordTotals.other | negativeToParens }}</td>
      <td class="align-right">{{ fuelRecordTotals.cash | negativeToParens }}</td>
      <td class="align-right">{{ fuelRecordTotals.fee | negativeToParens }}</td>
      <td class="align-right">{{ fuelRecordTotals.discount | negativeToParens }}</td>
      <td class="align-right">{{ fuelRecordTotals.total | negativeToParens }}</td>
    </tr>
  </ng-template>
</p-table>

<p-table
  #dt2
  [columns]="moneyCodeCols"
  [value]="moneyCodes"
  [autoLayout]="true"
  [style]="{ marginTop: '10px' }"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th></th>
      <th
        *ngFor="let col of columns"
        [ngClass]="{ 'align-right': col.type === 'currency' || col.type === 'number' }"
      >
        {{ col.header }}
      </th>
      <th class="align-right">Total</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-moneyCode>
    <tr *ngIf="moneyCode">
      <td style="text-overflow: clip">
        <img
          style="cursor: pointer; width: 21px"
          src="assets/img/details.svg"
          title="Go To Fuel Expense Grid"
          (click)="openFuelExpensesGrid(moneyCode, 'express')"
        />
      </td>
      <ng-template ngFor let-col [ngForOf]="moneyCodeCols">
        <td *ngIf="col.type === 'code'">{{ moneyCode.transactionId }}</td>
        <td *ngIf="col.type === 'date'">{{ moneyCode[col.field] | date : "MM/dd/yyyy" }}</td>
        <td *ngIf="col.type === 'expressCode'">Express Code</td>
        <td
          *ngIf="col.type === 'string' || col.type === 'currency' || col.type === 'number'"
          [ngClass]="{ 'align-right': col.type === 'currency' || col.type === 'number' }"
          class="editable-cell"
          [pEditableColumn]="moneyCode"
          [pEditableColumnField]="moneyCode[col.field]"
        >
          <p-cellEditor class="elements-table">
            <ng-template pTemplate="input">
              <input
                *ngIf="col.type === 'string'"
                style="width: 100px"
                type="text"
                [ngModel]="moneyCode[col.field]"
                (ngModelChange)="onMoneyCodeChanged($event, moneyCode, col.field)"
              />
              <input
                *ngIf="col.type === 'number' || col.type === 'currency'"
                style="width: 100px; text-align: right"
                type="number"
                min="0"
                [ngModel]="moneyCode[col.field]"
                (ngModelChange)="onMoneyCodeChanged($event, moneyCode, col.field)"
              />
            </ng-template>
            <ng-template pTemplate="output">
              <span
                *ngIf="col.type === 'string'"
                [style.color]="getEditedMoneyCodeColor(moneyCode, col.field)"
              >
                {{ moneyCode[col.field] }}
              </span>
              <span
                *ngIf="col.type === 'number'"
                [style.color]="getEditedMoneyCodeColor(moneyCode, col.field)"
              >
                {{ moneyCode[col.field] | number }}
              </span>
              <span
                *ngIf="col.type === 'currency'"
                [style.color]="getEditedMoneyCodeColor(moneyCode, col.field)"
              >
                {{ moneyCode[col.field] | negativeToParens }}
              </span>
            </ng-template>
          </p-cellEditor>
        </td>
      </ng-template>
      <td class="align-right">
        {{
          moneyCode.fuel +
            moneyCode.other +
            moneyCode.cash +
            moneyCode.feeAmount +
            moneyCode.discount | negativeToParens
        }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="footer" let-columns>
    <tr>
      <td></td>
      <td>TOTALS</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td class="align-right">{{ moneyCodeTotals.fuel | negativeToParens }}</td>
      <td class="align-right">{{ moneyCodeTotals.other | negativeToParens }}</td>
      <td class="align-right">{{ moneyCodeTotals.cash | negativeToParens }}</td>
      <td class="align-right">{{ moneyCodeTotals.feeAmount | negativeToParens }}</td>
      <td class="align-right">{{ moneyCodeTotals.discount | negativeToParens }}</td>
      <td class="align-right">{{ moneyCodeTotals.total | negativeToParens }}</td>
    </tr>
  </ng-template>
</p-table>
