<div class="sticky-header container">
  <app-rates-header
    [rateId]="rateId"
    [rateType]="rateType"
    [title]="pageTitle"
    [(isEditing)]="isEditing"
    [isCreating]="isCreating"
    [canChange]="hasPermission('BusinessRulesChange')"
    [buildRatesObject]="buildRatesObject"
    [validate]="validate"
  ></app-rates-header>
</div>
<div class="container" style="margin-top: 10px">
  <div class="col-md-12">
    <div class="row form-group">
      <label class="col-md-2">Version</label>
      <div class="col-md-1">
        <select
          *ngIf="isEditing; else versionPreview"
          class="form-control"
          [(ngModel)]="rate.version"
          aria-label="version"
        >
          <option>1</option>
          <option>2</option>
        </select>

        <ng-template #versionPreview>
          <div>{{ rate.version }}</div>
        </ng-template>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="row form-group">
          <label class="col-md-4">Rate Name</label>
          <div class="col-md-8">
            <input
              *ngIf="isEditing; else namePreview"
              type="text"
              class="form-control input-md"
              [(ngModel)]="rate.name"
              aria-label="rateName"
              required
            />
            <ng-template #namePreview>
              <div>
                {{ rate.name }}
              </div>
            </ng-template>
          </div>
        </div>
        <div class="row form-group" *ngIf="useChargeType">
          <label class="col-md-4">Charge Type</label>
          <p-dropdown
            *ngIf="isEditing"
            class="col-md-8"
            [style]="{ minWidth: '100%' }"
            [options]="chargeTypes"
            [(ngModel)]="rate.chargeTypeId"
            placeholder="Select a Charge Type"
            tabbableDropdown
          ></p-dropdown>
          <div *ngIf="!isEditing" class="col-md-4">
            {{ rate.chargeType?.name }}
          </div>
        </div>
        <div class="row form-group">
          <label class="col-md-4">Description</label>
          <div class="col-md-8">
            <textarea
              *ngIf="isEditing; else descriptionPreview"
              rows="5"
              maxlength="1024"
              [(ngModel)]="rate.description"
              aria-label="description"
              class="form-control inputForm notes"
            ></textarea>
            <ng-template #descriptionPreview>
              <div>
                {{ rate.description }}
              </div>
            </ng-template>
          </div>
        </div>
        <div class="row form-group" *ngIf="!hideBillTos">
          <label class="col-md-4">Bill Tos</label>
          <div class="col-md-8">
            <app-add-bill-tos
              [(billTos)]="rate.clients"
              [(isDefaultBillTo)]="rate.isDefaultBillTo"
              [isEditing]="isEditing"
            ></app-add-bill-tos>
          </div>
        </div>
        <div class="row form-group" *ngIf="rateType != 'LANECLIENT' && rateType != 'LANEDRIVER'">
          <label class="col-md-4">Priority</label>
          <div class="col-md-8">
            <input
              *ngIf="isEditing; else priorityReview"
              type="number"
              class="form-control input-md"
              [(ngModel)]="rate.priority"
              aria-label="priority"
            />
            <ng-template #priorityReview>
              <div>
                {{ rate.priority }}
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="col-md-6" *ngIf="useLocations">
        <div class="row form-group">
          <label class="col-md-4">Origins</label>
          <div class="col-md-8">
            <app-states-picker
              [isEditing]="isEditing"
              [(states)]="rate.origins"
              id="origins"
            ></app-states-picker>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-md-4">Destinations</label>
          <div class="col-md-8">
            <app-states-picker
              [isEditing]="isEditing"
              [(states)]="rate.destinations"
              id="dests"
            ></app-states-picker>
          </div>
        </div>
      </div>
      <div class="col-md-6" *ngIf="useFuelRegions">
        <!-- Fuel Pads -->
        <app-fuel-regions-selector
          [isEditing]="isEditing"
          [regions]="fuelRegions"
          [(selectedRegions)]="selectedRegions"
        ></app-fuel-regions-selector>
      </div>
    </div>
    <div class="row form-group" *ngIf="useValidFrom">
      <label class="col-md-2">Valid From</label>
      <div class="col-md-4" *ngIf="isEditing; else validFromPreview">
        <input
          id="validFromUtc"
          name="validFromUtc"
          type="text"
          [inputMask]="dateInputMask"
          autocomplete="off"
          class="form-control input-md"
          placeholder="mm/dd/yyyy"
          aria-label="validFrom"
          required
          [(ngModel)]="rate.validFromUtc"
        />
      </div>
      <ng-template #validFromPreview>
        <div class="col-md-4">
          {{ rate.validFromUtc | date : "MM/dd/yyyy" }}
        </div>
      </ng-template>
      <label class="col-md-2">Valid To</label>
      <div class="col-md-4">
        <input
          *ngIf="isEditing; else validToPreview"
          id="validToUtc"
          name="validToUtc"
          type="text"
          [style]="{ width: '100%' }"
          [inputMask]="dateInputMask"
          autocomplete="off"
          class="form-control input-md"
          placeholder="mm/dd/yyyy"
          aria-label="validTo"
          [(ngModel)]="rate.validToUtc"
        />
      </div>
      <ng-template #validToPreview>
        <div class="col-md-4">
          {{ rate.validToUtc !== null ? (rate.validToUtc | date : "MM/dd/yyyy") : "N/A" }}
        </div>
      </ng-template>
    </div>

    <!-- This container is here to make sure we wait until we have the rates data
  to render this child (only if we're editing). Otherwise, we run into problems -->
    <div *ngIf="isCreating || (!isCreating && rateLoaded)">
      <app-edit-client-rates
        #editClient
        *ngIf="
          rateType === 'CLIENT' ||
          rateType === 'DRIVER' ||
          rateType === 'LANECLIENT' ||
          rateType === 'LANEDRIVER'
        "
        [rateId]="rateId"
        [rateType]="rateType"
        [isEditing]="isEditing"
        [isCreating]="isCreating"
        [rate]="rate"
      >
      </app-edit-client-rates>
      <app-edit-fuel-surcharges
        #editFuel
        *ngIf="rateType === 'FUEL'"
        [rateId]="rateId"
        [isEditing]="isEditing"
        [isEditing]="isEditing"
        [isCreating]="isCreating"
        [rate]="rate"
        [(fuelRegions)]="fuelRegions"
        [(selectedRegions)]="selectedRegions"
      >
      </app-edit-fuel-surcharges>
      <app-edit-driver-fuel-surcharges
        #editDriverFuel
        *ngIf="rateType === 'DRIVERFUEL'"
        [rateId]="rateId"
        [isEditing]="isEditing"
        [isEditing]="isEditing"
        [isCreating]="isCreating"
        [rate]="rate"
        [(fuelRegions)]="fuelRegions"
        [(selectedRegions)]="selectedRegions"
      >
      </app-edit-driver-fuel-surcharges>
      <app-edit-insurance-surcharges
        #editInsurance
        *ngIf="rateType === 'INSURANCE'"
        [rateId]="rateId"
        [rateType]="rateType"
        [isEditing]="isEditing"
        [isEditing]="isEditing"
        [isCreating]="isCreating"
        [rate]="rate"
      >
      </app-edit-insurance-surcharges>
      <app-edit-longevity
        #editLongevity
        *ngIf="rateType === 'LONGEVITY'"
        [rateId]="rateId"
        [rateType]="rateType"
        [isEditing]="isEditing"
        [isEditing]="isEditing"
        [isCreating]="isCreating"
        [rate]="rate"
      >
      </app-edit-longevity>
      <app-edit-custom-charges
        #editCustomCharges
        *ngIf="rateType === 'CUSTOM'"
        [rateId]="rateId"
        [rateType]="rateType"
        [isEditing]="isEditing"
        [isEditing]="isEditing"
        [isCreating]="isCreating"
        [rate]="rate"
        [(hideBillTos)]="hideBillTos"
      >
      </app-edit-custom-charges>
    </div>
  </div>
</div>
