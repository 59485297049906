import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ClientLaneRateViewModel } from "@app/shared/models/rates/ClientLaneRateViewModel.model";
import { SelectItem } from "primeng/api";
import { Table } from "primeng/table";

@Component({
  selector: "app-client-lane-rates",
  templateUrl: "./client-lane-rates.component.html",
  styleUrl: "./client-lane-rates.component.css",
})
export class ClientLaneRatesComponent {
  @ViewChild("clientLaneRatesTable", { static: true }) table: Table;
  @Input() rates: ClientLaneRateViewModel[];

  statusOptions: SelectItem[] = [
    { label: "ALL", value: null },
    { label: "ACTIVE", value: true },
    { label: "INACTIVE", value: false },
  ];

  // prettier-ignore
  columns: any = [
    { field: "name", header: "Rate Name", width: 300 },
    { field: "description", header: "Description", width: 300 },
    { field: "rateBy", header: "Rate By", width: 200 },
  ];
  filterElements: any = [];

  constructor(private router: Router) {}

  ngOnInit(): void {
    const filter = sessionStorage.getItem("client-lane-rates-list");
    if (!filter) this.table.filter(this.statusOptions[1].value, "isValid", "equals");
  }

  editRate(rate: ClientLaneRateViewModel) {
    this.router.navigate([`/rates/laneclient/${rate.id}`]);
  }

  createRate() {
    this.router.navigate([`/createrate/laneclient`]);
  }
}
