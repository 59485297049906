<legend style="color: green">Fuel Record Totals</legend>
<p-table [columns]="totalsCols" [value]="totals" [scrollable]="true" [autoLayout]="true">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns">
        {{ col.header }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-total>
    <tr>
      <td *ngFor="let col of totalsCols">
        {{ total[col.field] | currency }}
      </td>
    </tr>
  </ng-template>
</p-table>

<legend style="color: green; margin-top: 10px">Fuel Records</legend>

<div style="height: 700px; margin-bottom: 70px">
  <p-toolbar styleClass="toolbar-header">
    <div class="ui-toolbar-group-left">
      <button
        pButton
        type="button"
        icon="fa fa-file-excel-o"
        class="p-button-secondary mr-2"
        title="Export to Excel"
        label="Export"
        (click)="exportExcel()"
      ></button>
    </div>
  </p-toolbar>
  <p-table
    #recordTable
    [columns]="recordCols"
    [value]="records"
    [scrollable]="true"
    [virtualScroll]="true"
    [virtualScrollItemSize]="42"
    [autoLayout]="true"
    scrollHeight="flex"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngIf="showBatchLink"></th>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
          {{ col.header }}
        </th>
      </tr>
      <tr>
        <th *ngIf="showBatchLink"></th>
        <th *ngFor="let col of columns" [ngSwitch]="col.field">
          <input
            *ngIf="!col.hide && col.type != 'number'"
            type="text"
            style="width: 100%"
            (input)="$any(recordTable).filter($event.target.value, col.field, 'contains')"
            [value]="$any(recordTable).filters[col.field]?.value"
          />
          <input
            *ngIf="col.field == 'chargeTypeId'"
            type="text"
            style="width: 100%"
            (input)="$any(recordTable).filter($event.target.value, 'filterChargeType', 'contains')"
            [value]="$any(recordTable).filters['filterChargeType']?.value"
          />
          <input
            *ngIf="col.field == 'batchDriverId'"
            type="text"
            style="width: 100%"
            (input)="$any(recordTable).filter($event.target.value, 'filterDriverName', 'contains')"
            [value]="$any(recordTable).filters['filterDriverName']?.value"
          />
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-record let-y="rowIndex">
      <tr>
        <td style="text-overflow: clip" *ngIf="showBatchLink">
          <img
            ng-if="fuelRecord.batchDriver"
            style="cursor: pointer; width: 21px"
            src="assets/img/details.svg"
            title="Go To Fuel Expense Grid"
            (click)="openDriverSettlement(record.batchDriver)"
          />
        </td>
        <ng-template ngFor let-col [ngForOf]="recordCols">
          <td *ngIf="col.type == 'driverId'">
            {{ record[col.field] }}
          </td>
          <td *ngIf="col.type == 'driver'">
            <p-dropdown
              [options]="drivers"
              [style]="{ minWidth: '100%', maxWidth: '100%' }"
              [filter]="true"
              [(ngModel)]="record.batchDriverId"
              optionLabel="label"
              optionValue="value"
              placeholder="Select a Driver"
              (onChange)="onChange()"
              appendTo="body"
            ></p-dropdown>
          </td>
          <td *ngIf="col.type == 'chargeType'">
            <p-dropdown
              [style]="{ minWidth: '100%', maxWidth: '100%' }"
              [options]="chargeTypes"
              optionLabel="label"
              optionValue="value"
              [(ngModel)]="record.chargeTypeId"
              (onChange)="onChange()"
              placeholder="none"
              appendTo="body"
            ></p-dropdown>
          </td>
          <td *ngIf="col.type === 'date'">
            {{ record[col.field] | date : "MM/dd/yyyy" }}
          </td>
          <td
            *ngIf="col.type === 'string' || col.type === 'number' || col.type === 'currency'"
            [ngClass]="{ 'align-right': col.type === 'number' || col.type === 'currency' }"
            class="editable-cell"
            [pEditableColumn]="record"
            [pEditableColumnField]="record[col.field]"
          >
            <p-cellEditor class="elements-table">
              <ng-template pTemplate="input">
                <input
                  *ngIf="col.type === 'string'"
                  style="min-width: 100px"
                  type="text"
                  [(ngModel)]="record[col.field]"
                  (blur)="onChange()"
                  [title]="record[col.field]"
                />
                <input
                  *ngIf="col.type === 'number' || col.type === 'currency'"
                  style="min-width: 100px; text-align: right"
                  type="number"
                  min="0"
                  [(ngModel)]="record[col.field]"
                  (blur)="onChange()"
                  [title]="record[col.field]"
                />
              </ng-template>
              <ng-template pTemplate="output">
                <span
                  *ngIf="col.type === 'string'"
                  [style.color]="getEditedColor(record, col.field)"
                  [title]="record[col.field]"
                >
                  {{ record[col.field] }}
                </span>
                <span
                  *ngIf="col.type === 'number'"
                  [style.color]="getEditedColor(record, col.field)"
                  [title]="record[col.field]"
                >
                  {{ record[col.field] | number }}
                </span>
                <span
                  *ngIf="col.type === 'currency'"
                  [style.color]="getEditedColor(record, col.field)"
                  [title]="record[col.field]"
                >
                  {{ record[col.field] | negativeToParens }}
                </span>
              </ng-template>
            </p-cellEditor>
          </td>
        </ng-template>
      </tr>
    </ng-template>
  </p-table>
</div>
