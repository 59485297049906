import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { HttpClient } from "../shared/services/http/http-client.service";
import { GasRegion } from "@app/shared/models/fuel/GasRegion.model";

@Injectable()
export class FuelService {
  constructor(private _http: HttpClient) {}

  getFuelPrice() {
    return this._http.get("api/gas/getFuelPrice").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server home error"))
    );
  }

  getFuelPriceByZone(zone) {
    return this._http.post("api/gas/getFuelPriceByZone", { zone: zone }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server home error"))
    );
  }

  getRegions() {
    return this._http.get("api/gas/getRegions").pipe(
      map((res: GasRegion[]) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
}
