import { Component, OnInit } from "@angular/core";
import { CanComponentDeactivate } from "@app/shared/guards/confirmation/confirmation.guard";

@Component({
  selector: "app-efs-grids",
  templateUrl: "./efs-grids.component.html",
  styleUrl: "./efs-grids.component.css",
})
export class EfsGridsComponent implements OnInit, CanComponentDeactivate {
  currentTab: string;

  constructor() {}

  ngOnInit() {
    this.currentTab = "fuel";
  }

  setTab(tabName: string) {
    this.currentTab = tabName;
  }

  confirm() {
    return true;
  }
}
